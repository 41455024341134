
import {
  DeleteFilled,
  FilePdfFilled,
  InboxOutlined,
  MessageFilled,
  MinusCircleOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  ContractTypesEnum,
  FileHandlingService,
  FileTypeEnum,
  FileUploadIdModel,
  FilesRequirementEnum,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { FormInstance, FormLayout } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../notifications';
import { useAuthState } from '../iam-client-react';
import FormList from 'antd/es/form/FormList';

const { Dragger } = Upload;
export interface IFileUploadFormProps {
  maxCount: number;
  layoutType: FormLayout;
  formRef: FormInstance<any>;
  initialValues: any[];
  filesAndAllowedTypes?: Map<string, string[]>;
  children?: React.ReactNode;
  isButtonType?: boolean;
  disableDescription?: boolean;
  filePermissions?: { noOfFiles: FilesRequirementEnum; fileType: FileTypeEnum };
  contractType?: ContractTypesEnum;
  title?: string;
  formType?: string;
  fileModal?: boolean;
  setFileModal?: (value: boolean) => void;
  setRefresh?: (value: any) => void;
  commentsEnabled?: boolean;
  setFeaturesRefName?: React.Dispatch<React.SetStateAction<string>>
  setCommentsTitle?: React.Dispatch<React.SetStateAction<string>>
  setCommentsVisible?: React.Dispatch<React.SetStateAction<boolean>>
}

export const FileUploadForm = (props: IFileUploadFormProps) => {
  const { formatMessage: fm } = useIntl();
  const {
    maxCount,
    isButtonType,
    disableDescription,
    contractType,
    layoutType,
    formRef,
    initialValues,
    title,
    formType,
    fileModal,
    setFileModal,
    setRefresh,
    commentsEnabled,
    setFeaturesRefName,
    setCommentsTitle,
    setCommentsVisible
  } = props;
  const [fileList, setFileList] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<any>();
  const {
    authContext: { defaultPlant, user },
  } = useAuthState();

  console.log('fileModal', fileModal)
  console.log('openModal', openModal)


  useEffect(() => {
    formRef.setFieldValue('filesData', fileList)
    fileList.forEach((file, index) => {
      // formRef.setFieldValue(['filesData', index, 'fileDescription'], file?.fileDescription ? file?.fileDescription : file?.name)
    })
  }, [fileList])

  // const {filePermissions :{ noOfFiles,fileType}}  = props.filePermissions ? (props.filePermissions ): ({noOfFiles : undefined,fileType:undefined});
  const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];
  const fileService = new FileHandlingService();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const validateFileType = (
    { type, name }: UploadFile,
    allowedTypes: string[] = ['image/jpeg', 'application/pdf'],
  ) => {
    return true;
    if (props?.filePermissions?.fileType != FileTypeEnum.BOTH) {
      if (props?.filePermissions?.fileType != type) return false;
      return true;
    } else {
      return true; //allowedTypes.includes(type!);
    }
  };
  const getUploadProps = (indexOfThatFile: number) => {
    // console.log('upload props');
    const uploadProps = {
      // showUploadList: false,
      multiple: true,
      beforeUpload: (file: UploadFile) => {
        console.log('File ==>', file)
        // console.log(file);
        formRef.setFieldValue(['filesData', indexOfThatFile, 'fileDescription'], file?.name)
        const isAllowedType = validateFileType(file);
        if (!isAllowedType) {
          message.error(`${file.type} is not allowed`);
          return false;
        }
        setFileList((prev) => {
          if (prev.length < maxCount) {
            const clonedData = [...prev];
            clonedData[indexOfThatFile] = file;
            return clonedData;
          }
          return prev;
        });
        return false;
      },
      onRemove: (file: UploadFile) => {
        setFileList((prev) => {
          const clonedData = [...prev];
          clonedData[indexOfThatFile] = [];
          return clonedData;
        });
        formRef.setFieldValue(['filesData', indexOfThatFile, 'fileDescription'], undefined)

      },
      fileList: fileList[indexOfThatFile] ? [fileList[indexOfThatFile]] : [],
    } as UploadProps;
    return uploadProps;
  };

  const uploadButton = (
    <div>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </div>
  );

  function deleteFile(fileUploadId) {
    const req = new FileUploadIdModel(
      fileUploadId,
      defaultPlant,
      user.userName,
    );
    fileService
      .deleteSingleFile(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setRefresh(prev => prev + 1 )
          // window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message, 'err message'));
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    multiple: true,
    onChange(info) {
      console.log('info ==>', info)
      setFileList(info.fileList)
    },
    beforeUpload: (file: UploadFile, fileList) => {
      console.log('fileList ==>', fileList)
      // console.log(file)
      const isAllowedType = validateFileType(file);
      if (!isAllowedType) {
        message.error(`${file.type} is not allowed`);
        return false;
      }
      setFileList(fileList)

      return false;
    },
    onRemove: (fileList) => {
      setFileList((prev) => {
        const clonedData = [...prev];
        return clonedData;
      });


      // formRef.setFieldValue(['filesData', indexOfThatFile, 'fileDescription' ], undefined)

    },
    fileList: fileList,
  }


  const renderForm = () => (
    <Form
      layout={layoutType}
      form={formRef}
      initialValues={{
        filesData: initialValues.length !== 0 ? undefined : [],
      }}
    >

      {/* <Form.Item name='filesData'> */}
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly
          prohibited from uploading company data or other
          banned files
        </p>
      </Dragger>
      {/* </Form.Item> */}
      <br />

      <Form.List name="filesData">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key}>
                <Col
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}
                  xl={{ span: 20 }}
                >

                  <Form.Item
                    label={fileList[index]?.name}
                    {...field}
                    name={[field.name, 'fileDescription']}
                    rules={[
                      {
                        required: true,
                        message: fm({
                          id: 'common.fileUploadForm.reqFileDescription',
                          defaultMessage:
                            'Please Enter File Description',
                        }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={fm({
                        id: `common.fileUploadForm.fileDescription`,
                        defaultMessage: `File Description`,
                      })}
                      autoComplete="off"
                    // onChange={(e) => onChangeFileNames(e, index)}

                    />
                  </Form.Item>

                </Col>


                {/* <Form.Item
                          label={fm({
                            id: 'common.fileUploadForm.reqFile',
                            defaultMessage: 'Select file to upload',
                          })}
                          {...field}
                          name={[field.name, 'file']}
                          getValueFromEvent={normFile}
                          style={{display:'none'}}
                        >
                           <Dragger {...getUploadProps(index)}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibited from uploading company data or other
                                banned files
                              </p>
                            </Dragger>

                        </Form.Item> */}
                <Col
                  xs={{ span: 2 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2 }}
                  xl={{ span: 2 }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      marginTop: '26px',
                      marginLeft: '35px',
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={fm({ id: 'common.delete' })}
                    >
                      <DeleteFilled
                        style={{
                          color: '#f70404',
                          fontSize: '21px',
                          float: 'right',
                        }}
                        onClick={() => {
                          remove(field.name)
                          setFileList((prev) => {
                            const clonedData = [...prev];
                            clonedData.splice(field.name, 1);
                            return clonedData;
                          });
                        }}
                      />
                    </Tooltip>
                  </span>
                </Col>

              </Row>
            ))}

          </>
        )}
      </Form.List>

    </Form>
  )

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    if (setCommentsTitle) {  
      setCommentsTitle(title); 
  }
  }

  return (
    <Card
      title={title || fm({
        id: 'common.fileUploadForm.fileUploadTitle',
        defaultMessage: 'Document Details',
      })}
      extra={
        <Space>
          {setCommentsVisible && 
          <Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData(`BusinessNo-Documents > ${title}`, title)} /></Tooltip>}
          {formType === 'View' &&
            <Button size='middle' icon={<PlusCircleFilled />} className='plusCircled-icon'
              onClick={() => {setFileModal(true); setFileList([])}}
            ></Button>
          }
        </Space>
      }
      // bordered={false}
      headStyle={{ border: 'none', paddingTop: '12px' }}
      style={{ width: '100%' }}
      className='card-radius ant-card-height'
    >
      <>
      {(!initialValues?.length && formType === 'View') && <h6 style={{textAlign:'center', color: '#64748B'}}>No files to show</h6>}
        {initialValues
          ?.map((imageData: any, index) => {
            if (!imageData || typeof imageData !== 'object')
              return <div key={`fileListColumn${index}`}></div>;

            let path = '';
            if (imageData.base64Url) {
              path = imageData.base64Url;
            } else {
              if (imageData.filePath?.startsWith('http')) {
                path = imageData.filePath;
              } else {
                path = fileUploadPath + '/' + imageData.filePath.slice(7);
              }
            }
            // if (index % 3 === 0) {
            //   return (
            //     <Col
            //       key={`fileListColumn${index}`}
            //       xs={{ span: 24 }}
            //       sm={{ span: 24 }}
            //       md={{ span: 24 }}
            //       lg={{ span: 24 }}
            //       xl={{ span: 24 }}
            //     >
            //       {/* {imageData.type.includes('image') ? <Image
            //                             width={500}
            //                             height={375}
            //                             title={imageData.fileDescription}
            //                             src={path}
            //                         /> :
            //                             <Card
            //                                 title={imageData.fileDescription}
            //                                 style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }}
            //                                 bodyStyle={{ overflow: 'auto' }}
            //                             ><embed src={path} width="500" height="375"
            //                                 type={imageData.type} /></Card>} */}
            //       <div>
            //         <a
            //           style={{ padding: '20px' }}
            //           href={`${path}`}
            //           target="_blank"
            //         >
            //           {imageData.fileDescription}
            //         </a>
            //         <Popconfirm
            //           onConfirm={(e) => {
            //             deleteFile(imageData.fileUploadId);
            //           }}
            //           title={'Are you sure to Delete File ?'}
            //         >
            //           <DeleteFilled
            //             type="delete"
            //             style={{ color: 'red', fontSize: '16px' }}
            //           />
            //         </Popconfirm>
            //       </div>
            //     </Col>
            //   );
            // } 
            // else {
            return (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingRight: '20px', backgroundColor: '#F7F9FC', padding: 4, margin: 2, borderRadius: '6px' }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              ><span className='link' >
              <FilePdfFilled style={{marginRight: '4px'}} />
                <a href={`${path}`} target="_blank" className='link'>
                  {imageData.fileDescription}
                </a>
                </span>
                <Popconfirm
                  onConfirm={(e) => {
                    deleteFile(imageData.fileUploadId);
                  }}
                  title={'Are you sure to Delete File ?'}
                  okText='Delete'
                >
                  {hoveredIndex === index && (
                  <DeleteFilled
                    type="delete"
                    className='delete-filled'
                  />)}
                </Popconfirm>
              </div>
            );
          })
          .reduce((r, element, index2) => {
            index2 % 3 === 0 && r.push([]);
            r[r.length - 1].push(element);
            return r;
          }, [])
          .map((rowContent, rowIndex) => {
            return <Row key={`filesRow${rowIndex}`}>{rowContent}</Row>;
          })}
      </>

      {formType !== 'View' && renderForm()}


      <Modal
        title={<p>Upload Files</p>}
        footer={<>{fileList.length > 0 && props?.children}</>}
        open={fileModal}
        onCancel={() => setFileModal(false)}
      >
        {renderForm()}
      </Modal>



      {formType !== 'View' && props?.children}

      {/* <Form
        layout={layoutType}
        form={formRef}
        initialValues={{
          filesData: initialValues.length !== 0 ? undefined : [],
        }}
      >
        <Form.List name="filesData">
          {(fields, { add, remove }) => (
            <>
            {console.log(fields)}
            {console.log('formData', formRef.getFieldValue('filesData'))}
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Col
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}
                  >
                    <Row>
                      
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
        
                        md={{ span: 24, offset: 2 }}
                        lg={{ span: 24, offset: 2 }}
                        xl={{ span: 24, offset: 2 }}
                      >
                        <Form.Item
                          label={fm({
                            id: 'common.fileUploadForm.reqFile',
                            defaultMessage: 'Select file to upload',
                          })}
                          {...field}
                          name={[field.name, 'file']}
                          getValueFromEvent={normFile}
                        >
                          {!isButtonType ? (
                            <Dragger {...getUploadProps(index)}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibited from uploading company data or other
                                banned files
                              </p>
                            </Dragger>
                          ) : (
                            <Upload
                              style={{ width: 130 }}
                              multiple={true}
                              listType="text"
                              showUploadList
                              {...getUploadProps(index)}
                            >
                              {uploadButton}
                            </Upload>
                          )}
                        </Form.Item>
                      </Col>

                    </Row>
                    {!disableDescription && (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 11 }}
                          lg={{ span: 11 }}
                          xl={{ span: 11 }}
                        >
                          <Form.Item
                            label={fm({
                              id: 'common.fileUploadForm.fileDescription',
                              defaultMessage: 'File Description',
                            })}
                            {...field}
                            name={[field.name, 'fileDescription']}
                            rules={[
                              {
                                required: true,
                                message: fm({
                                  id: 'common.fileUploadForm.reqFileDescription',
                                  defaultMessage:
                                    'Please Enter File Description',
                                }),
                              },
                            ]}
                          >
                            <Input
                              placeholder={fm({
                                id: `common.fileUploadForm.fileDescription`,
                                defaultMessage: `File Description`,
                              })}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      )}
                  </Col>
                  <Col
                    xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}
                  >
                    {fields.length - 1 === index && fields.length < 5 && (
                      <span
                        style={{
                          position: 'absolute',
                          marginTop: '33px',
                          marginLeft: '15px',
                        }}
                      >
                        <Tooltip
                          placement="top"
                          title={fm({
                            id: 'common.add',
                            defaultMessage: 'Add',
                          })}
                        >
                          {/* <PlusCircleOutlined onClick={() => add()} /> *
                        </Tooltip>{' '}
                      </span>
                    )}

                    {/* {(index != 0 || initialValues.length != 0) && *
                    <span
                      style={{
                        position: 'absolute',
                        marginTop: '33px',
                        marginLeft: '35px',
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={fm({ id: 'common.delete' })}
                      >
                        <DeleteFilled
                          style={{
                            color: '#f70404',
                            fontSize: '21px',
                            float: 'right',
                          }}
                          onClick={() => remove(field.name)}
                        />
                      </Tooltip>
                    </span>
                    {/* } *
                  </Col>

                  <Row>
                    <p></p>
                  </Row>
                </Row>
              ))}

              <Row>
                <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  Add Documents{' '}
                  <span>
                    <Tooltip
                      placement="top"
                      title={fm({ id: 'common.add', defaultMessage: 'Add' })}
                    >
                      <PlusCircleOutlined
                        onClick={() => {
                          if (
                            props?.filePermissions?.noOfFiles
                              ? props?.filePermissions?.noOfFiles ==
                                FilesRequirementEnum.MULTIPLE
                              : maxCount > fields.length
                          ) {
                            add();
                          } else {
                            AlertMessages.getErrorMessage(
                              `You can't add more than ${maxCount} fields`,
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </span>
                  {/* <Button onClick={() => {
                                        if (maxCount > fields.length) {
                                            add()
                                        } else {
                                            AlertMessages.getErrorMessage(`You can't add more than ${maxCount} fields`)
                                        }
                                    }} block>
                                        {fm({ id: "common.fileUploadForm.addButton", defaultMessage: 'Add New File' })}
                                    </Button> *
                </Col>
                <Col
                  xl={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                ></Col>
              </Row>
            </>
          )}
        </Form.List>
        {/* {typeof remarks !== 'undefined' && <Form.Item label="Remarks" name="remarks">
                    <TextArea rows={5} />
                </Form.Item>} *
      </Form> */}

    </Card >
  );
};

export default FileUploadForm;
