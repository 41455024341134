import {
  ContractStatusEnum,
  ContractDetailsDto,
  ContractIdRequest,
  PurchaseContractService,
  ContractModesEnum,
  GetTaxesByBpIdDto,
  ItemsCodesEnum,
  ItemsIdRequestDto,
  TaxesAndItemsService,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import type { TableProps } from 'antd';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../common';
import {
  EditOutlined,
  PlusSquareOutlined,
  CopyOutlined,
  FileExcelFilled,
  UpCircleFilled,
  DownCircleFilled,
  CheckCircleOutlined,
  RotateRightOutlined,
  SplitCellsOutlined,
  EditFilled,
  EllipsisOutlined,
  MoreOutlined,
  PlusOutlined,
  PlusCircleFilled,
  CopyFilled,
} from '@ant-design/icons';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { Excel } from 'antd-table-saveas-excel';
import QualityDetailView from './quality-detail-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ContractTransfer from './contract-transfer';
import FormItem from 'antd/es/form/FormItem';
import { Option } from 'antd/es/mentions';

const { TabPane } = Tabs;
export const PurchaseContractView = () => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [, setPage] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const service = new PurchaseContractService();
  let pathToreDirect = '/po-detail-view';
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [plantCode, setPlantCode] = useState<any>();
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const taxesAndItemsService = new TaxesAndItemsService();
  const [transferFormRef] = Form.useForm();
  const [contractDate, setContractDate] = useState<any>();
  const [status, setStatus] = useState<any>('');

  useEffect(() => {
    if (!isPermissionExist([111])) return navigate('/');

    getAllPurchaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTaxesByItemsId(contractDate);
  }, [contractDate]);

  const getAllPurchaseData = () => {
    service
      .getAllPurchaseContracts({ plantCode: authContext.defaultPlant })
      .then((res) => {
        if (res.status) {
          const processedData = res.data.map(item => {
            const uniqueQualities = Array.from(new Set(item.qualities.split(' / '))).join(' / ');
            return { ...item, qualities: uniqueQualities };
          });
        setGridData(processedData);
          // setGridData(res.data);
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // Filter data based on selected status
  const filteredData = status ? gridData.filter((item) => item.status === status) : gridData;

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate);
    taxesAndItemsService
      .getVatByItemsId(req)
      .then((res) => {
        if (res.status) {
          setVat(res.data);
        } else {
          setVat([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setVat([]);
      });
  };

  //
  const renderItems = (record: ContractDetailsDto, index, indent, expanded) => {
    // issue
    // getTaxesByItemsId(record.contractDate);
    setContractDate(record.contractDate);
    return (
      <QualityDetailView
        pcId={record.pcId}
        type={ContractModesEnum.PURCHASE}
        classes="contracts"
        plantCode={record.plantCode}
        snapShotData={[]}
        contractType={record.contractType}
        vat={vat}
      />
    );
  };

  const transferModelOncancel = () => {
    setShowTransferModal(false);
    transferFormRef.resetFields();
  };

  const menu = (rowData: any) => (
    <Menu>
      {rowData.status === ContractStatusEnum.ACTIVE && isPermissionExist([116]) ? (
      <Menu.Item key="1"
       onClick={() => {
        addendumClickHandler(rowData.pcId, rowData.plantCode);
        }}
         >
        <PlusCircleFilled style={{  width: '16px', height: '16px', marginRight: '8px', color: '#64748B' }} /> 
       <span style={{fontWeight: '400', fontSize: '14px', color: '#64748B'}}>Create Addendum</span> 
      </Menu.Item>
      ) : null } 

      {isPermissionExist([115]) ? (
      <Menu.Item key="2"
       onClick={() => {
        cloneHandler(rowData.pcId, rowData.plantCode);
      }}
      >
        <CopyFilled style={{ width: '11px', height: '14px', marginRight: '12px', color: '#64748B' }} /> 
        <span style={{fontWeight: '400', fontSize: '14px', color: '#64748B'}}>Clone</span>
      </Menu.Item>
      ) : null }
    </Menu>
  );

  const tableColumns: any = [
    {
      title: 'PO ID',
      dataIndex: 'contractNo',
      // width: 130,
      align: 'left',
      sorter: (a, b) =>
        a.contractNo !== null
          ? a.contractNo.localeCompare(b.contractNo)
          : 'Have Null Values',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true)) return true;
        else return false;
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const link = `/#/po-detail-view?pc_id=${record.pcId}`;
        return (
          <>
            <a
            href={link} className="link">
              {record.contractNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'customerName',
      // width: 230,
      align: 'left',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <>
          {/* <Avatar style={{ backgroundColor: `rgba${ColorAlphaBets[record?.customerName?.charAt(0).toLocaleUpperCase()]}`, verticalAlign: 'middle' }} >
                    {record?.customerName?.charAt(0).toLocaleUpperCase()}
                </Avatar > */}

          {/* &nbsp; */}
          <span className='table-name-column'>{record?.customerName}</span>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'contractDate',
      // width: 150,
      align: 'left',
      render: (text, record) => {
        return <span className='table-other-columns'>{record.contractDate !== undefined
          ? moment(record.contractDate).format('DD-MMM-YYYY')
          : ''}</span>
      },
      sorter: (a, b) =>
        moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
      // width: 200,
      align: 'left',
      render: (text, record) => {
        const qualities = record.qualities 
         ? record.qualities.split('/').map(item => item.trim()).join(', ') : '';
        return <span className='table-other-columns'>
          {qualities}
          </span>
      }
    },
    // {
    //   title: 'Currency',
    //   dataIndex: 'currencyName',
    //   width: 130,
    //   align: 'left',
    //   sorter: (a, b) => a.currencyName.localeCompare(b.currencyName),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      align: 'start',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <StatusTag status={record.status} />
      ),
    },
    // {
    //   title: 'Vessel Type',
    //   dataIndex: 'vesselType',
    //   width: 130,
    //   align: 'left',
    //   sorter: (a, b) => a.vesselType.localeCompare(b.vesselType),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Load Port',
    //   dataIndex: 'pol',
    //   width: 130,
    //   align: 'left',
    //   sorter: (a, b) => a.pol.localeCompare(b.pol),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Load Rate',
    //   dataIndex: 'loadingRateForGeared',
    //   children: [
    //     {
    //       title: 'Geared',
    //       dataIndex: 'loadingRateForGeared',
    //       width: 130,
    //       align: 'left',
    //       sorter: (a, b) =>
    //         a.loadingRateForGeared.localeCompare(b.loadingRateForGeared),
    //       sortDirections: ['descend', 'ascend'],
    //     },
    //     {
    //       title: 'Gearless',
    //       dataIndex: 'loadingRateForGearless',
    //       width: 130,
    //       align: 'left',
    //       sorter: (a, b) =>
    //         a.loadingRateForGearless.localeCompare(b.loadingRateForGearless),
    //       sortDirections: ['descend', 'ascend'],
    //     },
    //   ],
    // },
    {
      // title: 'Action',
      // align: 'center',
      // className: 'operations',
      // fixed: 'right',
      // children: [
      //   {
          title: 'Action',
          dataIndex: 'action',
          // fixed: 'right',
          width: 100,
          align: 'left',
          render: (text, rowData) => {
            return rowData.isTransferredContract ? (
              <b>This Contract Has Been Transferred</b>
            ) : (
              <span style={{display: 'flex'}}>
                {rowData.status === ContractStatusEnum.DRAFT ||
                rowData.status === ContractStatusEnum.PENDING_APPROVAL ? (
                  <>
                    {isPermissionExist([112]) && (
                      <Tooltip
                        placement="top"
                        title={fm({
                          id: 'common.edit',
                          defaultMessage: 'Edit',
                        })}
                      >
                        <EditFilled
                          type="edit"
                          onClick={() => {
                            editClickHandler(rowData.pcId, rowData.plantCode);
                          }}
                          className='action-icons mr-8'
                        />
                      </Tooltip>
                    )}{' '}
                  </>
                ) :  <span
                style={{
                  // width: '20px', 
                  marginRight: '34px',
                  visibility: 'hidden', 
                }}
              />}

              {isPermissionExist([115,116]) && (
                <Tooltip title= 'More'>
               <Dropdown overlay={menu(rowData)} trigger={['click']}>
               <MoreOutlined className='action-icons' />
               </Dropdown>
               </Tooltip>)}
 


                {/* <>
                  {isPermissionExist([115]) && (
                    <Tooltip
                      placement="top"
                      title={fm({
                        id: 'common.clone',
                        defaultMessage: 'Clone',
                      })}
                    >
                      <CopyOutlined
                        style={{ color: '#1890ff', fontSize: '14px' }}
                        onClick={() => {
                          cloneHandler(rowData.pcId, rowData.plantCode);
                        }}
                      />
                    </Tooltip>
                  )}
                  <span> | </span>
                </>

                {rowData.status === ContractStatusEnum.DRAFT ? (
                  <>
                    <Tooltip
                      placement="top"
                      title={fm({
                        id: 'common.sendForApproval',
                        defaultMessage: 'Send For Approval',
                      })}
                    >
                      <FontAwesomeIcon
                        icon={faPersonCircleCheck}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                        onClick={() => {
                          sendForApproval(rowData.pcId, rowData.plantCode);
                        }}
                        className={`hover ${
                          rowData.status !== ContractStatusEnum.DRAFT
                            ? 'disabled-button'
                            : ''
                        }`}
                      />
                    </Tooltip>
                    <span> | </span>
                  </>
                ) : null}

                {rowData.status === ContractStatusEnum.PENDING_APPROVAL ? (
                  <>
                    {isPermissionExist([119]) && (
                      <Tooltip
                        placement="top"
                        title={fm({
                          id: 'common.approve',
                          defaultMessage: 'Approve',
                        })}
                      >
                        <CheckCircleOutlined
                          type="approve"
                          onClick={() => {
                            approvePurchaseOrder(
                              rowData.pcId,
                              rowData.plantCode,
                            );
                          }}
                          style={{ color: '#1890ff', fontSize: '14px' }}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : null}
                {rowData.status === ContractStatusEnum.ACTIVE ? (
                  <>
                    <span> | </span>
                    {isPermissionExist([116]) && (
                      <Tooltip placement="top" title={'Addendum'}>
                        <PlusSquareOutlined
                          type="edit"
                          onClick={() => {
                            addendumClickHandler(
                              rowData.pcId,
                              rowData.plantCode,
                            );
                          }}
                          style={{ color: '#1890ff', fontSize: '14px' }}
                        />
                      </Tooltip>
                    )}
                    <span> | </span>{' '}
                  </>
                ) : null}
                {rowData.transferLogId == null && (
                  <span>
                    <>
                      {isPermissionExist([117]) && (
                        <Tooltip title="Transfer">
                          <RotateRightOutlined
                            className={'editSamplTypeIcon'}
                            type="transfer"
                            onClick={() => {
                              setShowTransferModal(true);
                              setPlantCode({
                                plantCode: rowData?.plantCode,
                                previousContractId: rowData?.pcId,
                              });
                            }}
                            style={{ color: '#1890ff', fontSize: '14px' }}
                          />
                        </Tooltip>
                      )}
                      <span> | </span>
                    </>
                    <Tooltip title="Sub Job">
                      <SplitCellsOutlined
                        className={'editIcon'}
                        type="subPo"
                        onClick={() => {}}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      />
                    </Tooltip>
                  </span>
                )} */}
              </span>
            );
          },
      //   },
      // ],
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record.pcId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split('-')
      .join('/');
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return {
          title: item.dataIndex.toLocaleUpperCase(),
          dataIndex: item.dataIndex,
        };
    });

    const excel = new Excel();
    excel.addSheet('Sheet1');
    excel.addRow();
    excel
      .addColumns(exportingColumns)
      .addDataSource(filteredData)
      .saveAs(`Purchase-${currentDate}.xlsx`);
  };

  const redirectToFullView = (contractId: string, plantCode: string) => {
    navigate(pathToreDirect, { state: { contractId, plantCode } });
  };

  const cloneHandler = (pcId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(pcId), plantCode };
    service
      .clonePurchaseOrder(req)
      .then((res) => {
        if (res.status) {
          getAllPurchaseData();
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const editClickHandler = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service
      .getPurchasePreviewData(req)
      .then((res) => {
        if (res.status) {
          navigate('/po-update', { state: res.data });
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const addendumClickHandler = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service
      .getPurchasePreviewData(req)
      .then((res) => {
        if (res.status) {
          navigate('/po-addendum', { state: res.data });
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const sendForApproval = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service
      .sendForApproval(req)
      .then((res) => {
        if (res.status) {
          getAllPurchaseData();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: 'Purchase Order Sent successfully',
            }),
          );
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const approvePurchaseOrder = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service
      .approvePurchaseOrder(req)
      .then((res) => {
        if (res.status) {
          getAllPurchaseData();
          AlertMessages.getSuccessMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: 'Purchase Order approved successfully',
            }),
          );
        } else {
          AlertMessages.getErrorMessage(
            fm({
              id: `mdm.errorCodes.${res.errorCode}`,
              defaultMessage: res.internalMessage,
            }),
          );
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  return (
    <>
      <Card
      className='card-container'
        title={<span className='card-title'>Purchase Orders</span>}
        headStyle={{ border: 'none' }}
      >
        {/* <Row justify="space-between">
          <Col span={12}> */}
            {/* <Row> */}
              {/* <Col>
                <Card
                  title={"Total PO's: " + gridData.length}
                  style={{ textAlign: 'left', backgroundColor: '#bfbfbf' }}
                  bodyStyle={{ display: 'none' }}
                ></Card>
              </Col> */}
              {/* <Col>
                <Card
                  title={
                    'OPEN :' +
                    gridData.filter(
                      (el) => el.status === ContractStatusEnum.DRAFT,
                    ).length
                  }
                  style={{ textAlign: 'left'}}
                  bodyStyle={{ display: 'none' }}
                ></Card>
              </Col>
              <Col>
                <Card
                  title={
                    'IN-PROGRESS :' +
                    gridData.filter(
                      (el) => el.status == ContractStatusEnum.ACTIVE,
                    ).length
                  }
                  style={{ textAlign: 'left'}}
                  bodyStyle={{ display: 'none' }}
                ></Card>
              </Col> */}
            {/* </Row> */}
            {/* <Row style={{ width: '176px', height: '64px',display: 'flex', gap: '16px', alignItems: 'flex-start' }}> */}
              {/* <Card style={{ width: '80px', height: '64px', padding: '0'}}>
                <h5 style={{ margin: '0', padding: '0' }}>
               { gridData.filter(
                      (el) => el.status === ContractStatusEnum.DRAFT,
                    ).length}
                </h5>
                <p  style={{ margin: '0', fontSize: '12px' }}>Draft</p>
              </Card>
              <Card style={{ width: '80px', height: '64px', padding: '0', boxSizing: 'border-box'}}>
              <h5 style={{ margin: '0', padding: '0' }}>
              {gridData.filter(
                      (el) => el.status == ContractStatusEnum.ACTIVE,
                    ).length}
                </h5>
                <p style={{ margin: '0', fontSize: '12px' }}>In Progress</p>
              </Card> */}

              {/* <Card style={{ 
                width: '80px', 
                height: '64px', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                borderRadius: '8px',
                border: 'none',
                boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.05)'
              }}>
               <h5 style={{ margin: '0', padding: '0', color: '#4A5E6D' }}>
                {gridData.filter(
                (el) => el.status === ContractStatusEnum.DRAFT,
                ).length}
               </h5>
                <p style={{ margin: '0', fontSize: '10px', whiteSpace: 'nowrap', fontWeight: '500', color: '#64748BB2' }}>Draft</p>
              </Card>

                <Card style={{ 
                   width: '80px', 
                   height: '64px', 
                   display: 'flex', 
                   flexDirection: 'column', 
                   justifyContent: 'center', 
                   borderRadius: '8px',
                   border: 'none',
                   boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.05)'
                  }}>
                   <h5 style={{ margin: '0', padding: '0', color: '#0083C9' }}>
                    {gridData.filter(
                    (el) => el.status == ContractStatusEnum.ACTIVE,
                    ).length}
                   </h5>
                     <p style={{ margin: '0', fontSize: '10px', whiteSpace: 'nowrap', fontWeight: '500', color: '#2675A0', }}>In Progress</p>
                </Card> */}
            {/* </Row>
          </Col> */}
          {/* <Col> */}
            {/* <Row> */}
              {/* <Col>
                <Input.Search
                  placeholder="Search"
                  allowClear
                  onChange={(e) => {
                    setSearchedText(e.target.value);
                  }}
                  onSearch={(value) => {
                    setSearchedText(value);
                  }}
                />
              </Col> */}
              {/* <Col> */}
                {/* <Tooltip placement="topRight" title="Create">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => navigate('/po-creation')}
                  >
                    Create
                  </Button>
                </Tooltip> */}

                {/* <Tooltip placement="topRight" title="Export As Excel">
                  <Button
                    disabled={gridData.length === 0}
                    size="small"
                    type="default"
                    className={
                      gridData.length === 0
                        ? 'export-excel-btn-disabled'
                        : 'export-excel-btn'
                    }
                    onClick={handleExport}
                    icon={<FileExcelFilled />}
                  >
                    Export
                  </Button>
                </Tooltip> */}
              {/* </Col>
            </Row>
          </Col> */}
        {/* </Row> */}
        <Card 
        className='contracts-list card-radius'
        title={<Row className='card-title-buttons'>
          <Input.Search placeholder="Search Orders" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} className='input-search' />
          <Select
          placeholder="Filter by Status"
          className='filter-by-status'
          allowClear
          onChange={handleStatusChange}
        >
          <Option value="Draft">Draft</Option>
          <Option value="Send For Approval">Send for Approval</Option>
          <Option value="Active">Active</Option>
        </Select>
        </Row>}
        extra={isPermissionExist([110]) && (
          <Tooltip placement="topRight" title="Create">
            <Row className='mt-16'>
                <Button
                  className='new-button'
                  size="small"
                  type="primary"
                  onClick={() => navigate('/po-creation')}
                >
                  New PO
                </Button>
            </Row>    
              </Tooltip>)}
              headStyle={{ border: 'none' }}
              >     
        <Table
          expandIconColumnIndex={0}
          className="contracts-list"
          rowKey={(record) => record.pcId}
          columns={tableColumns}
          dataSource={filteredData}
          // expandable={{
          //   expandedRowRender: renderItems,
          //   expandedRowKeys: expandedIndex,
          //   onExpand: setIndex,
          //   fixed: 'right',
          // }}
          // expandIcon={({ expanded, onExpand, record }) =>
          //   expanded ? (
          //     <UpCircleFilled onClick={(e) => onExpand(record, e)}>
          //       Collapse
          //     </UpCircleFilled>
          //   ) : (
          //     <DownCircleFilled onClick={(e) => onExpand(record, e)}>
          //       Expand
          //     </DownCircleFilled>
          //   )
          // }
          pagination={{
            onChange(current) {
              setExpandedIndex([]);
              setPage(current);
            },
            showTotal: (total) => (
              <span 
              className='page-total-items'
              >Total {total} items</span>
            ),
            showSizeChanger: true, 
            pageSizeOptions: ['25', '50', '100'],
            defaultPageSize: 25,
            style: {
              paddingTop: '20px',
            },
          }}
          scroll={{ x: 500 }}
          // size="large"
          // bordered
        ></Table>
      </Card>
      </Card>
      <Modal
        open={showTransferModal}
        onCancel={transferModelOncancel}
        footer={null}
        width={750}
      >
        <ContractTransfer
          plantCode={plantCode}
          formRef={transferFormRef}
          contractType={'Purchase Contract'}
        />
      </Modal>
    </>
  );
};

export default PurchaseContractView;
