import React, { useEffect } from 'react';
import { ContractModesEnum, SamplingTypes } from '@exportx/shared-models-and-services';
import ActualReport from './actual-report';
import { Card } from 'antd';
import { isPermissionExist } from '../../../../common';
import { useNavigate } from 'react-router-dom';

interface IProps {
    sampleType: SamplingTypes;
    contractType: ContractModesEnum
}

export const PurchaseSampleReport = (props: IProps) => {
    const navigate = useNavigate();
    const { sampleType, contractType } = props;
   
    useEffect(() => {
            let actionTypee;
    
            if(contractType === ContractModesEnum.PURCHASE){
                switch (sampleType) {
                    case SamplingTypes.SUPPLIER:
                        actionTypee = 857
                        break;
                    case SamplingTypes.INTERNAL:
                        actionTypee = 861
                        break;
                    case SamplingTypes.ACTUAL:
                        actionTypee = 865
                        break;
                    default:
                        actionTypee = 1
                        break;
                }
            } else {
                switch (sampleType) {
                    case SamplingTypes.ACTUAL:
                        actionTypee = 882
                        break;
                    default:
                        actionTypee = 1
                        break;
                }
            }

            if (!isPermissionExist([actionTypee])) navigate('/');
            
        },[sampleType, contractType])

    return (
        <Card title={`${props.contractType === ContractModesEnum.SALES ? "S&A - Sales" : "S&A - Purchase"}`}>
            <div><ActualReport sampleType={props.sampleType} contractType={ContractModesEnum.PURCHASE} /></div>
        </Card>
    )
}

export default PurchaseSampleReport