import { BNoRequest, BusinessNumberService, BusinessResponseNumberDto } from "@exportx/shared-models-and-services";
import { Affix, Button, Card, Col, Descriptions, Drawer, Form, Input, Layout, Row, Space, Tabs, Tag, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, useAuthState } from "../../../../common";
import queryString from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { CheckCircleOutlined, EditFilled, MessageFilled } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
import ShipmentPlaning from "./shipment-planing";
import VesselData from "./mother-vessel-page";
import AddContract from "./add-contract-from";
import { BargeMappingView } from "../barge";
import SamplingAndAnalysis from "./sampling-and-analysis";
import BNCommercialPage from "./bn-commercial-page";
import BNApprovalsPage from "./business-number-approvals";
import CommentsPage from "../../../../common/comments/comments-page";

export const BusinessNumberDetailView = () => {
    const { authContext } = useAuthState();
    const { formatMessage: fm } = useIntl();
    const printRef = React.useRef();
    const { state }: any = useLocation();
    const navigate = useNavigate();
    //let { businessNo } = state ? state : { businessNo: '' };
    const location = useLocation();
    const paramBargeId = queryString.parse(location.search)?.bn_Id;
    console.log(paramBargeId,"=====================")
    console.log(queryString.parse(location.search));
    let { businessNo } = paramBargeId != null ? { businessNo: paramBargeId } : state ? state : { businessNo: null };

    const [previewData, setPreviewData] = useState<BusinessResponseNumberDto>(null);
    const [businessNumberCreation, setBusinessNumberCreation] = useState(null);
    const [searchedText, setSearchedText] = useState("");
    const [shipmentDataFormRef] = Form.useForm();
    const [motherVesselFormRef] = Form.useForm()
    const [allocationFormRef] = Form.useForm()
    const [samplingFormRef] = Form.useForm();
    const [hatchData, setHatchData] = useState<any>()
    const [dummyRefresh, setDummyRefresh] = useState(1);
    const [allocationForm, setAllocationForm] = useState<Boolean>(true)
    const [witnessAnalysis, setWitnessAnalysis] = useState<any>({ witnessAnalysis: [''] });
    const [mappingSurveyors, setMappingSurveyors] = useState<any>();
    const [viewForm, setViewForm] = useState<boolean>(false);
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [featuresRefName, setFeaturesRefName] = useState<string>('');
    const [commentsTitle, setCommentsTitle] = useState<string>('');

    const service = new BusinessNumberService(); 

    useEffect(() => {
        if(!isPermissionExist([254])) navigate('/')
        getBNoPreviewData(businessNo);
        getShippingAnalysisData();
        getBargeMappingSurveyors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessNo, dummyRefresh]);

    const getBNoPreviewData = (businessNo: string) => {
        const req = new BNoRequest(businessNo);
        service.getBNoPreviewData(req).then(res => {
            if (res.status) {
                setPreviewData(res.data);
                setBusinessNumberCreation(res.data.businessNumberCreation);
                // setShippingPlaning(res.data.shippingPlaning);
                // setvessel(res.data.vessel)
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getShippingAnalysisData = () => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        service.getShippingAnalysisData(req).then(res => {
          if (res.status) {
            setWitnessAnalysis(res.data)
          } else {
            setWitnessAnalysis({ witnessAnalysis: [' '] })
          }
        }).catch(err => {
          console.log(err.message);
        })
      }

      const samplingAnalysisSaveData = () => {
        samplingFormRef.validateFields().then(values => {
          const data = { ...values, businessNo: businessNo }
          service.samplingAnalysisCreate(data).then(res => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getShippingAnalysisData();
              onReset();
            //   setSampling(false);
              setViewForm(false)
              setDummyRefresh(prev => prev + 1);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
          })
        })
      }

      const getBargeMappingSurveyors = () => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        service.getBargeMappingSurveyors(req).then(res => {
          if (res.status) {
            setMappingSurveyors(res.data)
          }
        }).catch(err => console.log(err.message))
      };

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };



    const setFormData = () => {
        navigate('/businessNumber-update', { state: previewData });
    };

    const onReset = () => {
        samplingFormRef.setFieldsValue(undefined);
        samplingFormRef.resetFields();
        // setSampling(false)
        setWitnessAnalysis(undefined);
        setWitnessAnalysis({ witnessAnalysis: [' '] });
        setDummyRefresh(prev => prev + 1);
    
      }

      const commercialOnClose = () => {
        // setCommercial(false);
        // getAllBusinessNumber();
        setDummyRefresh(prev => prev + 1);
    
    
      };

      const commentsData = (refName, title) => {
        setCommentsVisible(true);
        setFeaturesRefName(refName);
        setCommentsTitle(title);
      }

      const getFilteredData = (data: any[]) => {
        if (!searchedText) return data;
        return data?.filter((item: any) => 
            Object.values(item)
                .join(' ')
                .toLowerCase()
                .includes(searchedText.toLowerCase())
        );
    };

    const buyerDemurrageColumns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'bpName',
            // align: 'center',
            // width: 500,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
        },
        {
            title: 'Inco Term',
            dataIndex: 'buyerIncoterm',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
        },
        {
            title: 'Laycan',
            dataIndex: 'buyerBargeWindow',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
            render: (value) => {
                if (Array.isArray(value) && value.length === 2 && moment(value[0]).isValid() && moment(value[1]).isValid()) {
                    return (
                        <>
                            {moment(value[0]).format('DD\u2011MMM\u2011YYYY') + " to " + moment(value[1]).format('DD\u2011MMM\u2011YYYY')}
                        </>
                    );
                } 
            }
        },
        {
          title: 'Barge Window',
          dataIndex: 'bargeWindow',
          render: (value: any, record: any) => {
            if (value && Array.isArray(value) && value.length === 2) {
              const startDate = moment(value[0]);
              const endDate = moment(value[1]);
        
              if (startDate.isValid() && endDate.isValid()) {
                return (
                  <>
                    {startDate.format('DD\u2011MMM\u2011YYYY')} to {endDate.format('DD\u2011MMM\u2011YYYY')}
                  </>
                );
              }
            }
        
            return <span className="NA">N/A</span>;
          },
        },
        {
            title: 'Quantity(MT)',
            dataIndex: 'bdQuantity',
            align: 'right',
            render: (value: any, record: any) => {
                return value ? Number(value).toFixed(3) : <span style={{color: '#94A3B8'}}>N/A</span>
            }
          },
        {
            title: 'Demurrage(MT)',
            dataIndex: 'buyerDemurrage',
            align: 'right',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record) => {
              const formattedValue = value !== null ? value : '';
              const currency = record.buyerDemurrageCurrency !== null ? record.buyerDemurrageCurrency : '';
          
              return (
                <>
                  {formattedValue} <span className="currency-style">{currency}</span>
                </>
              );
            },
          },
          
    ]

    const supplierDemurrageColumns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'bpName',
            // align: 'center',
            // width: 500,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
        },

        {
            title: 'Inco Term',
            dataIndex: 'IncotermType',
            // align: 'center',
            // width: 500,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
        },

        {
            title: 'Laycan',
            dataIndex: 'supplierBargeWindow',
            // align: 'center',
            // width: 500,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
            render: (value) => {
                if (Array.isArray(value) && value.length === 2 && moment(value[0]).isValid() && moment(value[1]).isValid()) {
                    return (
                        <>
                            {moment(value[0]).format('DD\u2011MMM\u2011YYYY') + " to " + moment(value[1]).format('DD\u2011MMM\u2011YYYY')}
                        </>
                    );
                } 
            }
        },

        {
            title: 'Barge Window',
            dataIndex: 'bargeWindow',
            render: (value: any, record: any) => {
              if (value && Array.isArray(value) && value.length === 2) {
                const startDate = moment(value[0]);
                const endDate = moment(value[1]);
          
                if (startDate.isValid() && endDate.isValid()) {
                  return (
                    <>
                      {startDate.format('DD\u2011MMM\u2011YYYY')} to {endDate.format('DD\u2011MMM\u2011YYYY')}
                    </>
                  );
                }
              }
          
              return <span className="NA">N/A</span>; // Display 'null' when value is null or invalid
            },
          },

        {
            title: 'Quantity(MT)',
            dataIndex: 'sdQuantity',
            align: 'right',
            render: (value: any, record: any) => {
                return value ? Number(value).toFixed(3) : <span style={{color: '#94A3B8'}}>N/A</span>
            }
          },

        {
            title: 'Demurrage(MT)',
            dataIndex: 'supplierDemurrage',
            align: 'right',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record) => {
              const formattedValue = value !== null ? value : '';
              const currency = record.supplierCurrency !== null ? record.supplierCurrency : '';
          
              return (
                <>
                  {formattedValue} <span className="currency-style">{currency}</span>
                </>
              );
            },
          },
    ]

    // const supplierDemurrageColumns: ColumnsType<any> = [
    //     {
    //         title: 'Supplier',
    //         dataIndex: 'bpName',
    //         // align: 'center',
    //     },
    //     {
    //         title: 'Supplier Demurrage',
    //         dataIndex: 'supplierDemurrage',
    //         align: 'center',
    //         // render: (value, record) => {
    //         //   const formattedValue = value !== null ? value : '';
    //         //   const currency = record.supplierCurrency !== null ? record.supplierCurrency : '';
          
    //         //   return (
    //         //     <>
    //         //       {formattedValue + ' ' + currency}
    //         //     </>
    //         //   );
    //         // },
    //       },
    //       {
    //         title: 'Incoterm',
    //         dataIndex: 'IncotermType',
    //         // align: 'center',
    //     },
    //       {
    //         title: 'Supplier Barge Window',
    //         dataIndex: 'supplierBargeWindow',
    //         align: 'center',
    //         // render: (value: any, record: any) => {
    //         //   if (value && Array.isArray(value) && value.length === 2) {
    //         //     const startDate = moment(value[0]);
    //         //     const endDate = moment(value[1]);
          
    //         //     if (startDate.isValid() && endDate.isValid()) {
    //         //       return (
    //         //         <>
    //         //           {startDate.format('DD-MMM-YYYY')} TO {endDate.format('DD-MMM-YYYY')}
    //         //         </>
    //         //       );
    //         //     }
    //         //   }
          
    //         //   return <></>; // Display 'null' when value is null or invalid
    //         // },
    //       },
    //       {
    //         title: 'barge Window',
    //         dataIndex: 'bargeWindowData',
    //         align: 'center',
    //         // render: (value: any, record: any) => {
    //         //   for(const data of value){
    //         //     if (data.bargeWindow && data.bargeWindow.length === 2) {
    //         //     const startDate = moment(data.bargeWindow[0]);
    //         //     const endDate = moment(data.bargeWindow[1]);
          
    //         //     if (startDate.isValid() && endDate.isValid()) {
    //         //       return (
    //         //         <>
    //         //           {startDate.format('DD-MMM-YYYY')} TO {endDate.format('DD-MMM-YYYY')}
    //         //         </>
    //         //       );
    //         //     }
    //         //   }
    //         //   }
    //         //   return <></>; 
    //         // },
       
    //       },
    //       {
    //         title: 'Supplier Quantity',
    //         dataIndex: 'supplierQuantity',
    //       },
          

    // ]


    const anchorageColumns: ColumnsType<any> = [
        {
            title: 'Anchorage',
            dataIndex: 'anchorageName',
            // align: 'center',
            // width:400,
            filteredValue: [String(searchedText).toLowerCase()],

        },
        {
            title: 'Estimated Date',
            dataIndex: 'estimateDate',
            // align: 'center',
            render: (value) => {
              const formattedDate = moment(value);
              if (formattedDate.isValid()) {
                return <>{formattedDate.format('DD\u2011MMM\u2011YYYY')}</>;
              } else {
                return <></>; // Display "N/A" when the date is not valid
              }
            },
          },
          
        {
            title: 'Laycan',
            dataIndex: 'laycan',
            // align: 'center',
            width: 120,
            render: (value) => {
                return <>
                    {value[0] !== null && value[1] !== null ? moment(value[0]).format('DD\u2011MMM\u2011YYYY') + " to " + moment(value[1]).format('DD\u2011MMM\u2011YYYY') : '--'}
                </>
            }
        },
    ]

    const BuyerDetailsDataProcessing = (values: any[]) => {
        const buyerData = [];
        values?.forEach((rec) => {
            if (!rec.buyerBargeWindowData || rec.buyerBargeWindowData.length === 0) {
                buyerData.push({
                    demurrageId: rec.demurrageId,
                    bpName: rec.bpName,
                    buyerIncoterm: rec.buyerIncoterm,
                    buyerDemurrage: rec.buyerDemurrage,
                    buyerDemurrageCurrency: rec.buyerDemurrageCurrency,
                    buyerBargeWindow: rec.buyerBargeWindow,
                    buyerQuantity: rec.buyerQuantity,
                    rowSpan: 1
                });
            } else {
                rec.buyerBargeWindowData.forEach((bargeWindowRecord, index) => {
                    buyerData.push({
                        buyerBargeWindowId: bargeWindowRecord.buyerBargeWindowId,
                        bargeWindow: bargeWindowRecord.bargeWindow,
                        bdQuantity: bargeWindowRecord.bdQuantity,
                        rowSpan: index === 0 ? rec.buyerBargeWindowData.length : 0,
                        demurrageId: rec.demurrageId,
                        bpName: rec.bpName,
                        buyerIncoterm: rec.buyerIncoterm,
                        buyerDemurrage: rec.buyerDemurrage,
                        buyerDemurrageCurrency: rec.buyerDemurrageCurrency,
                        buyerBargeWindow: rec.buyerBargeWindow,
                        buyerQuantity: rec.buyerQuantity,
                    });
                });
            }
        });
        return buyerData;
    };
    
    const SupplierDetailsDataProcessing = (values: any[]) => {
        const supplierData = [];
        values?.forEach((rec) => {
            if (!rec.bargeWindowData || rec.bargeWindowData.length === 0) {
                supplierData.push({
                    demurrageId: rec.demurrageId,
                    bpName: rec.bpName,
                    IncotermType: rec.IncotermType,
                    supplierDemurrage: rec.supplierDemurrage,
                    supplierCurrency: rec.supplierCurrency,
                    supplierBargeWindow: rec.supplierBargeWindow,
                    supplierQuantity: rec.supplierQuantity,
                    rowSpan: 1
                });
            } else {
                rec.bargeWindowData.forEach((bargeWindowRecord, index) => {
                    supplierData.push({
                        bargeWindowId: bargeWindowRecord.bargeWindowId,
                        bargeWindow: bargeWindowRecord.bargeWindow,
                        sdQuantity: bargeWindowRecord.sdQuantity,
                        rowSpan: index === 0 ? rec.bargeWindowData.length : 0,
                        demurrageId: rec.demurrageId,
                        bpName: rec.bpName,
                        IncotermType: rec.IncotermType,
                        supplierDemurrage: rec.supplierDemurrage,
                        supplierCurrency: rec.supplierCurrency,
                        supplierBargeWindow: rec.supplierBargeWindow,
                        supplierQuantity: rec.supplierQuantity,
                    });
                });
            }
        });
        return supplierData;
    };
    
    const bargeMapping = (businessNo: string) => {
        navigate('/bargeMapping-view', { state: { businessNo } });
      }

    return (
        <>
            <div className="layout" ref={printRef}>
                <Row 
                className="mb-12"
                justify="space-between" 
                align="middle" 
                >
                    <Col>
                        <Space size={5}>
                            <a href="/#/businessnumber" className="arrow-left">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </a>
                            <span className="business-no">
                                {`${businessNumberCreation?.['businessNo']} - ( ${businessNumberCreation?.['motherVesselName']} )`}
                            </span>
                            &nbsp;
                            <Tag icon={<CheckCircleOutlined />} className="active-tag">
                                {previewData?.['status']} 
                            </Tag>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            className="download-btn"
                            icon={<FontAwesomeIcon icon={faDownload} />}
                            onClick={handleDownloadPdf}
                        />
                    </Col>
                </Row>
                <Card className="card-br-8">
                    <Tabs tabPosition='top'>
                        <TabPane tab="Basic Details" key="1">
                            <Row className='card-title-buttons mb-12' justify="space-between" >
                                <Col>
                                    <Input.Search placeholder="Search" allowClear className='input-search' onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} />
                                </Col>
                                <Col>
                                    <Space size='middle'>
                                        <Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('', 'All Comments')} /></Tooltip>
                                        <EditFilled type="edit"
                                            className='action-icons'
                                            onClick={setFormData}
                                        />
                                    </Space>    
                                </Col>
                            </Row>
                            
                            <Card title={<span className="card-title-md">Basic Details</span>}
                                    extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Basic Details > Basic Details', 'Basic Details')} /></Tooltip>}
                                    className="card-br-8 mb-12" headStyle={{border: 'none'}}>
                                <Descriptions
                                    className='descriptions'
                                    title=''
                                    column={{ xxl: 6, xl: 6, lg: 6, md: 3, sm: 2, xs: 1 }}
                                    layout='vertical'
                                    colon={false}
                                >
                                    <Descriptions.Item label={`Business Type`}>
                                        {businessNumberCreation?.businessType}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Consignment Type">
                                        {businessNumberCreation?.consignmentType}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Mother Vessel Name">
                                        {businessNumberCreation?.motherVesselName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Estimated Cargo">
                                        {businessNumberCreation?.estimatedCargo ? businessNumberCreation.estimatedCargo : <span className="NA">N/A</span>}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Shipment Type">
                                        {businessNumberCreation?.shipmentType}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="IMO No">
                                        {businessNumberCreation?.imoNo ? businessNumberCreation.imoNo : <span className="NA">N/A</span>}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <Row gutter={[12,12]} >
                                <Col span={16} xs={24} md={24} lg={16} className="flex-col">
                                    <Row className="mb-12">
                                        <Card title={<span className="card-title-md">Buyer Details</span>}
                                                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Basic Details > Buyer Details', 'Buyer Details')} /></Tooltip>}
                                                headStyle={{border: 'none'}}
                                                className="contracts-list card-br-8 contracts-List full-width"
                                        >
                                            <Table pagination={false} size="middle" className="overflowX" columns={buyerDemurrageColumns} dataSource={getFilteredData(BuyerDetailsDataProcessing(businessNumberCreation?.buyerDemurrage))} ></Table>
                                        </Card>
                                    </Row>
                                    <Row>
                                        <Card title={<span className="card-title-md">Supplier Details</span>}
                                                extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Basic Details > Supplier Details', 'Supplier Details')} /></Tooltip>}
                                                headStyle={{border: 'none'}}
                                                className="contracts-list card-br-8 contracts-List full-width"
                                        >
                                            <Table pagination={false} size="middle" className="overflowX" columns={supplierDemurrageColumns} dataSource={getFilteredData(SupplierDetailsDataProcessing(businessNumberCreation?.supplierDemurrage))} ></Table>
                                        </Card>
                                    </Row>
                                </Col>
                                <Col span={8} xs={24} md={24} lg={8} className="flex-col">
                                    <Card title={<span className="card-title-md">Loading Anchorage</span>}
                                            extra={<Tooltip placement="top" title='Comments'><MessageFilled className='message-icon' onClick={() => commentsData('BusinessNo-Basic Details > Loading Anchorage', 'Loading Anchorage')} /></Tooltip>}
                                            headStyle={{border: 'none'}}
                                            className="contracts-list card-br-8 contracts-List flex-1"
                                    >
                                        <Table className="overflowX" columns={anchorageColumns} dataSource={getFilteredData(businessNumberCreation?.loadingAnchorage)} pagination={false} ></Table>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Shipment Planning" key="2">
                            <ShipmentPlaning setDummyRefresh={setDummyRefresh} key={`SamplingAndAnalysis${businessNo} ${dummyRefresh}`} formRef={shipmentDataFormRef} businessNo={businessNo} setHatchData={setHatchData}  setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible}/>
                        </TabPane>
                        <TabPane tab="Mother Vessel" key="3">
                            <VesselData setDummyRefresh={setDummyRefresh} key={`MotherVessel${businessNo} ${dummyRefresh}`} hatchData={hatchData} formRef={motherVesselFormRef} businessNo={businessNo} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>
                        <TabPane tab="Contract Allocation" key="4">
                        <AddContract key={businessNo + dummyRefresh} setDummyRefresh={setDummyRefresh} formRef={allocationFormRef} business={businessNo} allocationForm={allocationForm} setAllocationForm={setAllocationForm} bargeBusiness={businessNo} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>
                        <TabPane tab="Barge Mapping" key="5">
                           <BargeMappingView  businessNo={businessNo} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>
                        <TabPane tab="Sampling and Analysis" key="6">
                            <SamplingAndAnalysis setDummyRefresh={setDummyRefresh} key={`SamplingAndAnalysis${businessNo} ${dummyRefresh}`} businessNo={businessNo} formRef={samplingFormRef} initialValues={witnessAnalysis} layoutType={'vertical'} setViewForm={setViewForm} viewForm={viewForm} onResets={onReset} samplingAnalysisSaveData={samplingAnalysisSaveData} mappingSurveyors={mappingSurveyors} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>
                        <TabPane tab="Documents" key="7">
                            <BNCommercialPage key={`Commercial${businessNo} ${dummyRefresh}`} businessNo={businessNo} cancelHandler={() => { commercialOnClose() }} submitHandler={() => { }} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>
                        <TabPane tab="Approvals" key="8">
                            <BNApprovalsPage setDummyRefresh={setDummyRefresh} key={`Approvals${businessNo} ${dummyRefresh}`} businessNo={businessNo} setFeaturesRefName={setFeaturesRefName} setCommentsTitle={setCommentsTitle} setCommentsVisible={setCommentsVisible} />
                        </TabPane>

                    </Tabs>
                </Card>


            </div>
                <Drawer
                    bodyStyle={{ paddingBottom: 0 }}
                    width={700}
                    onClose={() => setCommentsVisible(false)}
                    open={commentsVisible}
                    closable={false}
                >
                    <CommentsPage businessNo={businessNo} featuresRefName={featuresRefName} commentsTitle={commentsTitle} setCommentsVisible={setCommentsVisible}/>
                </Drawer>

            {/* <br /> */}
{/* 
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>

                <Row justify="end">
                    <Col><>
                        <Button href="/businessnumber" style={{ color: 'black' }}>Business Number Grid</Button>
                        <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Business Number</Button>
                    </></Col></Row>
                <Card
                    title={<span >Business Number Detail View - {businessNumberCreation?.['businessNo']} <Tag color="#55acee">
                        {previewData?.['status']}
                    </Tag></span>}
                    style={{ textAlign: 'center' }}
                    headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
                    ref={printRef}
                // extra={<>{getExtraComponent(previewData?.['status'])}</>}
                >
                    <br></br>
                    <Card title={'Business Number Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label={`Business Type`}>
                                {businessNumberCreation?.businessType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Consignment Type">
                                {businessNumberCreation?.consignmentType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mother Vessel Name">
                                {businessNumberCreation?.motherVesselName}
                            </Descriptions.Item>

                            <Descriptions.Item label="Estimated Cargo">
                                {businessNumberCreation?.estimatedCargo}
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipment Type">
                                {businessNumberCreation?.shipmentType}
                            </Descriptions.Item>
                            <Descriptions.Item label="IMO No">
                                {businessNumberCreation?.imoNo}
                            </Descriptions.Item>
                            <Descriptions.Item label="Barge Window">
                                {businessNumberCreation?.bargeWindow ? moment(businessNumberCreation?.bargeWindow[0]).format('DD-MMM-YYYY') + " " + "To" + " " + moment(businessNumberCreation?.bargeWindow[1]).format('DD-MMM-YYYY') : ''}
                            </Descriptions.Item>

                        </Descriptions>
                    </Card>
                    <br></br>
                    <Card title={'Buyer Demurrage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Vessel Type">
                                {shippingPlaning?.vesselType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Stevedore Vendor">
                                {shippingPlaning?.stevedoreVendor}
                            </Descriptions.Item>
                            <Descriptions.Item label="Floating Crane Vendor">
                                {shippingPlaning?.floatingCraneVendor}
                            </Descriptions.Item>

                            <Descriptions.Item label="Loading Port Agent">
                                {shippingPlaning?.loadingPortAgent ? moment(shippingPlaning?.loadingPortAgent).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>
                            <Descriptions.Item label="ETA / NOR">
                                {shippingPlaning?.etanor ? moment(shippingPlaning?.etanor).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>
                            <Descriptions.Item label="Loading Rate per Day">
                                {shippingPlaning?.loadRate ? moment(shippingPlaning?.loadRate).format('YYYY-MMM-DD') : " "}
                            </Descriptions.Item>

                        </Descriptions>
                        <Table pagination={false} size="middle" columns={buyerDemurrageColumns} dataSource={businessNumberCreation?.buyerDemurrage}></Table>
                    </Card>
                    <br></br>
                    <Card title={'Supplier Demurrage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="NOR Acceptance">
                                {vessel?.norAcceptance}
                            </Descriptions.Item>
                            <Descriptions.Item label="Vessel Arrived On">
                                {vessel?.vesselArrived}
                            </Descriptions.Item>
                            <Descriptions.Item label="Vessel Sailing">
                                {vessel?.vesselSailing}
                            </Descriptions.Item>

                            <Descriptions.Item label="Supply Tonnage In Mt">
                                {vessel?.vesselFinalQuantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="Document On Board">
                                {vessel?.documentOnLoad}
                            </Descriptions.Item>

                        </Descriptions>
                        <table className='table table-bordered table-sm'>
                    <thead className='table-header' style={{ background: '#fafafa' }}>
                        {['Supplier','Supplier Demurrage','Incoterm Type','Supplier Barge Window','Barge Window', 'Barge Qty','Supplier Quantity']?.map((item) => (
                            <th className='text-center'>{item}</th>
                        ))}</thead>
                    <tbody>
                        {businessNumberCreation?.supplierDemurrage?.map((e, index) => e.bargeWindowData.map((item, idx) => {
                            return (
                                <tr key={item.id}>
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.bpName}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.supplierDemurrage}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.IncotermType}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}}rowSpan={e.bargeWindowData.length}>{e.supplierBargeWindow}</td>
                                    }
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}}>{`${moment(item.bargeWindow[0]).format('DD-MM-YYYY')} to ${moment(item.bargeWindow[1]).format('DD-MM-YYYY')}`}</td>
                                    
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}}>{`${item.sdQuantity || ''}`}</td>
                                    
                                    
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}} rowSpan={e.bargeWindowData.length}>{e.supplierQuantity}</td>
                                    }
                                </tr>
                            )
                        }
                        ))}
                    </tbody>
                </table>
                        <Table columns={supplierDemurrageColumns} dataSource={businessNumberCreation?.supplierDemurrage} pagination={false}></Table>
                    </Card>
                    <br />
                    <Card title={'Loading Anchorage'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Table columns={anchorageColumns} dataSource={businessNumberCreation?.loadingAnchorage} pagination={false}></Table>
                    </Card>

                </Card><br></br>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            {isPermissionExist([255]) && (<Col><Button onClick={setFormData}>Edit</Button></Col>)}
                        </Row>
                    </Card>
                </Affix>
            </Layout> */}
        </>
    )
}
export default BusinessNumberDetailView;
