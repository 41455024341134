import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { BGCostingParentReqDto, BPFCostingParentDto, BargeCostingsService, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingWorkLogsEnum, PerformaCoalCostingService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { SequenceUtils, internationalFormattedValue } from '@exportx/ui-utils';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../../common';
import { BargeCostingChild } from './barge-costing-child';
import { Card, Input, Row, Typography } from 'antd'
import { PaymentNoticePageEnum } from '../../../payment-notice';

interface IBargeCostingApproval {
  type?: ContractStatusEnum;
  costingType: CostingWorkLogsEnum;
  contractType?: ContractModesEnum;
  paymentType?: PaymentNoticePageEnum
}

export enum BargeCostingApprovalScreen {
  PMT_NOTICE = 'pmt-notice'
}
export const BargeCostingApproval = (props: IBargeCostingApproval) => {
  const { type, costingType, contractType, paymentType } = props;
  const [expandedIndex, setExpandedIndex] = useState([]);
  const bargeCostingsService = new BargeCostingsService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const { authContext } = useAuthState();
  const [bargeCostingData, setBargeCostingData] = useState<BPFCostingParentDto[]>([]);
  const [searchedText, setSearchedText] = useState("");


  // useEffect(()=>{
  //     console.log('type',type)
  //     console.log('costingType',costingType)
  //     console.log('contractType',contractType)
  //     let permission;
  //     if(type === ContractStatusEnum.BUSINESS_APPROVAL){
  //         if(contractType === ContractModesEnum.PURCHASE){
  //             if(costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING){
  //                 permission = [275]
  //             } else if(costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING){
  //                 permission = []
  //             }
  //         } else if(contractType === ContractModesEnum.SALES){
  //             if(costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING){
  //                 console.log('sales performa')
  //             } else if(costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING){
  //                 console.log('sales commercial')
  //             }
  //         } else if(contractType === ContractModesEnum.PROCUREMENT_FEES){
  //             if(costingType === CostingWorkLogsEnum.PROCUREMENT_FEES){

  //             }
  //         } else {
  //             if(costingType === CostingWorkLogsEnum.MARKETING_FEES){

  //             }
  //         }
  //     }
  // },[])



  const costingsApiReq = new BGCostingParentReqDto();
  costingsApiReq.status = ContractStatusEnum.PENDING_APPROVAL;
  if (type === ContractStatusEnum.AUDIT_APPROVAL) {
    costingsApiReq.status = ContractStatusEnum.PENDING_AUDIT_APPROVAL
  }
  else if (type === ContractStatusEnum.MANAGEMENT_APPROVAL) {
    costingsApiReq.status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL
  }
  costingsApiReq.plantCode = authContext.defaultPlant;
  costingsApiReq.costingType = costingType


  useEffect(() => {
    if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
      getBargeCostingParentData();
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
      getPerFormData();
    } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.MARKETING_FEES) {
      getCommercialData();
    }
  }, []);


  const getBargeCostingParentData = () => {
    const bgCostingReq = new BGCostingParentReqDto();
    bgCostingReq.status = type === ContractStatusEnum.PMT_NOTICE ? ContractStatusEnum.ACTIVE : type === ContractStatusEnum.BUSINESS_APPROVAL ? ContractStatusEnum.PENDING_APPROVAL : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    bgCostingReq.plantCode = authContext.defaultPlant;
    bgCostingReq.costingType = costingType
    bargeCostingsService.getBargeCostingParentData(bgCostingReq).then(res => {
      if (res.status) {
        setBargeCostingData(res.data);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }

  const getPerFormData = () => {
    perFormaService.getPerformaApprovalCostings(costingsApiReq).then(res => {
      if (res.status) {
        setBargeCostingData(res.data);
      }
    }).catch(err => console.log(err.message))
  }

  const getCommercialData = () => {
    commercialService.getCommercialApprovalCostings(costingsApiReq).then(res => {
      if (res.status) {
        setBargeCostingData(res.data);
      }
    }).catch(err => console.log(err.message))
  }

  const getRoutes = (costingType) => {
    const routes = {
      [CostingWorkLogsEnum.BARGE_COSTING]: 'barge-costings/barge',
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: 'performaCoalCosting',
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: 'performaCostingSale',
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
      [CostingWorkLogsEnum.SALES_FREIGHT_COSTING]: `salesFreightCosting`,
    }
    return routes[costingType]
  };


  const tableColumns: ColumnsType<any> = [
    {
      title: 'Costing Number',
      dataIndex: 'costingNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },
      render: (value, record) => {
        return <>
          < a href={`/#/${getRoutes(costingType)}?costingId=${record.costingId}`} className="link" >
            {value}
          </a >
        </>
      }
    },
    {
      title: 'Date',
      dataIndex: 'costingDate',
      render: (value, record) => {
        return <>{moment(value).format('YYYY-MM-DD')}</>
      }
    },

    {
      title: 'Purchase ContractNo',
      dataIndex: 'purchaseContractNo',
      render: (v, record) => {
        const id = costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? 'pc_id' : 'sc_id';
        const route = costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? 'p' : 's';
        const link = `/#/${route}o-detail-view?${id}=${record.pcId}`
        return <>
          {<a href={link} className="link">{v}</a>}
        </>
      }
    },
    {
      title: 'ContractNO',
      dataIndex: 'contractNo',
      render: (v, record) => {
        const link = `/#/${record.contractType}-detailedView?cpcId=${record.contractId}`
        return <>
          {<a href={link} className="link">{v}</a>}
        </>
      }
    },


    {
      title: 'Supplier Name',
      dataIndex: 'bargeSupplier',
    },
    {
      title: 'Amount To Be Paid',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>{internationalFormattedValue(value, 3)}</Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
        </>

      }
    },

  ]

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }
  const renderItems = (record: any, index, indent, expanded) => {
    return <>
      <BargeCostingChild paymentType={paymentType} contractType={contractType} type={type} costingInfo={record} getBargeCostingParentData={getBargeCostingParentData} costingType={costingType} />
    </>
  };

  return (
    <Card>
      <Row className='card-title-buttons mb-3'>

        <Input.Search
          placeholder="Search Orders"
          allowClear
          value={searchedText}
          onChange={(e) => setSearchedText(e.target.value)}
          className='input-search'
        />
      </Row>

      <Table
        size='small'
        className='contracts-list'
        rowKey={record => record.costingId}
        columns={tableColumns}
        dataSource={bargeCostingData}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right'
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined
              onClick={(e) => onExpand(record, e)}
            >
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
          )
        }
        scroll={{ x: 500 }}

        bordered={false}
      />

    </Card>
  )
}

export default BargeCostingApproval;