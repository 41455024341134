import { useEffect, useState } from "react"
import { internationalFormattedValue } from "@exportx/ui-utils";
import { Typography, Col, Card, Row, Flex, Divider } from "antd";
import moment from "moment";
import { useAuthState } from "../../../common";
import { BargeCostingsService, BgDemurrageIdReqDto, ShippingTypes } from "@exportx/shared-models-and-services"


const { Text } = Typography;
interface DemurrageDetailsProps {
    costingId: string;
    remainingAmount?: number;
    children?: React.ReactNode;
}

export const DemurrageDispatchDetails = (props: DemurrageDetailsProps) => {
    const { costingId, remainingAmount, children } = props;
    const { authContext } = useAuthState();
    const bargeCostingService = new BargeCostingsService();

    const [data, setData] = useState<any>();
    useEffect(() => {
        const req = new BgDemurrageIdReqDto(costingId, authContext.defaultPlant)
        bargeCostingService.getBgDemurrageById(req).then(res => {
            if (res.status) {
                setData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })


    }, [costingId])

    return (
        <>
            <Row gutter={8}>
                <Col span={16}>
                    <Card
                        className="card-shadow mb-2"
                        title={<span className="card-title-md">Demurrage/Dispatch Details</span>}
                        styles={{ body: { padding: '6px 14px' } }}
                    >

                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Mv Stowage Plan</Text>
                            <Text className="price-card-text"> {data?.stowagePlanInMt}</Text>
                        </Flex>
                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Contract No</Text>
                            <Text className="price-card-text"> {data?.contractNo ?? '-'} </Text>
                        </Flex>
                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Shipping Type</Text>
                            <Text className="price-card-text">{data?.shippingType} </Text>
                        </Flex>
                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Supply Tonage</Text>
                            <Text className="price-card-text">
                                {internationalFormattedValue(data?.supplyTonnage, 3)}  {data?.quoteCurrency}
                            </Text>
                        </Flex>
                        {[ShippingTypes.CFR_DP, ShippingTypes.CFR_DP].includes(data?.shippingType) &&
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Port Type</Text>
                                <Text className="price-card-text">{data?.portType} </Text>
                            </Flex>
                        }

                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Demurrage/Dispatch Applicable</Text>
                            <Text className="price-card-text">{data?.costingApplicable ? 'Yes' : 'N/A'} </Text>
                        </Flex>
                        <Flex justify="space-between" align='center'>
                            <Text className="price-card-text">Notes</Text>
                            <Text className="price-card-text">{data?.notes ?? 'N/A'} </Text>
                        </Flex>
                    </Card>


                    {children}

                </Col >

                {data?.costingApplicable &&


                    <Col span={8}>
                        <Card
                            className="card-shadow"
                            title={<span className="card-title-md">Price Details</span>}
                            styles={{ body: { padding: '6px 14px' } }}
                        >

                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Load Rate Per Day</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.portRate, 3)}  {data?.quoteCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Demurrage Rate Per Day</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.demurrgeRatePerDay, 3)}  {data?.quoteCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Dispatch Rate Per Day</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.dispatchRatePerDay, 3)}  {data?.quoteCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Allowed Time to Load</Text>
                                <Text className="price-card-text">{data?.allowedTimeToLoad} </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Time Completed to Load</Text>
                                <Text className="price-card-text">{data?.timeCompletedForLoadMV} </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">No of Diff Days</Text>
                                <Text className="price-card-text">{data?.noofDaysDifference} </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Exchange Date</Text>
                                <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Exchange Rate</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.quoteCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Amount</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.cost, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Discount</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.discount, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Amount After Discount</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.amtAftDiscount, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>

                            <Divider dashed={true} />

                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Tax 1</Text>
                                <Text className="price-card-text">{`${data?.taxName1 || 'N/A'} (${data?.taxPercent1 || '0'})`}</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">Tax 2</Text>
                                <Text className="price-card-text">{`${data?.taxName2 || 'N/A'} (${data?.taxPercent2 || '0'})`}</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-text">TDS</Text>
                                <Text className="price-card-text">{`${data?.tdsName || 'N/A'} (${data?.tdsPercent || '0'})`}</Text>
                                <Text className="price-card-text">
                                    {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>

                            <Divider dashed={true} />
                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-heading">Total Amount</Text>
                                <Text className="price-card-heading">
                                    {internationalFormattedValue(data?.totalAmount, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>

                            <Flex justify="space-between" align='center'>
                                <Text className="price-card-green">To be paid to Supplier</Text>
                                <Text className="price-card-green">
                                    {internationalFormattedValue(data?.totalAmount, 3)}  {data?.baseCurrency}
                                </Text>
                            </Flex>

                            {Number(remainingAmount) > 0 &&

                                <Flex justify="space-between" align='center'>
                                    <Text className="price-card-red">Remaining Amount</Text>
                                    <Text className="price-card-red">
                                        {internationalFormattedValue(remainingAmount, 3)}  {data?.baseCurrency}
                                    </Text>
                                </Flex>
                            }
                        </Card>
                    </Col>
                }
            </Row>

            {/* <table className="table table-bordered table-sm mt-4">
                <tr>
                    <th colSpan={2}>Mv Stowage Plan</th>
                    <td> <Text>{data?.stowagePlanInMt}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Contract No</th>
                    <td> <Text>{data?.contractNo}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Shipping Type</th>
                    <td> <Text>{data?.shippingType}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Supply Tonage</th>
                    <td> <Text>{getLocalFormat(data?.supplyTonnage, data?.quoteCurrency)}</Text></td>
                </tr>
                {[ShippingTypes.CFR_DP, ShippingTypes.CFR_DP].includes(data?.shippingType) &&
                    <tr>
                        <th colSpan={2}>Port Type</th>
                        <td> <Text>{data?.portType}</Text></td>
                    </tr>
                }
                <tr>
                    <th colSpan={2}>Demurrage/Dispatch Applicable</th>
                    <td> <Text>{data?.costingApplicable ? 'Yes' : 'N/A'}</Text></td>
                </tr>

                {data?.costingApplicable &&
                    <>

                        <tr>
                            <th colSpan={2}>Load Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.portRate, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Demurrage Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.demurrgeRatePerDay, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Dispatch Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.dispatchRatePerDay, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Allowed Time to Load</th>
                            <td> <Text>{data?.allowedTimeToLoad}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Time  Completed to Load</th>
                            <td> <Text>{data?.timeCompletedForLoadMV}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>No of Diff Days</th>
                            <td> <Text>{data?.noofDaysDifference}</Text></td>
                        </tr>

                        <tr>
                            <th colSpan={2}>Exchange Date</th>
                            <td>{data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Exchange Rate</th>
                            <td>
                                <Text>{data?.exchangeRate}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Amount</th>
                            <td>
                                <Text>{getLocalFormat(data?.cost, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Discount</th>
                            <td>
                                <Text>{getLocalFormat(data?.discount, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Notes</th>
                            <td>
                                <Text>{data?.notes}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Amount After Discount</th>
                            <td>
                                <Text>{getLocalFormat(data?.amtAftDiscount, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th>TAX 1</th>
                            <td>
                                <Text>{`${data?.taxName1 || ''} - ${data?.taxPercent1}`}</Text>
                            </td>
                            <td>{getLocalFormat(data?.taxTotal1, data?.baseCurrency)}</td>
                        </tr>
                        <tr>
                            <th>TAX 2</th>
                            <td>
                                <Text>{`${data?.taxName2 || ''} - ${data?.taxPercent2}`}</Text>
                            </td>
                            <td>
                                <Text>{getLocalFormat(data?.taxTotal2, data?.baseCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>TDS:</th>
                            <td><Text>{`${data?.tdsName} - ${data?.tdsPercent}`}</Text></td>
                            <td>{getLocalFormat(data?.tdsTotal, data?.baseCurrency)}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} > Total Amount</th>
                            <td>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</td>

                        </tr>
                        <tr>
                            <th colSpan={2} >
                                To be paid to Supplier
                            </th>
                            <td>
                                <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</span>
                            </td>
                        </tr>
                    </>
                }

            </table> */}

            {/* <ApprovalTracking history={data?.workLog} /> */}
        </>
    )
}