import { BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, CostingReportsReqDto, ReportsService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Row, Select, Table, Tabs, Typography  } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import moment from "moment";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate } from "react-router-dom";

const {Option} = Select;
const { Text } = Typography;

export const ProfitLossReports = () => {
  const { authContext } = useAuthState();
  const navigate = useNavigate()
  const [plReport, setPlReport] = useState<any>();
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [selectedBusinessNo, setSelectedBusinessNo] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [pcPerformaTotalAmount, setPcPerformaTotalAmount] = useState<number>(0);
  const [pcCommercialTotalAmount, setPcCommercialTotalAmount] = useState<number>(0);
  const [scPerformaTotalAmount, setScPerformaTotalAmount] = useState<number>(0);
  const [scCommercialTotalAmount, setScCommercialTotalAmount] = useState<number>(0);
  const [pcProcurementTotalAmount, setPcProcurementTotalAmount] = useState<number>(0);
  const [pcMarketingTotalAmount, setPcMarketingTotalAmount] = useState<number>(0);
  const [pcExpenseTotalAmount, setPcExpenseTotalAmount] = useState<number>(0);
  const [deadFreightTotalAmount, setDeadFreightTotalAmount] = useState<number>(0);
  const [demurrageTotalAmount, setDemurrageTotalAmount] = useState<number>(0);
  const [scFreightTotalAmount, setScFreightTotalAmount] = useState<number>(0);
  const [pcBargeCostingTotalAmount, setPcBargeCostingTotalAmount] = useState<number>(0);
  const [scExpenseTotalAmount, setExpenseTotalAmount] = useState<number>(0)



  const [pcFinalAmount, setPcFinalAmount] = useState<number>(0);
  const [scFinalAmount, setScFinalAmount] = useState<number>(0);



  const service = new ReportsService();
  const businessService = new BusinessNumberService();

  useEffect(() => {
    getBusinessNoDropDown()
  },[])

  useEffect(() => {
      if(selectedBusinessNo){
        let businessNo = businessDropDown?.filter(item => item.bnId === selectedBusinessNo)
        let bnNo = `${businessNo[0]?.bnNo} (${businessNo[0]?.motherVesselName})`
        setTitle(bnNo)
      }
  },[selectedBusinessNo])

  useEffect(() => {
    if (plReport) {
      const performaTotal = plReport?.purchase?.performaPurchaseData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const commercialTotal = plReport?.purchase?.commercialPurchaseData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const procurementTotal = plReport?.purchase?.procurementFeeData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const marketingData = plReport?.purchase?.marketingFeeData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const expenseTotal = plReport?.purchase?.expenseData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const deadFreightTotal = plReport?.purchase?.deadFreightData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const demurrageTotal = plReport?.purchase?.bemurrageData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const bargeCostingTotal = plReport?.purchase?.bargeData?.reduce((sum, item) => sum + Number(item.total || 0), 0)

      const performaSalesTotal = plReport?.sales?.performaSalesData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const commercialSalesTotal = plReport?.sales?.commercialSalesData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const salesExpenseTotal = plReport?.sales?.salesExpenseData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      const salesFreightTotal = plReport?.sales?.scFreightData?.reduce((sum, item) => sum + Number(item.total || 0), 0);
      

  
      setPcPerformaTotalAmount(performaTotal);
      setPcCommercialTotalAmount(commercialTotal);
      setPcProcurementTotalAmount(procurementTotal);
      setPcExpenseTotalAmount(expenseTotal);
      setScPerformaTotalAmount(performaSalesTotal);
      setScCommercialTotalAmount(commercialSalesTotal);
      setDeadFreightTotalAmount(deadFreightTotal);
      setDemurrageTotalAmount(demurrageTotal)
      setScFreightTotalAmount(salesFreightTotal)
      setPcBargeCostingTotalAmount(bargeCostingTotal)
      setPcMarketingTotalAmount(marketingData)
      setExpenseTotalAmount(salesExpenseTotal)
  
      const pcfinal = commercialTotal > 0 
        ? commercialTotal + procurementTotal + expenseTotal + deadFreightTotal + demurrageTotal + bargeCostingTotal + marketingData
        : performaTotal + procurementTotal + expenseTotal + deadFreightTotal + demurrageTotal + bargeCostingTotal + marketingData;

      const scfinal = commercialSalesTotal > 0
        ? commercialSalesTotal + salesExpenseTotal + salesFreightTotal
        : performaSalesTotal + salesExpenseTotal + salesFreightTotal;
  
      setPcFinalAmount(pcfinal);
      setScFinalAmount(scfinal)
    }
  }, [plReport]);

  const submitHandler = async () => {
    setPlReport({})
    const req = new CostingReportsReqDto("",selectedBusinessNo,authContext.defaultPlant)
    if(!req.businessNo){
        return AlertMessages.getErrorMessage('Please Select business No');
    }
      service.getProfitLossByBusinessNo(req).then((res) => {
        if(res.status){
          setPlReport(res.data)
        } else {
          setPlReport({})
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch((err) => {
          console.log(err)
      })
  }

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    businessService.getBusinessNoDropDown(req).then(res => {
      if (res.status) {
        let dropdown = res.data.map(item => {
          let bnNo = item.businessNo.split(" ").pop()
          return {
            ...item,
            bnNo: bnNo
          }
        })
        setBusinessDropDown(dropdown)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getSeletedBusinessNo = (value: any) => {
    setSelectedBusinessNo(value)
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
  }

  const columns3 = ["Costing No", "Costing Type", "Costing Date", "Business Partner", "QTY (MT)", "RATE", "AMOUNT"];

  const columns: any = [
    {
      title: "",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <span style={{ fontWeight: record.name === "Profit / Loss" ? "bold" : "normal" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Amount",
      align: "right",
      dataIndex: "amount",
      render: (text: string, record: any) => {
        if (record.name === "Profit / Loss") {
          return (
            <span style={{ color: pcFinalAmount > scFinalAmount ? "red" : "green", fontWeight: "bold" }}>
              {text}
            </span>
          );
        }
        return text;
      },
    }
  ]

  const profitLoss = [
    {
      name: "Total Expenses",
      amount: formatCurrency(pcFinalAmount)
    },
    {
      name: "Total Income",
      amount: formatCurrency(scFinalAmount)
    },
    {
      name: "Profit / Loss",
      amount: formatCurrency(scFinalAmount - pcFinalAmount)
    },
  ]

  return (
    <Card 
      title={<span className='card-title'>PL Report : <span className="business-no">{title}</span></span>}
      className='card-container'
      headStyle={{ border: 'none' }}>
      <Card 
        className='card-radius'>
          <Row gutter={10} style={{ marginBottom: 16 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Select
                style={{ width: "100%" }}
                onChange={(value) => getSeletedBusinessNo(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                allowClear
                showSearch
                placeholder="Select Business Number"
              >
                {businessDropDown.map((bn) => {
                  return (
                    <Option value={bn.bnId}>
                      {bn.businessName}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" className="new-button" onClick={submitHandler}>
                Submit
              </Button>
            </Col>
          </Row>

        {/* <Card className="card-br-8"> */}
          <Tabs tabPosition='top'>
              <TabPane tab="Purchase" key="1">
                <table style={{ width: "100%", marginTop: "20px", overflow: "hidden", borderRadius: "8px" }}>  
                  <thead>
                    <tr style={{ backgroundColor: "#f2f7fd", padding: "10px" }}>
                      {columns3.map(item => {
                          return <th style={{ padding: "10px", fontWeight: "600" }}>{item}</th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                  {plReport?.purchase?.performaPurchaseData.length > 0 && plReport?.purchase?.commercialPurchaseData?.length === 0 && (
                      <>
                    <tr style={{ borderBottom: "0.5px solid rgba(209, 214, 219, 0.52)" }}>
                      <td style={{ padding: "8px 10px", fontWeight: "bold" }} className="link">Performa Purchase</td>
                    </tr>
                    {plReport?.purchase?.performaPurchaseData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}} >{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                    </tr>})}
                    <tr className="table-row">
                      <td colSpan={6}></td>
                      <td style={{ fontWeight: "bold" }}>
                        {formatCurrency(pcPerformaTotalAmount)}
                      </td>
                    </tr>  
                    </>
                  )}
                    {plReport?.purchase?.commercialPurchaseData.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td  className="link">Commercial Purchase</td>
                      </tr>
                      {plReport?.purchase?.commercialPurchaseData?.map(item => {
                          return <tr className="table-row">
                                  <td>{item.costingNo}</td>
                                  <td>{item.costingType}</td>
                                  <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                  <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                  {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                  <td>{item.quantity}</td>
                                  <td>{item.rate}</td>
                                  <td>{formatCurrency(item.total)} {item.currency}</td>
                      </tr>})}
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(pcCommercialTotalAmount)}
                        </td>
                      </tr>
                    </>)}
                    {plReport?.purchase?.procurementFeeData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Procurement Fee</td>
                      </tr>
                      {plReport?.purchase?.procurementFeeData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(pcProcurementTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                    {plReport?.purchase?.marketingData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Marketing Fee</td>
                      </tr>
                      {plReport?.purchase?.marketingFeeData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(pcMarketingTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                    {plReport?.purchase?.bargeData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Barge Costing</td>
                      </tr>
                      {plReport?.purchase?.bargeData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(pcBargeCostingTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                    {plReport?.purchase?.expenseData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Expense Extry</td>
                      </tr>
                      {plReport?.purchase?.expenseData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(pcExpenseTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                    {plReport?.purchase?.deadFreightData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Dead Freight</td>
                      </tr>
                      {plReport?.purchase?.deadFreightData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(deadFreightTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                    {plReport?.purchase?.bemurrageData?.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Demurrage</td>
                      </tr>
                      {plReport?.purchase?.bemurrageData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                              </tr>})}
                   
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(demurrageTotalAmount)}
                          </td>
                      </tr>
                    </>
                    )}
                  </tbody>
                  <tfoot>
                    <tr className="table-row">
                      <td colSpan={6} className="total-row"></td>
                      <td style={{ fontWeight: "bold",color: "green" }}>
                          {formatCurrency(pcFinalAmount)}
                        </td>
                    </tr>
                  </tfoot>
                </table> 
              </TabPane>
              <TabPane tab="Sales" key="2">
                {/* <h1>Sales</h1> */}
                <table style={{ width: "100%", marginTop: "20px", overflow: "hidden", borderRadius: "8px" }}>  
                  <thead>
                    <tr style={{ backgroundColor: "#f2f7fd", padding: "10px" }}>
                      {columns3.map(item => {
                          return <th style={{ padding: "10px", fontWeight: "600" }}>{item}</th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                  {plReport?.sales?.performaSalesData.length > 0 && plReport?.sales?.commercialSalesData?.length === 0 && (
                      <>
                    <tr style={{ borderBottom: "0.5px solid rgba(209, 214, 219, 0.52)" }}>
                      <td style={{ padding: "8px 10px", fontWeight: "bold" }} className="link">Performa Sales</td>
                    </tr>
                    {plReport?.sales?.performaSalesData?.map(item => {
                        return <tr className="table-row">
                                <td>{item.costingNo}</td>
                                <td>{item.costingType}</td>
                                <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.rate}</td>
                                <td>{formatCurrency(item.total)} {item.currency}</td>
                    </tr>})}
                    <tr className="table-row">
                      <td colSpan={6}></td>
                      <td style={{ fontWeight: "bold" }}>
                      {formatCurrency(scPerformaTotalAmount)}
                      </td>
                    </tr>  
                    </>
                  )}
                    {plReport?.sales?.commercialSalesData.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td  className="link">Commercial Sales</td>
                      </tr>
                      {plReport?.sales?.commercialSalesData?.map(item => {
                          return <tr className="table-row">
                                  <td>{item.costingNo}</td>
                                  <td>{item.costingType}</td>
                                  <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                  <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                  {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                  <td>{item.quantity}</td>
                                  <td>{item.rate}</td>
                                  <td>{formatCurrency(item.total)} {item.currency}</td>
                      </tr>})}
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(scCommercialTotalAmount)}
                        </td>
                      </tr>
                    </>)}
                    {plReport?.sales?.salesExpenseData.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td  className="link">Commercial Sales</td>
                      </tr>
                      {plReport?.sales?.salesExpenseData?.map(item => {
                          return <tr className="table-row">
                                  <td>{item.costingNo}</td>
                                  <td>{item.costingType}</td>
                                  <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                  <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                  {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                  <td>{item.quantity}</td>
                                  <td>{item.rate}</td>
                                  <td>{formatCurrency(item.total)} {item.currency}</td>
                      </tr>})}
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(scExpenseTotalAmount)}
                        </td>
                      </tr>
                    </>)}
                    {plReport?.sales?.scFreightData.length > 0 && (
                      <>
                      <tr className="table-row">
                        <td className="link">Freight Costing</td>
                      </tr>
                      {plReport?.sales?.scFreightData?.map(item => {
                          return <tr className="table-row">
                                  <td>{item.costingNo}</td>
                                  <td>{item.costingType}</td>
                                  <td>{moment(item.costingDate).isValid ? moment(item.costingDate).format('DD-MMM-YYYY') : ''}</td>
                                  <td style={{ fontWeight: 500}}>{item.bpName}</td>
                                  {/* <td>{item.quality} {item.qualtitySpec}</td> */}
                                  <td>{item.quantity}</td>
                                  <td>{item.rate}</td>
                                  <td>{formatCurrency(item.total)} {item.currency}</td>
                      </tr>})}
                      <tr className="table-row">
                        <td colSpan={6}></td>
                        <td style={{ fontWeight: "bold" }}>
                          {formatCurrency(scFreightTotalAmount)}
                        </td>
                      </tr>
                    </>)}
                  </tbody>
                  <tfoot>
                    <tr className="table-row">
                      <td colSpan={6} className="total-row"></td>
                      <td style={{ fontWeight: "bold",color: "green" }}>
                      {formatCurrency(scFinalAmount)}
                        </td>
                    </tr>
                  </tfoot>
                </table>
              </TabPane>
              <TabPane tab="Purchase & Sales Summary" key="3">
                <Table
                  className="contracts-list"
                  columns={columns}
                  dataSource={profitLoss}
                  pagination={false}
                >
                </Table>
              </TabPane>
          </Tabs>
        </Card>
        
       {/* </Card> */}
    </Card>
  )
}

export default ProfitLossReports;