import { BargeCostingsEnum, BargeCostingsService, BNoRequest, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerCategoryReqDto, BusinessPartnerTypeEnum, CompanyCodeReq, CompanyTdsService, ContractStatusEnum, ContractStatusReqDto, CostingWorkLogsEnum, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, GetDataDeadFreightDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, MvDeadFreightQtyDto, TaxDropDownDto, TaxesAndItemsService, TaxesService, TdsResModel } from "@exportx/shared-models-and-services";
import { getLocalFormat } from "@exportx/ui-utils";
import { Button, Card, Col, Drawer, Form, Input, Radio, RadioChangeEvent, Row, Select, Typography } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { AlertMessages, isPermissionExist, useAuthState } from "../../../common";
import DatePicker from "../../../common/data-picker/date-picker";
import DeadFreightList from "./dead-freight-entry-list";
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  deadFreightApproval?: boolean
  deadFreightStatus?: ContractStatusEnum;
  approvalLevel?: ContractStatusEnum;
  setDeadFreightForm?: (value) => void;
  accountType?: BusinessPartnerTypeEnum
}

export const DeadFreight = (props: IProps) => {
  const { authContext } = useAuthState()
  const navigate = useNavigate()
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [formRef] = Form.useForm()
  const businessNumberService = new BusinessNumberService();
  const currencyConverterService = new CurrencyConverterService();
  const bargeCostingService = new BargeCostingsService();
  const companyTdsService = new CompanyTdsService();
  const taxesService = new TaxesService();

  const [gridData, setGridData] = useState<GetDataDeadFreightDto[]>();
  const [initialValues, setInitialValues] = useState<any>({ baseCurrency: authContext.defaultPlantCurrency });
  const { Option } = Select;
  const { deadFreightApproval, deadFreightStatus, setDeadFreightForm, accountType, approvalLevel } = props;
  const [supplier, setSupplier] = useState<any[]>()
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const defaultCurrency = authContext.defaultPlantCurrency;
  const [dummyRefresh, setDummyRefresh] = useState<number>(1)
  const [account, setAccount] = useState<any>();
  const [currency, setCurrency] = useState<CurrencyDto[]>([]);
  const currencyService = new CurrencyService();
  const [exChangeDate, setExChangeDate] = useState<boolean>(false)
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
  const [accessId, setAccessId] = useState({})

  let location = useLocation();
  let paramsId = queryString.parse(location.search).costingId;

  useEffect(()=>{
    setAccessId({
      create: 714,
      view: 715,
      update: 716,
      delete: 717,
      approve: 718,
      reject: 719,
      release: 720,
      files: 721,
      deadFreightEdit: 708,
      deadFreightDelete: 709
    })
    // if(!isPermissionExist([707])) navigate('/')
  },[])

  useEffect(() => {
    getBusinessNoDropDown();
    getDeadFreightData();
    getTdsData();
    getCurrency();
    taxActiveDropDown();
  }, []);

  const taxActiveDropDown = () => {
    taxesService.getActiveTaxDropDown().then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      } else {
        setTaxDropDown([])
      }
    }).catch(err => {
      console.log(err.message);
      setTaxDropDown([]);
    })
  };


  useEffect(() => {
    if (paramsId && gridData) {
      setInitialValues(gridData?.find((rec) => rec?.bgDeadFreightId === paramsId));
    };
  }, [gridData]);

  useEffect(() => {
    if (initialValues) {
      formRef.setFieldsValue(initialValues);
    };

  }, [initialValues])


  const getCurrency = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message))
  }


  if (initialValues?.deadFreightDate) {
    initialValues.deadFreightDate = moment(initialValues.deadFreightDate);
  };

  if (initialValues?.exchangeDate) {
    initialValues.exchangeDate = moment(initialValues.exchangeDate);
  }

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    businessNumberService.dropDownForDeadFreight(req).then(res => {
      if (res.status) {
        setBusinessDropDown(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const pricePMT = (value) => {
    setInitialValues((prev) => {
      const deadFreightBasePrice = Number(value) * Number(prev?.deadFreight) * Number(prev.exchangeRate ? prev.exchangeRate : 1)
      const taxPercent1 = prev.taxPercent1 ?? 0;
      const taxPercent2 = prev.taxPercent2 ?? 0;
      const tdsPercentage = prev.tdsPercentage ?? 0;
      const tdsTotal = (tdsPercentage / 100) * deadFreightBasePrice;
      const taxTotal1 = (taxPercent1 / 100) * deadFreightBasePrice;
      const taxTotal2 = (taxPercent2 / 100) * deadFreightBasePrice;
      const withTax = (deadFreightBasePrice + taxTotal1 + taxTotal2) - tdsTotal
      
      return {
        ...prev,
         deadFreightBasePrice,
         taxTotal1,
         taxTotal2,
         tdsTotal,
         totalAmount: withTax,


      }
    })
  };

  const deadFreightOnchangeHandler = (value: number) => {
    const pricePmt = formRef.getFieldValue('pricePerMT');
    setInitialValues((prev) => {
      const cal = Number(pricePmt) * Number(value) * Number(prev.exchangeRate ? prev.exchangeRate : 1)
      const taxPercent1 = prev.taxPercent1 ?? 0;
      const taxPercent2 = prev.taxPercent2 ?? 0;
      const tdsPercentage = prev.tdsPercentage ?? 0;
      const tdsTotal = (tdsPercentage / 100) * cal;
      const taxTotal1 = (taxPercent1 / 100) * cal;
      const taxTotal2 = (taxPercent2 / 100) * cal;
      const withTax = (cal + taxTotal1 + taxTotal2) - tdsTotal
      return {
        ...prev,
        deadFreight: value,
        deadFreightBasePrice: cal,
        amount: cal,
        taxTotal1,
        taxTotal2,
        tdsTotal,
        amtAftDiscount: cal,
        totalAmount: withTax,
      }
    });

  }

  const businessNoOnChange = (value, text) => {
    const filteredValue = businessDropDown?.filter((rec) => {
      return rec.bnId === value;
    })
    formRef.setFieldValue('stowagePlanInMt', filteredValue[0]?.stowagePlanInMt);
    formRef.setFieldValue('vesselSailing', filteredValue[0]?.vesselSailing);
    supplierDropDown(value, account);
    formRef.setFieldValue('pricePerMT', undefined);
    formRef.setFieldValue('supplierId', undefined);
    if (!value) {
      formRef.setFieldValue('pricePerMT', undefined);
      formRef.setFieldValue('supplierId', undefined);
    }

  }

  const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
    setAccount(value);
    supplierDropDown(formRef.getFieldValue('businessNo'), value);

    const resetFields = businessDropDown.filter((rec) => {
      return rec.bnId === formRef.getFieldValue('businessNo')
    })
    if (resetFields !== formRef.getFieldValue('businessNo')) {
      setInitialValues(undefined);
      formRef.setFieldValue('pricePerMT', undefined);
      formRef.setFieldValue('businessNo', undefined);
      formRef.setFieldValue('supplierId', undefined);
      formRef.setFieldValue('stowagePlanInMt', undefined);
      formRef.setFieldValue('vesselSailing', undefined);
      formRef.setFieldValue('deadFreight', undefined);
      formRef.setFieldValue('exchangeDate', undefined);
      setInitialValues(undefined);

    }
  };

  const supplierDropDown = (businessNo: string, type: BusinessPartnerTypeEnum) => {
    const req = new BusinessPartnerCategoryReqDto(businessNo, type, authContext.defaultPlant);
    businessNumberService.mvBpNames(req).then(res => {
      if (res.status) {
        setSupplier(res.data);
      } else {
        setSupplier([]);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }





  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = formRef.getFieldValue('deadFreightDate');
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  }


  const tdsOnchange = (tdsId) => {
    const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
      setInitialValues(prevState => {
        const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
        const tdsTotal = (tdsPercentage / 100) * prevState.amtAftDiscount;
        const taxTotal1 = prevState.taxTotal1 ?? 0;
        const taxTotal2 = prevState.taxTotal2 ?? 0;
        const withTax = (prevState.amtAftDiscount + taxTotal1 + taxTotal2) - tdsTotal
        return {
          ...prevState,
          tdsId,
          tdsPercentage,
          tdsTotal,
          totalAmount: withTax
        }
      })
  }

  const submit = () => {
    formRef.validateFields().then(values => {
      const req = { ...values, bnId: values.businessNo, pricePerMT: Number(formRef.getFieldValue('pricePerMT')), userName: authContext.user.userName, deadFreightDate: formRef.getFieldValue('deadFreightDate'), plantCode: authContext.defaultPlant, bgDeadFreightId: initialValues?.bgDeadFreightId, versionFlag: initialValues?.versionFlag, supplierId: formRef.getFieldValue('supplierId'), costingType: CostingWorkLogsEnum.DEAD_FREIGHT, stowagePlanInMt: formRef.getFieldValue('stowagePlanInMt'), vesselSailing: formRef.getFieldValue('vesselSailing'), account: formRef.getFieldValue('account'), baseCurrency: authContext.defaultPlantCurrency, exchangeDate: formRef.getFieldValue('exchangeDate'), quoteCurrency: formRef.getFieldValue('baseCurrency'), exchangeRate: initialValues.exchangeRate }
      console.log(req);
      bargeCostingService.createBgDeadFreight(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(prev => prev + 1);
          getDeadFreightData();
          formRef.resetFields();
          window.location.reload();
          setInitialValues(undefined)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err.message)
      })
    })

  }

  const getDeadFreightData = () => {
    const req = new ContractStatusReqDto(deadFreightStatus, authContext.defaultPlant)
    bargeCostingService.getDeadFreightData(req).then(res => {
      if (res.status) {
        setGridData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getDeadFreight = (value) => {

    const req = new BNoRequest(formRef.getFieldValue('businessNo'), authContext.defaultPlant, null, null, value);
    businessNumberService.getMvDeadFreightQtyByBN(req).then(res => {
      if (res.status) {
        if (res.data) {
          const amt = Number(res.data.quantity) * Number(res.data.price);
          setInitialValues(prev => {
            return {
              ...prev,
              supplierId: res.data.businessPartner,
              deadFreight: res.data.quantity,
              pricePerMT: res.data.price,
              amount: amt,
              baseCurrency: res.data.currency || authContext.defaultPlantCurrency,
              amtAftDiscount: amt,
              totalAmount: amt,
            }
          })
        } else {
          formRef.setFieldValue('supplierId', null);
          formRef.setFieldValue('deadFreight', null);
          formRef.setFieldValue('pricePerMT', null);
          formRef.setFieldValue('amount', null);
          formRef.setFieldValue('amtAftDiscount', null);
          formRef.setFieldValue('totalAmount', null);
          AlertMessages.getErrorMessage('No Data Found for given Business Number');
        }
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }


  const getCurrencyExchangeRate = (fromCurrency, toCurrency, date) => {
    const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'))
    if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
      return;
    }
    currencyConverterService.getCurrencyExchangeRate(req).then(res => {
      if (res.status) {
        setInitialValues(prev => {
          const deadFreightBasePrice = Number(formRef.getFieldValue('pricePerMT')) * Number(prev?.deadFreight) * res.data.exchangeRate
          const taxPercent1 = prev.taxPercent1 ?? 0;
          const taxPercent2 = prev.taxPercent2 ?? 0;
          const tdsPercentage = prev.tdsPercentage ?? 0;
          const tdsTotal = (tdsPercentage / 100) * deadFreightBasePrice;
          const taxTotal1 = (taxPercent1 / 100) * deadFreightBasePrice;
          const taxTotal2 = (taxPercent2 / 100) * deadFreightBasePrice;
          const withTax = (deadFreightBasePrice + taxTotal1 + taxTotal2) - tdsTotal
          return {
            ...prev,
            deadFreightBasePrice,
            exchangeRate: res.data.exchangeRate,
            displayExchangeRate: res.data.displayExchangeRate,
            amount: deadFreightBasePrice,
            amtAftDiscount: deadFreightBasePrice,
            taxTotal1,
            taxTotal2,
            tdsTotal,
            totalAmount: withTax,
          }
        })
      } else {

        setInitialValues(prev => {
          const deadFreightBasePrice = Number(formRef.getFieldValue('pricePerMT')) * Number(prev?.deadFreight);
          delete prev?.displayExchangeRate;
          const taxPercent1 = prev.taxPercent1 ?? 0;
          const taxPercent2 = prev.taxPercent2 ?? 0;
          const tdsPercentage = prev.tdsPercentage ?? 0;
          const tdsTotal = (tdsPercentage / 100) * deadFreightBasePrice;
          const taxTotal1 = (taxPercent1 / 100) * deadFreightBasePrice;
          const taxTotal2 = (taxPercent2 / 100) * deadFreightBasePrice;
          const withTax = (deadFreightBasePrice + taxTotal1 + taxTotal2) - tdsTotal
          return {
            ...prev,
            deadFreightBasePrice,
            amount: deadFreightBasePrice,
            amtAftDiscount: deadFreightBasePrice,
            taxTotal1,
            taxTotal2,
            tdsTotal,
            totalAmount: withTax,
          }
        })

      }
    }).catch(err => { console.log(err.message) });
  }


  const currencyOnChangeHandler = (value) => {
    if (value) {
      setExChangeDate(value !== authContext.defaultPlantCurrency);
      getCurrencyExchangeRate(value, authContext.defaultPlantCurrency, formRef.getFieldValue('exchangeDate'))
    } else {
      setExChangeDate(false);
    }
  };

  const exChangeDateHandler = (value) => {
    getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, value)
  }


  const suffixSelector = (
    <Form.Item
      name="baseCurrency"
      noStyle
    >
      <Select
        onChange={currencyOnChangeHandler}
        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
        allowClear
        showSearch
        style={{ width: 80 }}

      >
        {currency?.map((rec) => {
          return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
        })}
      </Select>
    </Form.Item>
  );

  function onTaxChange1(taxId) {
    const vat = taxDropDown.filter(res => res.taxId === taxId);
    const taxId1 = vat[0]?.taxId ? vat[0]?.taxId : null;
    const taxPercent1 = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
    setInitialValues(prevState => {

      const taxTotal1 = (taxPercent1 / 100) * prevState.amtAftDiscount;
      const taxTotal2 = prevState.taxTotal2 ?? 0;
      const tdsTotal = prevState.tdsTotal ?? 0;
      const withTax = (prevState.amtAftDiscount + taxTotal1 + taxTotal2) - tdsTotal
      return {
        ...prevState,
        taxId1,
        taxPercent1,
        taxTotal1,
        totalAmount: withTax
      }
    })
  }

  function onTaxChange2(taxId) {
    const vat = taxDropDown.filter(res => res.taxId === taxId);
    const taxId2 = vat[0]?.taxId ? vat[0]?.taxId : null;
    const taxPercent2 = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
    setInitialValues(prevState => {
      const taxTotal2 = (taxPercent2 / 100) * prevState.amtAftDiscount;
      const taxTotal1 = prevState.taxTotal1 ?? 0;
      const tdsTotal = prevState.tdsTotal ?? 0;
      const withTax = (prevState.amtAftDiscount + taxTotal1 + taxTotal2) - tdsTotal
      return {
        ...prevState,
        taxId2,
        taxPercent2,
        taxTotal2,
        totalAmount: withTax
      }
    })
  }


  const discountOnchangeHandler = (dis: number) => {
    setInitialValues((prev) => {
      const afterDiscount = (prev?.amount - dis) || 0;
      const taxPercent1 = prev.taxPercent1 ?? 0;
      const taxPercent2 = prev.taxPercent2 ?? 0;
      const tdsPercentage = prev.tdsPercentage ?? 0;
      const tdsTotal = (tdsPercentage / 100) * afterDiscount;
      const taxTotal1 = (taxPercent1 / 100) * afterDiscount;
      const taxTotal2 = (taxPercent2 / 100) * afterDiscount;
      const withTax = (afterDiscount + taxTotal1 + taxTotal2) - tdsTotal
      return {
        ...prev,
        discount: dis,
        amtAftDiscount: afterDiscount,
        tdsTotal,
        taxTotal1,
        taxTotal2,
        totalAmount: withTax
      }

    });

  }

  return (
    <Card title={<span style={{ color: 'white' }}>Dead Freight Entry for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
      extra={<>{isPermissionExist([707]) && (<Button
        onClick={() => { setGridDrawer(true); setDummyRefresh(dummyRefresh + 1); }}
        className='panel_button'
      >View</Button>)}</>

      }>
      <Col span={24}>
      {isPermissionExist([706]) && (
        <Form
          key={dummyRefresh}
          form={formRef}
          initialValues={initialValues}
          autoComplete={"off"}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          style={{ maxWidth: 600 }}
        >
          <Card
            title={

              <Row className="mt-2">
                <Col span={8}>
                  <Form.Item
                    name={'deadFreightDate'}
                    label="Date"
                    initialValue={moment()}
                  >
                    <DatePicker defaultValue={moment()} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name='account' initialValue={initialValues?.account} rules={[{ required: deadFreightStatus ? false : true, message: "Account Type is Required" }]}>
                    <Radio.Group onChange={radioOnChange} style={{ width: '200%' }}>
                      <Radio value={`${BusinessPartnerTypeEnum.VENDOR}`}>Against Seller</Radio>
                      <Radio value={`${BusinessPartnerTypeEnum.CUSTOMER}`}>Against Buyer</Radio>
                      <Radio value={`${BusinessPartnerTypeEnum.VESSEL_OWNER}`}>Against Vessel Owner</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

              </Row>
            }
          >
            <Form.Item
              label={`${deadFreightStatus ? "Business Number:" : "Select MV:"}`}
              name="businessNo"
              rules={[{ required: deadFreightStatus ? false : true, message: "Business Number is Required" }]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                // style={{ width: '100%', paddingLeft: 30 }}
                placeholder='Select Business No'
                onChange={(value, text) => businessNoOnChange(value, text)}
                disabled={formRef.getFieldValue('account') ? false : true}
                showSearch
              >
                {/* <Option value={'ALL'}>All</Option> */}
                {businessDropDown.map(item => {
                  return <Option value={item.bnId}>{item.motherVesselName + " " + " " + `(${item.businessNo})`}</Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={`${deadFreightStatus ? "Supplier:" : `Select ${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''}:`}`}
              name="supplierId"

              rules={[{ required: deadFreightStatus ? false : true, message: `${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''} is Required` }]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                style={{ width: '100%', }}
                placeholder='Select Supplier No'
                onChange={getDeadFreight}
                disabled={formRef.getFieldValue('businessNo') ? false : true}
              >
                {/* <Option value={'ALL'}>All</Option> */}
                {supplier?.map(item => {
                  return <Option value={item.supplierId}>{item.supplierName}</Option>
                })}
              </Select>

            </Form.Item>


            <Form.Item
              name={"stowagePlanInMt"}
              label={' MV Stowage plan'}
            >
              <Input
                placeholder="MV Capacity"
                disabled={true}
                type={'number'}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'deadFreight'}
              label={<b>Dead Freight</b>}
            >
              <Input
                addonAfter={"MT"}
                type="number"
                onChange={(e) => {
                  deadFreightOnchangeHandler(Number(e.target.value))
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              label={<b>Price per MT</b>}
              name="pricePerMT"
            >
              <Input addonAfter={suffixSelector} type="number" onChange={(e) => pricePMT(e.target.value)}></Input>
            </Form.Item>

            {exChangeDate ?
              <>
                <Form.Item name={'exchangeDate'} label={'Exchange Date'}>
                  <DatePicker onChange={exChangeDateHandler} />
                </Form.Item>
                <Form.Item name={'exchangeRate'} label={'Exchange Rate'}>
                  <span>
                    {initialValues?.exchangeRate}
                  </span>
                </Form.Item>

              </>
              :
              <></>}
            <Form.Item
              name={'amount'}
              label={<b>Amount</b>}
            >
              <Input
                addonAfter={defaultCurrency}
                type="number"
                onChange={(e) => {
                  deadFreightOnchangeHandler(Number(e.target.value))
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'discount'}
              label={<b>Discount</b>}
            >
              <Input
                addonAfter={defaultCurrency}
                type="number"
                placeholder="Enter Discount"
                onChange={(e) => {
                  discountOnchangeHandler(Number(e.target.value))
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'amtAftDiscount'}
              label={<b>Amount After Discount</b>}
            >
              <Input
                addonAfter={defaultCurrency}
                type="number"
                disabled
                onChange={(e) => {
                  deadFreightOnchangeHandler(Number(e.target.value))
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'taxId1'}
              label={<b>TAX 1</b>}
            >
              <Select
                onChange={(v) => onTaxChange1(v)}
                placeholder='Select tax'
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
              >
                <Option value={undefined}>Not Applicable</Option>
                {taxDropDown?.map((rec) => {
                  return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item label={' '} name='taxTotal1' >
              <b>{getLocalFormat(initialValues?.taxTotal1, defaultCurrency)}</b>
            </Form.Item>
            <Form.Item
              name={'taxId2'}
              label={<b>TAX 2</b>}
            >
              <Select
                onChange={(v) => onTaxChange2(v)}
                placeholder='Select tax'
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
              >
                <Option value={undefined}>Not Applicable</Option>
                {taxDropDown?.map((rec) => {
                  return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item label={' '} name='taxTotal2'>
              <b>{getLocalFormat(initialValues?.taxTotal2, defaultCurrency)}</b>
            </Form.Item>
            <Form.Item
              name={'tdsId'}
              label={<b>TDS</b>}
            >
              <Select
                onChange={(v) => tdsOnchange(v)}
                placeholder='Select TDS'
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
              >
                <Option value={undefined}>Not Applicable</Option>
                {tdsModel?.map((rec) => {
                  return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item label={' '} name='tdsTotal'>
              <b>{getLocalFormat(initialValues?.tdsTotal, defaultCurrency)}</b>
            </Form.Item>
            <Form.Item
              name={'totalAmount'}
              label={<b>Final Amount</b>}
            >
              <Input
                addonAfter={defaultCurrency}
                type="number"
                disabled
              />
            </Form.Item>

            <Button type='primary' style={{ float: 'right' }}
              onClick={submit}
            >
              Submit
            </Button>
          </Card>
        </Form>)}
      </Col>
      <Drawer
        open={gridDrawer}
        onClose={() => setGridDrawer(false)}
        key={dummyRefresh}
        width={'50%'}

      >
        <DeadFreightList
          key={dummyRefresh}
          setDummyRefresh={setDummyRefresh}
          gridData={gridData}
          getDeadFreightData={getDeadFreightData}
          setInitialValues={setInitialValues}
          deadFreightApproval={deadFreightApproval}
          accessId={accessId}
        />
      </Drawer>
    </Card >
  )

}
export default DeadFreight