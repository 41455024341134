import { InfoCircleOutlined, DownCircleOutlined, PlusCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import { AccountsService, AdvanceReceiversService, BargeCostingIdReqDto, BargeCostingsService, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingInvoiceDto, CostingInvoiceReqDto, CostingInvoiceService, CostingWorkLogsEnum, ExpenseEntryService, LedgerUpdateDto, PaymentNoticeChildDto, PaymentNoticeChildReq, PaymentNoticeGetDataDto, PaymentNoticeService, PerformaCoalCostingService, PmtTransactionalDto, ReferenceFeatures, StatusUpdateReqDto, TransactionalIdReqDto, VendorAdvService } from '@exportx/shared-models-and-services';
import { ClassValidator, internationalFormattedValue } from '@exportx/ui-utils';
import { Button, Card, Col, Descriptions, Divider, Flex, Form, Input, InputNumber, Popover, Row, Select, Space, Typography } from 'antd';
import Table from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, StatusTag, useAuthState } from '../../../common';
import { PaymentNoticePageEnum } from './receivables';
import CreditReceivables from './credit-receivables';
import { ApprovalTracking } from '../costing-detail-view';
import ViewFileHandling from '../../../common/file-upload-form/view-files-handling';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IPaymentNoticeChildProps {
    record: PaymentNoticeGetDataDto;
    type: PaymentNoticePageEnum;
    getParentData: () => void;
    paymentType?: PaymentNoticePageEnum
}

const { Option } = Select;
const { Text } = Typography;

export const ReceivablesChild = (props: IPaymentNoticeChildProps) => {
    const paymentNoticeService = new PaymentNoticeService();
    const [formRef] = Form.useForm();
    const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];
    const { authContext } = useAuthState();
    const classValidator = new ClassValidator();
    const { type, record, getParentData, paymentType } = props;
    const [ledgerAccounts, setLedgerAccounts] = useState([]);
    const [childData, setChildData] = useState<PaymentNoticeChildDto>(null);
    const [accountLedger, setAccountLedger] = useState(null);
    const [hiddenState, setHiddenState] = useState<boolean>(true);
    const commercialService = new CommercialCoalCostingService();
    const performaService = new PerformaCoalCostingService();
    const service = new BargeCostingsService();
    const expensiveService = new ExpenseEntryService();
    const [kycData, setKycData] = useState<any[]>([]);
    const [transactionalData, setTransactionalData] = useState<PmtTransactionalDto[]>([]);
    const invoiceService = new CostingInvoiceService();
    const [invoiceData, setInvoiceData] = useState<CostingInvoiceDto[]>([]);
    const [filteredInVoice, setFilteredInvoice] = useState<any>()
    const advanceService = new VendorAdvService();
    const advanceReceivablesService = new AdvanceReceiversService();
    const [creditDrawer, setCreditDrawer] = useState<boolean>(false)
    const [totalTransactionValue, setTotalTransactionValue] = useState<{
        transactionAmount: number,
        remainingAmount: number,
        pmtNoticeId: string,
        pmtNoticeTransactionId: string,
    }>()
    const [dummyRefresh, setDummyRefresh] = useState<number>(0);
    const [expandedIndex, setExpandedIndex] = useState([]);
    const [open, setOpen] = useState<number>(undefined);


    useEffect(() => {
        if (type === PaymentNoticePageEnum.CREATE) {
            const accountsService = new AccountsService();
            const getAllAccountsData = () => {
                accountsService.getAllPmtLedgerAccounts().then((res => {
                    if (res.status) {
                        setLedgerAccounts(res.data)
                    } else {
                        setLedgerAccounts([])
                    }
                })).catch((err => {
                    setLedgerAccounts([])
                }))
            }
            getAllAccountsData();
        } else {
            const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant)
            paymentNoticeService.getPmtNoticeLedgerData(req).then(res => {
                if (res.status) {
                    setAccountLedger(res.data);
                } else {
                    setAccountLedger(null);
                }
            }).catch(err => {
                console.log(err)
                setAccountLedger(null);
            })
        }
        if (record.expenseType === CostingWorkLogsEnum.DEAD_FREIGHT) {
            const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant);
            getDeadFreightDataByCostingId(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getExpensivePmtData(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.DEMURRAGE || record.expenseType === CostingWorkLogsEnum.DISPATCH) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getDemurragePmtData(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.BARGE_COSTING) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getPaymentNoticeChildDataForBargeCosting(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || record.expenseType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
            const cType: ContractModesEnum = record.expenseType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? ContractModesEnum.PURCHASE : ContractModesEnum.SALES
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, cType);
            getPmtPerformData(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES || record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
            let contractType: ContractModesEnum;
            if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
                contractType = ContractModesEnum.PURCHASE;
            } else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
                contractType = ContractModesEnum.SALES;
            } else if (record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES) {
                contractType = ContractModesEnum.PROCUREMENT_FEES;
            } else if (record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
                contractType = ContractModesEnum.MARKETING_FEES;
            }
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, contractType);
            commercialsData(req);
        } else if (record.expenseType === ReferenceFeatures.ADVANCE_INVOICE) {
            const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            advanceService.getAdvanceCostingData(req).then(res => {
                if (res.status) {
                    // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
                    // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
                    setChildData(res.data)
                } else {
                    setChildData(undefined)
                }
            })
        } else if (record.expenseType === ReferenceFeatures.ADVANCE_RECEIVABLES) {
            const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            advanceReceivablesService.getReceivablesCostingData(req).then(res => {
                if (res.status) {
                    // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
                    // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
                    setChildData(res.data)
                } else {
                    setChildData(undefined)
                }
            })
        }
        getAllCostingInvoice();

    }, []);


    useEffect(() => {
        setTransactionalData(record.transactionalData);
    }, [props.record.transactionalData]);

    const getDeadFreightDataByCostingId = (req) => {
        paymentNoticeService.getDeadFreightDataByCostingId(req).then(res => {
            if (res.status) {

                setChildData(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        });
    };

    const getAllCostingInvoice = () => {
        const req = new CostingInvoiceReqDto(record.costingId, authContext.defaultPlant, undefined, record.expenseType);
        invoiceService.getAllCostingInvoice(req).then(res => {
            if (res.status) {
                setInvoiceData(res.data)
            } else {
                setInvoiceData([]);
                console.log(res.internalMessage);

            };
        }).catch(err => console.log(err.message));
    };


    const getPaymentNoticeChildDataForBargeCosting = (req) => {
        service.getPaymentNoticeChildDataForBargeCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            };
        }).catch(err => {
            console.log(err);
        });
    };

    const getPmtPerformData = (req) => {
        performaService.getPaymentNoticeChildDataPerFormaCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch((err) => console.log(err.message));
    };

    const commercialsData = (req) => {
        commercialService.getPaymentNoticeChildDataCommercialCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        });
    };

    const getExpensivePmtData = (req) => {
        expensiveService.getPaymentNoticeForExpensiveCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => console.log(err.message));
    };

    const getDemurragePmtData = (req) => {
        service.getPaymentNoticeForDemurrageCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const bankAccountsHandler = (value, children) => {
        if (children === "Bank Accounts") {
            setHiddenState(false);
            paymentNoticeService.getKycDropDownData({ actLedger: childData?.bpId }).then(res => {
                if (res.status) {
                    setKycData(res.data);
                } else {
                    setKycData([]);
                    setHiddenState(true);
                }
            }).catch(err => console.log(err.message, "err message"))
        } else {
            setHiddenState(true);
        }
    };

    const invoiceOnChangeHandler = (value: string) => {
        const againstInvoiceData = invoiceData.filter((rec) => rec.invoiceId === value)[0];
        setFilteredInvoice(againstInvoiceData);
        const remainingInvoice = Number(againstInvoiceData?.invoiceAmount) - record?.transactionalData?.filter((rec) => rec.invoiceNo === value).reduce((a, c) => a + Number(c.transactionAmount), 0);
        setFilteredInvoice((prev) => { return { ...prev, remainingInvoice: remainingInvoice } });
    };


    const ledgerUpdate = () => {
        formRef.validateFields().then(values => {
            if (Math.abs(Number(childData?.totalAmount)) < Math.abs(Number(record?.transactionalTotalAmount)) + Number(values.transactionAmount)) {
                return AlertMessages.getErrorMessage('Do Not Exceed Enter Receivable Amount More Than Total Amount');
            };
            if (Number(filteredInVoice?.remainingInvoice) < Number(values?.transactionAmount)) {
                return AlertMessages.getErrorMessage('Do Not Exceed Enter Receivable Amount More Than Total Amount');
            };
            const req = new LedgerUpdateDto(record.paymentNoticeId, authContext.user.userName, authContext.defaultPlant, values.actLedger, values.remarks, values.transactionAmount, values.kycId, values.invoiceNo, childData?.totalAmount);
            paymentNoticeService.updatePaymentNotice(req).then(res => {
                if (res.status) {
                    getParentData();
                    setOpen(undefined)
                    // window.location.reload();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err);
            });
        });
    };


    const transactionAmountOnchangeHandler = (value: number) => {
        if (Math.abs(Number(childData?.totalAmount)) < Math.abs(Number(record?.transactionalTotalAmount)) + Number(value)) {
            formRef.setFieldValue('transactionAmount', 0)
            return AlertMessages.getErrorMessage('Do Not Exceed Enter Receivable Amount More Than Total Amount')
        };
        if (Number(filteredInVoice?.remainingInvoice) < Number(value)) {
            formRef.setFieldValue('transactionAmount', 0)
            return AlertMessages.getErrorMessage('Do Not Exceed Enter Receivable Amount More Than Total Amount')
        };
    }

    const statusApprovalAndReject = (status: ContractStatusEnum) => {
        let number = type === PaymentNoticePageEnum.APPROVAL ? 1 : 2;
        formRef.validateFields().then(values => {
            const req: any = new StatusUpdateReqDto(record.paymentNoticeId, status, authContext.user.userName, authContext.defaultPlant, number, number, values.remarks, Number(values.amountPayable));
            paymentNoticeService.statusApprovalAndReject(req).then(res => {
                if (res.status) {
                    getParentData();
                    setOpen(undefined)
                }
                else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err);
            });
        });
    };

    const submitHandler = (status: ContractStatusEnum) => {
        if (type === PaymentNoticePageEnum.CREATE) {
            ledgerUpdate();
        } else {
            statusApprovalAndReject(status);
        };
    };

    const bg = [];
    const barges = childData?.businessNoAndBarge?.barges?.forEach((rec) => {
        if (rec.bargeNo)
            bg.push((rec?.bargeNo) + ` (${rec.bgNomination})`)
    });

    const approveTransactionRecord = (transId: string, pmtNoticeId: string, status: ContractStatusEnum) => {
        const req = new TransactionalIdReqDto(pmtNoticeId, transId, authContext.defaultPlant, status);
        paymentNoticeService.pmtTransactionalApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                // window.location.reload();
                getParentData()
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            };
        }).catch(err => console.log(err.message, 'err message'));
    };

    const approvalTransactionalTotalAmount = transactionalData?.reduce((a, c) => a + Number(c.transactionAmount), 0);
    const remainingAmount = Number(childData?.totalAmount) - Number(record?.transactionalTotalAmount);

    const columns = [
        {
            title: 'Act Grp',
            dataIndex: 'actGrp',
        },
        {
            title: 'Act Ledger',
            dataIndex: 'actLedger',
        },
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
        },
        {
            title: 'Received Date',
            dataIndex: 'paidDate',
            render: (value) => {
                return <>
                    {moment(value).format('YYYY-MM-DD')}
                </>
            }
        },
        {
            title: 'Transaction Amount',
            dataIndex: 'transactionAmount',
        },
        {
            title: 'Remaining Amount',
            dataIndex: 'remainingAmount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => {
                return <StatusTag status={text} />
            }
        },
        ...(type !== PaymentNoticePageEnum.CREATE && paymentType === PaymentNoticePageEnum.RECEIVABLES ?
            [
                {
                    title: 'Action',
                    render: (value, record: PmtTransactionalDto) => {
                        return <>
                            {
                                type === PaymentNoticePageEnum.APPROVAL ? <Button type='primary' disabled={record.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.FREEZED)}>Approve</Button> :
                                    type === PaymentNoticePageEnum.RELEASE ? <Button type='primary' disabled={record.status === ContractStatusEnum.FREEZED ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.CLOSED)}>release</Button> : ''
                            }
                        </>
                    }
                }
            ]
            :
            []
        ),

        ...(type !== PaymentNoticePageEnum.CREATE && paymentType !== PaymentNoticePageEnum.RECEIVABLES ?
            [
                {
                    title: 'Action',
                    render: (value, record: PmtTransactionalDto) => {
                        return <>
                            {
                                type === PaymentNoticePageEnum.APPROVAL ? <Button type='primary' disabled={record.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.FREEZED)}>Approve</Button> :
                                    type === PaymentNoticePageEnum.RELEASE ?
                                        <>
                                            <Button
                                                type='primary'
                                                disabled={record.status === ContractStatusEnum.FREEZED ? false : true}
                                                onClick={() =>
                                                    approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.CLOSED)}
                                            >Credit
                                            </Button>
                                        </>

                                        : ''
                            }
                        </>
                    }
                }
            ]
            :
            []
        ),
        ...(paymentType === PaymentNoticePageEnum.RECEIVABLES && type === PaymentNoticePageEnum.RELEASE ?
            [
                {
                    title: 'Action',
                    render: (value, record: PmtTransactionalDto) => {
                        let disable: boolean;
                        if (record.status === ContractStatusEnum.CLOSED && Number(record.remainingAmount) === 0) {
                            disable = true
                        } else if (Number(record.remainingAmount) === 0) {
                            disable = false
                        } else {
                            disable = true
                        }

                        return <>

                            <Button
                                type='primary'
                                onClick={() => openCreditDrawer(record)}
                                // disabled={Number(record.remainingAmount) <= 0}
                                disabled={record.status === ContractStatusEnum.CLOSED}

                            >
                                Credit Transactions
                            </Button>

                            <Button
                                type='primary'
                                disabled={disable}
                                onClick={() =>
                                    approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.CLOSED)}>
                                Validation</Button>
                        </>
                    }
                }
            ]
            :
            []
        )



    ]

    const invoiceColumns: any = [

        {
            title: 'Invoice No & Date',
            dataIndex: 'invoiceNo',
            render: (text, record) => {
                return (
                    <>
                        <Text className='link'>{text}</Text>
                        <Typography className='small-text'>{record.invoiceDate !== undefined ? moment(record.invoiceDate).format('DD-MMM-YYYY') : "N/A"}</Typography>
                    </>

                )
            },
        },
        {
            title: 'Invoice Amount	',
            dataIndex: 'invoiceAmount',
            render: (text) => {
                return (
                    <>
                        <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
                        <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

                    </>
                )
            }
        },
        ...record?.includeFreight ? [
            {
                title: 'Coal %',
                dataIndex: 'coal_percent',
                key: 'coal_percent',
                render: (text) => <Text className='small-text'>{text}</Text>
            },

            {
                title: 'Coal Amount',
                dataIndex: 'coal_amount',
                key: 'coal_amount',
                render: (text) => {
                    return (
                        <>
                            <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
                            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

                        </>
                    )
                }
            },
            {
                title: 'Freight %',
                dataIndex: 'freight_percent',
                key: 'freight_percent',
                render: (text) => <Text className='small-text'>{text}</Text>
            },
            {
                title: 'Freight Amount',
                dataIndex: 'freight_amount',
                key: 'freight_amount',
                render: (text) => {
                    return (
                        <>
                            <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
                            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

                        </>
                    )
                }
            },
        ] : [
            {
                title: 'Invoice %',
                dataIndex: 'invoicePercent',
                key: 'invoicePercent',
                render: (text) => <Text className='small-text'>{text}</Text>
            }
        ],
        {
            title: 'Balance Amount',
            dataIndex: 'invoiceAmount',
            render: (text, row) => {
                const remainingInvoice = Number(text) - record?.transactionalData?.filter((rec) => rec.invoiceNo === row.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
                return (
                    <>
                        <Text className='small-text'>{internationalFormattedValue(remainingInvoice, 2)}</Text>
                        <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

                    </>
                )
            }
        },
        {
            title: 'Invoice File',
            dataIndex: 'filesData',
            render: (value, record) => {
                return <>
                    <ViewFileHandling filesData={value.length ? value : []} required={true} />

                </>
            }

        },
        {
            title: 'Status	',
            dataIndex: 'status',
            render: (text, row) => {
                return <StatusTag status={text} />
            }
        },
        {
            title: 'Actions',
            width: 100,
            fixed: 'right',
            render: (value, row: CostingInvoiceDto, index) => {
                formRef.setFieldValue('invoiceNo', row.invoiceId)

                const invoiceTransactionData = record.transactionalData.filter((rec) => rec.invoiceNo === row.invoiceId);

                const outstandingInvoice = Number(row?.invoiceAmount ?? 0) - record?.transactionalData?.filter((rec) => rec.invoiceNo === row.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
                const approvalDetails = row.workLog?.filter((e) => e.approvalLevel && e.approvalLevel !== null);
                return <>

                    <Popover
                        content={<>
                            <Form layout='vertical' form={formRef} autoComplete='off' style={{ width: '300px' }}>
                                <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}
                                    label={paymentType === PaymentNoticePageEnum.RECEIVABLES ? "Receivable by" : 'Payable by'}

                                    name="actLedger" style={{ width: '100%' }}>
                                    <Select

                                        onChange={(value, opt: any) => bankAccountsHandler(value, opt?.grpName)}
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank Account'>
                                        {ledgerAccounts.map((a) => {
                                            return <Option grpName={a.actLedActGrpId.actGrpName} value={a.actLedId}>{a.actLedName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Receiver Bank'
                                    rules={[{ required: false }]}
                                    name="kycId" style={{ width: '100%' }}

                                >
                                    <Select
                                        disabled={hiddenState}
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank'>
                                        {kycData.map((a) => {
                                            return <Option value={a.kycId}>{a.bankName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label='Enter Receivable Amount'
                                    name='transactionAmount'
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Enter Receivable Amount' }
                                    ]}
                                >
                                    <InputNumber
                                        disabled={isNaN(remainingAmount) || Math.abs(remainingAmount) < 0 ? true : false}
                                        style={{ width: '100%' }}
                                        max={outstandingInvoice}
                                        onChange={transactionAmountOnchangeHandler}
                                    />
                                </Form.Item>


                                <Form.Item
                                    label='remarks'
                                    name='remarks'
                                    style={{ width: '100%' }}
                                    rules={[
                                        {
                                            required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                                        },
                                        ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                                    ]}
                                >
                                    <Input.TextArea style={{ width: '100%' }} placeholder='Notes' onChange={() => { }} />
                                </Form.Item>

                                <Divider />

                                <Flex align='center' justify='flex-end'>
                                    <Space size={10}>

                                        <Button type="text" className='link' size='small' onClick={() => setOpen(undefined)}>Cancel</Button>
                                        <Button type="primary" className='new-button' size='small' onClick={() => submitHandler(ContractStatusEnum.ACTIVE)}>Submit For Approval</Button>
                                    </Space>
                                </Flex>
                            </Form>
                        </>}
                        title="Add Receivables"
                        trigger="click"
                        open={index === open}
                        placement="bottom"
                    //   onOpenChange={handleOpenChange}
                    >
                        {type === PaymentNoticePageEnum.CREATE &&
                            <Button icon={<PlusCircleFilled />} className="action-icons mr-3" onClick={() => setOpen(index)} />
                        }
                    </Popover>

                    <Popover
                        content={<>
                            {approvalDetails?.map((log) => (
                                <Typography style={{ fontSize: 10 }}><b>{log.approvalLevel}</b> Approved By <b>{log?.createdUser}</b> Approved On <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                            ))}
                        </>}
                        title="Approval Details"
                        placement="bottom"
                    >
                        <Button icon={<InfoCircleOutlined />} className="action-icons" onClick={() => { }} />

                    </Popover>

                </>
            }
        }


    ];


    const openCreditDrawer = (record: PmtTransactionalDto) => {
        setCreditDrawer(true);//Number(record.transactionAmount)
        setTotalTransactionValue((prev) => {
            return {
                ...prev,
                transactionAmount: Number(record.transactionAmount),
                remainingAmount: Number(record.remainingAmount),
                pmtNoticeId: record.pmtNoticeId,
                pmtNoticeTransactionId: record.transId,

            }
        });
        setDummyRefresh(dummyRefresh + 1);

    }

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.invoiceId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    };
    const renderItems = (invoiceInfo: CostingInvoiceDto, index, indent, expanded) => {

        formRef.setFieldValue('invoiceNo', invoiceInfo.invoiceId)

        const outstandingInvoice = Number(invoiceInfo?.invoiceAmount ?? 0) - record?.transactionalData?.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
        const invoiceTransactionData = record.transactionalData.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId);
        console.log('invoiceTransactionData ', invoiceTransactionData)

        // return <>
        //     <div>
        //         <Form layout='vertical' form={formRef} autoComplete='off'>
        //             <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
        //                 <Input hidden />
        //             </Form.Item>
        //             {type === PaymentNoticePageEnum.CREATE && outstandingInvoice > 0 &&
        //                 <>
        //                     <Row gutter={16}>
        //                         <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
        //                             {type === PaymentNoticePageEnum.CREATE ?
        //                                 <Form.Item rules={[{ required: true }]}
        //                                     label={paymentType === PaymentNoticePageEnum.RECEIVABLES ? "Receivable by" : 'Payable by'}

        //                                     name="actLedger" style={{ width: '100%' }}>
        //                                     <Select

        //                                         onChange={(value, opt: any) => bankAccountsHandler(value, opt?.grpName)}
        //                                         filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank Account'>
        //                                         {ledgerAccounts.map((a) => {
        //                                             return <Option grpName={a.actLedActGrpId.actGrpName} value={a.actLedId}>{a.actLedName}</Option>
        //                                         })}
        //                                     </Select>
        //                                 </Form.Item> : <>{accountLedger?.actLedName}</>
        //                             }

        //                         </Col>
        //                         <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
        //                             {type === PaymentNoticePageEnum.CREATE ?
        //                                 <Form.Item
        //                                     label='Receiver Bank'
        //                                     rules={[{ required: false }]}
        //                                     name="kycId" style={{ width: '100%' }}

        //                                 >
        //                                     <Select
        //                                         disabled={hiddenState}
        //                                         filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank'>
        //                                         {kycData.map((a) => {
        //                                             return <Option value={a.kycId}>{a.bankName}</Option>
        //                                         })}
        //                                     </Select>
        //                                 </Form.Item> : <>{accountLedger?.bankName}</>
        //                             }
        //                         </Col>

        //                         <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
        //                             {type === PaymentNoticePageEnum.CREATE ?
        //                                 <Form.Item
        //                                     label='Enter Payable Amount'
        //                                     name='transactionAmount'
        //                                     style={{ width: '100%' }}
        //                                     rules={[{ required: true, message: 'Enter Payable Amount' }
        //                                     ]}
        //                                 >
        //                                     <InputNumber
        //                                         disabled={isNaN(remainingAmount) || Math.abs(remainingAmount) < 0 ? true : false}
        //                                         style={{ width: '100%' }}
        //                                         max={outstandingInvoice}
        //                                         onChange={transactionAmountOnchangeHandler}
        //                                     />
        //                                 </Form.Item>

        //                                 : <> {approvalTransactionalTotalAmount}</>
        //                             }
        //                         </Col>


        //                     </Row>
        //                     <br />
        //                     <Row>
        //                         <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
        //                             <Form.Item
        //                                 label='Work Log'
        //                                 name='remarks'
        //                                 style={{ width: '100%' }}
        //                                 rules={[
        //                                     {
        //                                         required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
        //                                     },
        //                                     ...classValidator.alphaNumericSpecialCharsWithSpaces(),
        //                                 ]}
        //                             >
        //                                 <Input style={{ width: '100%' }} onChange={() => { }} />
        //                             </Form.Item>
        //                         </Col>
        //                         <Col span={4} />
        //                         <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} >
        //                             <Form.Item
        //                                 style={{ marginTop: "24px" }}
        //                             >
        //                                 <Button type="primary" onClick={() => submitHandler(ContractStatusEnum.ACTIVE)}>Submit For Approval</Button>
        //                             </Form.Item>
        //                         </Col>
        //                     </Row>
        //                 </>
        //             }


        //             <br />
        //             {invoiceTransactionData.length !== 0 &&
        //                 <>
        //                     <Table pagination={false} columns={columns} dataSource={invoiceTransactionData} />
        //                 </>
        //             }



        //         </Form>

        //     </div>
        // </>
        return <Card bordered={false} size='small'>

            <Row gutter={16}>
                {invoiceTransactionData?.map((rec, index) => {
                    let disable: boolean;
                    if (rec.status === ContractStatusEnum.CLOSED && Number(rec.remainingAmount) === 0) {
                        disable = true
                    } else if (Number(rec.remainingAmount) === 0) {
                        disable = false
                    } else {
                        disable = true
                    }


                    return (
                        <Col span={invoiceTransactionData?.length > 1 ? 12 : 24} style={{ marginBottom: 6 }}>
                            <Card bordered={false} className="remarks-card no-shadow" styles={{ body: { padding: '10px', } }}>
                                <Flex justify="space-between" align="center">
                                    <Descriptions
                                        colon={false}
                                        layout="vertical"
                                        column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                    >
                                        <Descriptions.Item label="Act Ledger">
                                            {rec?.actLedger}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <StatusTag status={rec?.status} />
                                </Flex>
                                <Descriptions
                                    colon={false}
                                    layout="vertical"
                                    column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                >
                                    <Descriptions.Item label="Bank Name">
                                        {rec?.bankName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Account Group">
                                        {rec?.actGrp}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Paid Date">
                                        {rec?.paidDate ? moment(rec?.paidDate).format('YYYY-MM-DD') : ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Transaction Amount">
                                        {internationalFormattedValue(rec?.transactionAmount, 3)} {authContext.defaultPlantCurrency}
                                    </Descriptions.Item>


                                </Descriptions>

                                {type !== PaymentNoticePageEnum.CREATE && paymentType === PaymentNoticePageEnum.RECEIVABLES && (
                                    <Flex justify="flex-end" align="center">
                                        {type === PaymentNoticePageEnum.APPROVAL && <Button size='small' disabled={rec.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.FREEZED)}> <FontAwesomeIcon icon={faCheckCircle} /> Approve</Button>}
                                    </Flex>
                                )}

                                {type !== PaymentNoticePageEnum.CREATE && paymentType !== PaymentNoticePageEnum.RECEIVABLES && (

                                    <Flex justify="flex-end" align="center">
                                        {type === PaymentNoticePageEnum.APPROVAL && <Button size='small' disabled={rec.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.FREEZED)}> <FontAwesomeIcon icon={faCheckCircle} /> Approve</Button>}
                                        {type === PaymentNoticePageEnum.RELEASE && <Button size='small' disabled={rec.status === ContractStatusEnum.FREEZED ? false : true} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.CLOSED)}> <FontAwesomeIcon icon={faCheckCircle} /> Credit</Button>}
                                    </Flex>

                                )}

                                {type === PaymentNoticePageEnum.RELEASE && paymentType === PaymentNoticePageEnum.RECEIVABLES && (

                                    <Flex justify="flex-end" align="center">
                                        <Space size={10}>
                                            <Button size='small' disabled={rec.status === ContractStatusEnum.CLOSED || Number(record.remainingAmount) <= 0} className='send-approval-button' onClick={() => openCreditDrawer(rec)} > <FontAwesomeIcon icon={faCheckCircle} /> Credit Transactions</Button>
                                            <Button size='small' disabled={disable} className='approve' onClick={() => approveTransactionRecord(rec.transId, rec.pmtNoticeId, ContractStatusEnum.CLOSED)}> <FontAwesomeIcon icon={faCheckCircle} /> Release</Button>

                                        </Space>
                                    </Flex>

                                )}




                            </Card>
                        </Col>

                    )
                }

                )}
            </Row>
        </Card>
    };


    return (
        <Card
            bordered={false}
            className="card-shadow"
            styles={{ body: { padding: '0px 10px 15px' } }}
            title={<h6>Invoice Details</h6>}
            extra={<ApprovalTracking history={record?.workLog} />}
        >
            <Table
                columns={invoiceColumns}
                dataSource={invoiceData}
                rowKey={(row) => row.invoiceId}
                pagination={false}
                expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                }}
                expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                        <DownCircleOutlined
                            onClick={(e) => onExpand(record, e)}
                        >
                            Collapse
                        </DownCircleOutlined>
                    ) : (
                        <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
                    )
                }
                scroll={{ x: record?.includeFreight ? '120%' : '100%' }}
                size="small"
            />
            {/* <Form form={formRef} autoComplete='off'>
                <table className="table table-bordered border-primary">
                    <tr>
                        <td>
                            <b>Exp Ref #</b>
                        </td>
                        <td>
                            {childData?.costingNo}
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>Date</b>
                        </td>

                        <td>

                            {moment(childData?.costingDate).format('YYYY-MM-DD')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Expense Type</b>
                        </td>
                        <td>
                            {childData?.expenseType}
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>Cost Center</b>
                        </td>

                        <td>

                            {childData?.businessNoAndBarge?.businessNo?.length ? childData?.businessNoAndBarge.businessNo.map(rec => {
                                return <a>{rec?.businessNo}</a>
                            }) : <>NA</>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Vendor</b>
                        </td>
                        <td>
                            {childData?.bpName}
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>Barge</b>
                        </td>

                        <td>
                            {bg?.length ? bg?.map((rec, index) => {
                                const comma = index !== bg.length - 1 ? "," : ''
                                return <a>{SequenceUtils.formatNumberToSpecificLength(rec + comma)}</a>
                            }) : <>NA</>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Quantity (in MT)</b>
                        </td>
                        <td>
                            {childData?.inwardQuantity
                            }
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>Base Price</b>
                        </td>

                        <td>
                            {Number(Number(childData?.tdsTotal * 100) / (Number(childData?.tdsPercentage))).toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Prepared By</b>
                        </td>
                        <td>
                            {childData?.createdUser}
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>PPN</b>
                        </td>

                        <td>
                            {childData?.taxTotal}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>Remarks</b>
                        </td>
                        <td>
                            {childData?.remarks}
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>PPH</b>
                        </td>

                        <td>
                            {childData?.tdsTotal}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>{"Receivable by"}</b><span style={{ color: 'red' }}>*</span>
                        </td>
                        <td>
                            {type === PaymentNoticePageEnum.CREATE ? <Form.Item rules={[{ required: true }]}
                                name="actLedger" style={{ width: '100%' }}>
                                <Select

                                    onChange={(value, opt: any) => bankAccountsHandler(value, opt?.grpName)}
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank Account'>
                                    {ledgerAccounts.map((a) => {
                                        return <Option grpName={a.actLedActGrpId.actGrpName} value={a.actLedId}>{a.actLedName}</Option>
                                    })}
                                </Select>
                            </Form.Item> : <>{accountLedger?.actLedName}</>
                            }
                        </td>
                        <td>

                        </td>
                        <td>
                            <b>Bank Name</b>
                        </td>

                        <td>
                            {type === PaymentNoticePageEnum.CREATE ?
                                <Form.Item
                                    rules={[{ required: false }]}
                                    name="kycId" style={{ width: '100%' }}

                                >
                                    <Select
                                        disabled={hiddenState}
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank'>
                                        {kycData.map((a) => {
                                            return <Option value={a.kycId}>{a.bankName}</Option>
                                        })}
                                    </Select>
                                </Form.Item> : <>{accountLedger?.bankName}</>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Work Log</b><span style={{ color: 'red' }}>*</span>
                        </td>
                        <td>
                            <Form.Item
                                name='remarks'
                                style={{ width: '100%' }}
                                rules={[
                                    {
                                        required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                                    },
                                    ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                                ]}
                            >
                                <Input style={{ width: '100%' }} onChange={() => { }} />
                            </Form.Item>
                        </td>

                        <td>

                        </td>
                        <td>
                            <b>Total Amount</b>
                        </td>

                        <td>
                            {getLocalFormat(Number(childData?.totalAmount), childData?.costingCurrency)}
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <b>Invoice No</b>
                        </td>
                        <td>
                            <Form.Item name={'invoiceNo'} >
                                <Select
                                    disabled={type !== PaymentNoticePageEnum.CREATE}
                                    allowClear showSearch onChange={invoiceOnChangeHandler}>
                                    {invoiceData.map((rec) => {
                                        return <Option value={rec.invoiceId}>{rec.invoiceNo}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </td>

                        <td></td>

                        <td><b>	Invoice Amount</b></td>
                        <td>{filteredInVoice?.invoiceAmount}</td>
                    </tr>

                    <tr>
                        <td><b>Invoice Remaining Amount</b></td>
                        <td>{isNaN(filteredInVoice?.remainingInvoice) ? 0 : filteredInVoice?.remainingInvoice}</td>
                        <td> </td>
                        <td> </td>

                    </tr>
                    <tr>
                        <td><b>Invoice %</b></td>
                        <td>{filteredInVoice?.invoicePercent ? filteredInVoice?.invoicePercent + " " + "%" : ''}</td>
                        <td></td>
                        <td><b>Invoice Date</b></td>
                        <td>{moment(filteredInVoice?.invoiceDate).format('YYYY-MM-DD')}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Costings Remaining Amount</b>
                        </td>

                        <td>
                            {isNaN(remainingAmount) || remainingAmount < 0 ? 0 : remainingAmount.toFixed(2)}
                        </td>
                        <td></td>
                        <td>
                            <b>{type === PaymentNoticePageEnum.CREATE ? "Enter Receivable Amount" : "Receivable Amount"} </b><span style={{ color: 'red' }}>*</span>
                        </td>


                        <td>
                            {type === PaymentNoticePageEnum.CREATE ?
                                <Form.Item
                                    name='transactionAmount'
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Enter Receivable Amount' }
                                    ]}
                                >
                                    <InputNumber
                                        disabled={isNaN(remainingAmount) || remainingAmount < 0 ? true : false}
                                        style={{ width: '100%' }}
                                        onChange={transactionAmountOnchangeHandler}
                                    />
                                </Form.Item>

                                : <> {approvalTransactionalTotalAmount}</>
                            }
                        </td>


                    </tr>

                    <tr>
                        <td colSpan={5}>
                            {/* <a href={fileUploadPath + '/' + childData?.filesData?.[0]?.filePath?.slice(7)} target="_blank" download={`Invoice_${childData?.costingNo}`}>Download Invoice</a> *
                            {invoiceData.length !== 0 &&

                                <Table
                                    columns={invoiceColumns}
                                    dataSource={invoiceData}
                                    rowKey={(row) => row.invoiceId}
                                    pagination={false}
                                />
                            }

                        </td>

                    </tr>
                    <tr>
                        <td>Work log</td>
                        <td>
                            {childData?.workLog.length > 0 && <List
                                bordered
                                dataSource={childData?.workLog ? childData?.workLog : []}
                                renderItem={(item, index) => (
                                    <List.Item key={index} style={{ width: "100%" }}>
                                        <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                                    </List.Item>
                                )}
                            />}
                        </td>

                        <td>

                        </td>
                        <td colSpan={2} >
                            <div style={{ float: 'right' }}>
                                <Button type='primary' hidden={type === PaymentNoticePageEnum.CREATE ? false : true} onClick={() => submitHandler(ContractStatusEnum.ACTIVE)}>Submit For Approval</Button>
                                <Button type='primary' hidden={type === PaymentNoticePageEnum.CREATE ? true : false} danger onClick={() => submitHandler(ContractStatusEnum.CANCELLED)}>Reject</Button>
                            </div>
                        </td>

                    </tr>
                </table>
            </Form> */}
            {/* <br /> */}
            {/* {props.record.transactionalData.length !== 0 &&
                <>
                    <Table pagination={false} columns={columns} bordered dataSource={transactionalData}></Table>
                </>
            } */}

            {creditDrawer &&

                <CreditReceivables
                    costingCurrency={childData?.costingCurrency || authContext.defaultPlantCurrency}
                    totalTransactionValue={totalTransactionValue}
                    onCancel={() => {
                        setCreditDrawer(false);
                        setDummyRefresh(dummyRefresh + 1)
                    }}
                    open={creditDrawer}
                    title={`Transactions Value ${Number(totalTransactionValue?.remainingAmount)}`}
                />
            }

            {/* <Modal
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onCancel={() => setOpenDrawer(false)}
          destroyOnClose
          okText='Save'
          okButtonProps={{ className: 'new-button' }}
          onOk={onSaveShippingInstructions}
          title="Shipping Instruction"
        >

            <Modal
                bodyStyle={{ paddingBottom: 80 }}
                title={`Transactions Value ${Number(totalTransactionValue?.remainingAmount)}`}
                width={window.innerWidth > 768 ? '40%' : '50%'}
                open={creditDrawer}
                closable={creditDrawer}
                key={dummyRefresh}
                footer={null}
                onCancel={() => {
                    setCreditDrawer(false);
                    setDummyRefresh(dummyRefresh + 1)
                }}
            >
                
            </Modal> */}
        </Card>







    )
}

export default ReceivablesChild;