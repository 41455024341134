import { BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, PortTypeEnum, ReportsService } from "@exportx/shared-models-and-services";
import { Button, Card, Descriptions, Input, Modal, Popover, Select, Table, TableColumnsType, Tooltip, } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CloseOutlined, EditOutlined, SaveTwoTone } from "@ant-design/icons";
import { render } from "@testing-library/react";

const LTCReports = () => {
  const navigate = useNavigate() 
  const { Option } = Select;
  const { authContext } = useAuthState();
  const [LTCReports, setLTCReports] = useState([])
  const [edit,setEdit] = useState(false)
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [displayCount, setDisplayCount] = useState(10); 
  const [displayedData, setDisplayedData] = useState([]); 

  let limitedData;

  const bnService = new BusinessNumberService()
  const businessService = new BusinessNumberService();
  const reportsService = new ReportsService();


  useEffect(()=>{
    getAllLTCReports()
    getBusinessNoDropDown()
  },[])

  


    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessService.getBusinessNoDropDown(req).then(res => {
          if (res.status) {
            let dropdown = res.data.map(item => {
              let bnNo = item.businessNo.split(" ").pop()
              return {
                ...item,
                bnNo: bnNo
              }
            })
            setBusinessDropDown(dropdown)
          } else {
            console.log(res.internalMessage)
          }
        }).catch(err => {
          console.log(err.message)
        })
    }

  const getAllLTCReports = () => {
    bnService.getAllLTCReports({ plantCode: authContext.defaultPlant }).then(res => {
      if(res.status){
        setLTCReports(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
}

  const handleSubmit = () => {
    const currentDate = new Date()
    const formattedDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    const saveData = displayedData
    .filter(item => item.isUpdated === true || item.isNewRecord === true)
    .map(item => ({
      ...item,
      updatedBy: authContext.user.userName,
      updatedAt: formattedDate,
      createdBy: authContext.user.userName,
      createdAt: formattedDate,
      plantCode: authContext.defaultPlant
    }));
    console.log('saveing data',saveData)

    reportsService.createLTCReport(saveData).then(res => {
      if(res.status){
        getAllLTCReports()
        setEdit(null)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }


  const handleClick = (index) => {
    setEdit(index)
  };

  
  const ltcReportsData = (qualities: any[]) => {
    const ltcData = [];

    qualities?.forEach((rec) => {
    rec.contractData?.forEach((contractRecord, index) => {
      ltcData.push({
            id: contractRecord.id ? contractRecord.id : '',
            client: contractRecord.client,
            contractId: contractRecord.contractId,
            contractNo: contractRecord.contractNo,
            fileStatus: contractRecord.fileStatus,
            comment: contractRecord.comment,
            contractTypeId: contractRecord.contractTypeId,
            incoterm: contractRecord.incoterm,
            qualitiesId: contractRecord.qualitiesId,
            loadingRate: contractRecord.loadingRate,
            dischargeRate: contractRecord.dischargeRate,
            laycan: contractRecord.laycan,
            allocationId: contractRecord.allocationId,
            ltcApplicable: contractRecord.ltcApplicable,
            portType: contractRecord.portType,
            totalAmount: contractRecord.totalAmount,
            completedLoadTime: contractRecord.completedLoadTime,
            allowedTimeToLoad: contractRecord.allowedTimeToLoad,
            noOfDifference: contractRecord.noOfDifference,
            demurrage: contractRecord.demurrage,
            dispatch: contractRecord.dispatch,
            rowSpan: index === 0 ? rec.contractData.length : 0,
            bnId: rec.bnId,
            businessNo: rec.businessNo,
            mvName: rec.mvName,
            mvQty: rec.mvQty,
            blDates: rec.blDates,
            });
        });
      });
    return ltcData;
  };


  const handleLoadMore = () => {
    setDisplayCount(prevCount => prevCount + 10);
  };

  const handleBusinessNoChange = (value) => {
    setSelectedBusinessNo(value);
  };
  

  const filteredData = selectedBusinessNo
    ? ltcReportsData(LTCReports).filter(
        (item) => item.bnId === selectedBusinessNo
      )
    : ltcReportsData(LTCReports);

    limitedData = LTCReports.slice(0, displayCount);
    let data = ltcReportsData(limitedData);
    
    useEffect(() => {
      if(!selectedBusinessNo){
        setDisplayedData([...data]);
      } else {
        setDisplayedData([...filteredData]);
      }
    },[LTCReports,displayCount,selectedBusinessNo])


    const handleChange = (value: any, key: string, index: number) => {
      const newValue = value && value.target ? value.target.value : value;
    
      const newData = [...displayedData];
      newData[index][key] = newValue;
      if(newData[index].id){
        newData[index].isUpdated = true;
      } else {
        newData[index].isNewRecord = true;
      }
      setDisplayedData(newData);
    };


  const columns: any = [
      {
        title: 'Business Number',
        dataIndex: 'businessNo',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <><a className="link" href={link}>{text} ({record.mvName})</a></>
        }
      },
      {
        title: 'Client',
        dataIndex: 'client',
      },
      {
        title: 'Contract No',
        dataIndex: 'contractNo',
      },
      {
        title: 'Incoterm',
        dataIndex: 'incoterm',
      },
      {
        title: 'Load Rate',
        children: [
          {
            title: 'Load Port',
            dataIndex: 'loadingRate',
          },
          {
            title: 'Discharge Port',
            dataIndex: 'dischargeRate',
          },
        ]
      },
      {
        title: 'Laycan',
        dataIndex: 'laycan',
        width: 180,
        render: (text, record) => {
          if (record.laycan) {
            const [fromDate, toDate] = record.laycan.split(' - ');
            if (fromDate && toDate) {
              return (
                <div>
                  {moment(fromDate).isValid() && moment(toDate).isValid()
                    ? `${moment(fromDate).format('DD-MMM-YYYY')} - ${moment(toDate).format('DD-MMM-YYYY')}`
                    : null}
                </div>
              );
            }
          } else {
            return null
          }
        }
      },
      {
        title: 'Load Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'demurrage',
            // onCell: (record: any) => ({
            //   rowSpan: record.rowSpan
            // }),
            render: (text,record) => {
               return text && record.portType !== PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.dispatch}</> : null
            }
          }
        ]
      },
      {
        title: 'Discharge Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'dispatch',
            // onCell: (record: any) => ({
            //   rowSpan: record.rowSpan
            // }),
            render: (text,record) => {
              return record.portType === PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.demurrage}</> : null 
           }
          }
        ]
      },
      {
        title: 'Stowage',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let totalHetchQty = record.mvQty.reduce((a, c) => a + Number(c.hatchQty), 0)
          return totalHetchQty ? totalHetchQty : null
        }
      },
      {
        title: 'Loaded',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let finalQty = record.mvQty.reduce((a, c) => a + Number(c.finalQty), 0)
          return finalQty ? finalQty : null
        }
      },
      {
        title: 'Dead Freight',
        dataIndex: 'mvQty',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let totalHetchQty = record.mvQty.reduce((a, c) => a + Number(c.hatchQty), 0)
          let finalQty = record.mvQty.reduce((a, c) => a + Number(c.finalQty), 0)
          return (totalHetchQty - finalQty) ? (totalHetchQty - finalQty) : null
        }
      },
      {
        title: 'BL Date',
        dataIndex: 'blDates',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
           if(record.blDates && record.blDates !== null){
            let blDates = record.blDates?.split(',');
          return (
            <>
              {blDates.map((blDate, index) => (
                <div key={index}>
                  {moment(blDate).format('DD-MMM-YYYY')}
                </div>
              ))}
            </>
          );
           } else {
            return null
           }
        }
      },     
      {
        title: 'LTC Applicable',
        dataIndex: 'ltcApplicable',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text,record) => {
           return record.ltcApplicable ? (record.ltcApplicable === 1 ? 'YES' : 'NO') : null
        }
      },
      {
        title: 'LTC Status LP',
        dataIndex: 'status',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text,record) => {
          return record.portType !== PortTypeEnum.DISCHARGE_PORT ? PortTypeEnum.LOADING_PORT : null
        }
      },
      {
        title: 'DEM Days',
        dataIndex: '',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text, record) => {
          let demDays = record.completedLoadTime > record.allowedTimeToLoad ? record.noOfDifference : 0;
          return demDays
        }
      },
      {
        title: 'DES Days',
        dataIndex: '',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text, record) => {
          let desDays = record.completedLoadTime < record.allowedTimeToLoad ? record.noOfDifference : 0;
          return desDays
        }
      },
      {
        title: 'LTC Status DP',
        dataIndex: 'status',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text,record) => {
          return record.portType === PortTypeEnum.DISCHARGE_PORT ? record.portType : null
        }
      },
      {
        title: 'LTC Amount LP',
        dataIndex: 'totalAmount',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text,record) => {
          return record.portType !== PortTypeEnum.DISCHARGE_PORT  ? record.totalAmount : null
        }
      },
      {
        title: 'LTC Amount DP',
        dataIndex: 'totalAmount',
        // onCell: (record: any) => ({
        //   rowSpan: record.rowSpan
        // }),
        render: (text,record) => {
          return record.portType === PortTypeEnum.DISCHARGE_PORT ? record.totalAmount : null
        }
      },
      {
        title: 'File Status',
        dataIndex: 'fileStatus',
        width: 140,
        render: (text,record, index) => {
          return edit === index ? (
            <Select
              style={{ width: '100%' }}
              placeholder="File status" 
              value={record.fileStatus || null}
              onChange={(e)=> handleChange(e,'fileStatus',index)}
              allowClear
            >
              {['Initated','Pending Confirmation','Confirmed', 'Payment pending','Completed'].map(item => {
                 return <Option value={item}>{item}</Option>
              })}
            </Select>
          ) : (
            text
          )
        }
      },
      {
        title: 'Comments',
        dataIndex: 'comment',
        width: 140,
        render: (text,record, index) => {
          return edit === index ? (
            <Input 
                style={{ width: '100%' }}
                value={record.comment || null}
                onChange={(e) => handleChange(e,'comment',index)}
                placeholder="comment"
                autoComplete="off"
                allowClear
            />
          ) : (
            text
          )
        }
      },
      {
        title: 'Action',
        fixed: 'right',
        align: 'center',
        render: (text, record, index) => {
          return edit === index ? (
          <>
            <Tooltip placement="top" title="Save">
                <Button style={{ padding: '0px 3px' }}><SaveTwoTone
                    onClick={handleSubmit}
                    style={{ color: '#1890ff', fontSize: '18px' }}
                /></Button>
            </Tooltip> &nbsp;
            <Tooltip placement="top" title="Cancel">
            <Button style={{ padding: '0px 3px' }}>
            <CloseOutlined
                type="cancel"
                onClick={() => {
                  const newData = [...displayedData];
                  if (newData[index]) {
                    newData[index].fileStatus = null;
                    newData[index].comment = null;
                  }
                  setDisplayedData(newData);
                  setEdit(null)}}
                style={{ fontSize: '18px' }}
            /></Button>
            </Tooltip>
          </>) : (
            <Tooltip placement="top" title="Edit">
            <EditOutlined
                type="edit"
                onClick={() => {handleClick(index)}}
                style={{ color: '#1890ff', fontSize: '18px' }}
            />
            </Tooltip>
          )
        }
      }
  ]
  
  console.log('mappeddddddddddddddddd',displayedData)
  return (<>
    <Card
        title={<span className='card-title'>LTC Report</span>}
        className='card-container'
        headStyle={{ border: 'none' }}
      >
          <Card 
            className='card-radius'>
              <Descriptions layout="vertical">
                <Descriptions.Item label="Business Number">
                  <Select
                      style={{ width: '28%', marginBottom: '12px' }} 
                      placeholder="Business Number" 
                      value={selectedBusinessNo}
                      onChange={handleBusinessNoChange}
                      allowClear
                  >
                      {businessDropDown.map(item => (
                          <Option value={item.bnId} key={item.bnId}>
                              {item.bnNo} {item.motherVesselName && `(${item.motherVesselName})`}
                          </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
              </Descriptions>

            <Table
              className="contracts-list"
              columns={columns}
              dataSource={displayedData}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            { limitedData.length < LTCReports.length && !selectedBusinessNo && (
              <Button onClick={handleLoadMore} className="new-button" style={{ marginTop: '20px' }}>
                Load More
              </Button>
            )}
          </Card>
    </Card>
    </>
  )
}

export default LTCReports;