import { DeleteOutlined, EditFilled, EditOutlined } from '@ant-design/icons';
import { AdvanceEnum, AdvancePreview, ContractStatusEnum, ContractTypesEnum, CostingInvoiceReqDto, CostingInvoiceService, PriceTypeEnum, ReferenceFeatures, VendorAdvInvoiceService, VendorAdvService, vendorInvoiceIdReq } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Descriptions, Divider, Form, Popconfirm, Row, Space, Table, TableColumnsType, Tabs, Tag, Tooltip } from 'antd';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import PaymentTermsView from '../../../contracts/view-components/payment-terms-view';
import AddInvoice from '../../../costings/add-invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import TabPane from 'antd/es/tabs/TabPane';

const AdvanceContractDetails = () => {

  const navigate = useNavigate()
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const { state }: any = useLocation();
  //const { record } = state ? state : { record: null };
  const services = new VendorAdvService()
  const [previewData, setPreviewData] = useState(null);
  const service = new VendorAdvInvoiceService();
  const [invoiceData, setInvoiceData] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(1)
  const [initialValues, setInitialValues] = useState<any>({})
  const [formRef] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.id;
  const invoiceService = new CostingInvoiceService();
  const [adjustedData, setAdjustedData] = useState<any>()
  const [snapData, setSnapData] = useState<any>([]);
  const [accessId, setAccessId] = useState<any>({})
  const [approvePermission,setApprovePermission] = useState(false)
  const [rejectPermission,setRejectPermission] = useState(false)
  const [editPermission, setEditPermission] = useState(false)
  const [viewPermission, setViewPermission] = useState(false)

  let { record } = paramBargeId != null ? { record: paramBargeId } : state ? state : { record: null };


  let pathToreDirect = '/po-detail-view'

  useEffect(() => {
    getAdvancePreviewData(record);
    getAdvAdjustedRemainingData(record);
  }, [record]);

  const getAdvAdjustedRemainingData = (id) => {
    const req = new AdvancePreview(id, authContext.defaultPlant);
    services.getAdvAdjustedRemainingData(req).then(res => {
      if (res.status) {
        setAdjustedData(res.data)
      }
    }).catch(err => console.log(err.message))
  }

  const getAdvancePreviewData = (record) => {
    const req = new AdvancePreview(record, authContext.defaultPlant);
    services.getAdvancePreviewData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res.snapData)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });





  }

  const setFormData = () => {
    navigate('/vendorAdvance-Update', { state: { vendorAdvData: { ...previewData } } });
  };


  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(record, authContext.defaultPlant, undefined, ReferenceFeatures.ADVANCE_INVOICE, );
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setInvoiceData(res.data)
      } else {
        setInvoiceData([]);
        console.log(res.internalMessage);

      };
    }).catch(err => console.log(err.message));
  };



  useEffect(() => {
    if (initialValues) {
      formRef.setFieldsValue(initialValues);
    }
  }, [initialValues])

  if (initialValues === undefined) {
    formRef.setFieldValue('invoicePercent', undefined);
    formRef.setFieldValue('invoiceNo', undefined);
    formRef.setFieldValue('remarks', undefined);
    formRef.setFieldValue('invoiceDate', undefined);
    formRef.setFieldValue('invoiceAmount', undefined);
    formRef.setFieldValue('invoiceId', undefined);
  }


  if (initialValues?.invoiceDate) {
    initialValues.invoiceDate = moment(initialValues?.invoiceDate)
  }

  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (previewData.contractTypes === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.contractNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }


  const paymentTerms = { lc: previewData?.lc, others: previewData?.others, telegraphicTransfer: previewData?.telegraphicTransfer, lcValue: previewData?.lcValue, lcType: previewData?.lcType, lcCurrency: previewData?.lcCurrency, paymentMode: previewData?.paymentMode }
  const contractDetails = [
    {
      title: 'Vendor',
      dataIndex: 'vendorName', 
      render: (value, record) => {
        const link = `/#/detailCustomer?bp_id=${record?.vendorId}`
          return <> {
          <Link href={link} className="link-primary">{valiDateWithSnapShotData(['vendorName'], value, false)}</Link>
        }
        </>
      }
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (value, record) => {
        const formatDate = value ? moment(value).format('DD/MM/YYYY') : '';
        return (
          <span>{valiDateWithSnapShotData(['contractDate'], formatDate, false)}</span>
        )
      }
    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['currencyName'], previewData?.currencyName, false)}</span>
      ),
    },
    {
      title: 'Negotiation Currency',
      dataIndex: 'negotiationCurrency',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['negotiationCurrency'], previewData?.negotiationCurrency, false)}</span>
      ),
    },
    {
      title: 'Advance Amount',
      dataIndex: 'advanceAmount',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['advanceAmount'], previewData?.advanceAmount, false)}</span>
      ),
    },
    {
      title: 'JISDOR Date',
      dataIndex: 'jisdorDate',
      render(value, record) {
          const formatDate = value ? moment(value).format('DD/MM/YYYY') : '';
          return (
            <span>{valiDateWithSnapShotData(['jisdorDate'], formatDate, false)}</span>
          )
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['amount'], previewData?.amount, false)}</span>
      ),
    },

    {
      title: 'Purchase Contract',
      dataIndex: 'purchaseContractName',
      render: (value, record) => {
        const link = `/#/po-detail-view?pc_id=${record?.purchaseContractNo}`
        return <> {
          <Link href={link} className="link-primary">{value}</Link>
        }
        </>
      }
    },
    {
      title: `${previewData?.contractTypes === 'Addendum' ? 'Base Contract' : 'Addendum Data' }`,
      dataIndex: '',
      render: (text, record) => {
        if (previewData?.contractTypes === 'Original') {
          console.log('inside if loop',)
          return (
            <div>
              {previewData?.addendumData?.map((rec) => { 
                const link = `/#/advanceDetailView?id=${rec.id}`;
                return (
                  <a href={link} className="link-primary">
                    {`${rec.contractNo}, `}
                  </a>
                );
              })}
            </div>
          );
        } else {
          const link = `/#/advanceDetailView?id=${previewData?.baseContractId}`;
          return (
            <a href={link} className="link-primary">
              {previewData?.baseContractNo}
            </a>
          );
        }
      }
    }

  ]


  const deduction : TableColumnsType = [
    {
      title: 'Deduction Type',
      dataIndex: 'deductionType',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['deductionType'], previewData?.deductionType, false)}</span>
      ),
    },
    {
      title: previewData?.deductionType === PriceTypeEnum.LUMP_SUM ? 'Lumpsum Price' : 'Price PMT',
      dataIndex: 'pricePMT',
      align: 'right',
      render: (text, record) => {
        const price = record?.deductionType === PriceTypeEnum.LUMP_SUM ? record?.lumpSum : record?.pricePMT;
        return (
          <span>
            {valiDateWithSnapShotData(['pricePMT'], Number(price).toFixed(2), false)}
          </span>
        );
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'other',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['other'], previewData?.other, false)}</span>
      ),
    },

  ]



  const downPayments = [
    {
      title: 'Invoice No	',
      dataIndex: 'invoiceNo',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: (text, record) => { return record.invoiceDate !== undefined ? moment(record.invoiceDate).format('YYYY-MM-DD') : "" },
    },
    {
      title: 'Invoice %	',
      dataIndex: 'invoicePercent',
    },
    {
      title: 'Invoice Amount	',
      dataIndex: 'invoiceAmount',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      render: (value, record) =>
        // <span><EditOutlined  
        // style={{ color: '#1890ff', fontSize: '14px' }}
        // onClick={()=>{addInvoice ()}}  /> <PlusSquareOutlined onClick={() => { handlePayments() }} /></span>,
        <span>
          <Tooltip placement="topRight" title="Edit">
            <EditOutlined type="edit"
              onClick={() => editClickHandler(record)}
              style={{ color: '#1890ff', fontSize: '20px' }}
            /></Tooltip>
          <Divider type="vertical" />
          <Popconfirm
            onConfirm={e => {
              deleteInvoice(record?.id)
            }}
            title={'Are you sure to Delete Record ?'}>
            <Tooltip placement="topRight" title="Delete">
              <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
            </Tooltip>
          </Popconfirm>


        </span>
    },

  ];
  const Adjustments : TableColumnsType = [
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (text, record) => {
        return text && text.map(rec => {
          const link = `/#/bn-detail-view?bn_Id=${rec.businessNoId}`
          return <><a href={link} className="link" >
            {rec?.businessNumber}
          </a>&nbsp;</>
        })

      }
    },
    {
      title: 'Costing Number',
      dataIndex: 'costingNo',
    },
    {
      title: 'Financial Amount',
      dataIndex: 'financialTransactionAmount',
      align: 'right',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record) => {
        return value ? Number(value).toFixed(2) : ''
      }
    },
    {
      title: 'Adjusted Amount',
      dataIndex: 'adjustedAmount',
      align: 'right',
      render: (value, record) => {
        return value ? Number(value).toFixed(2) : ''
      }
    },

    {
      title: 'Remaining Paid Advance',
      dataIndex: 'remainingAdvance',
      align: 'right',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record) => {
        return value ? Number(value).toFixed(2) : ''
      }
    },
    {
      title: 'Un Paid Amount',
      dataIndex: 'unPaidAmount',
      align: 'right',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record) => {
        return value ? Number(value).toFixed(2) : ''
      }
    },
  ];


  const getTableData = (values: any) => {
    const empty = [];
    [values]?.forEach((rec) => {
      if (rec?.adjustedData?.length) {
        rec?.adjustedData?.forEach((record, index) => {
          const remainingAdvance = Number(rec?.financialTransactionAmount) - Number(rec?.totalAdjustedAmountOfAllCostings)
          const unPaidAmount = previewData?.amount - rec?.financialTransactionAmount
          empty.push({
            businessNo: record.businessNo,
            bnId: record.bnId,
            costingNo: record.costingNo,
            adjustedAmount: record.adjustedAmount,
            rowSpan: index === 0 ? adjustedData.adjustedData.length : 0,
            financialTransactionAmount: rec.financialTransactionAmount,
            remainingAdvance: remainingAdvance ? remainingAdvance : 0,
            unPaidAmount: unPaidAmount ? unPaidAmount : 0
            //  previewData?.amount - rec.transactionTotal
          })
        });
      } else {
        const remainingAdvance = Number(rec?.financialTransactionAmount) - Number(rec?.totalAdjustedAmountOfAllCostings)
        const unPaidAmount = previewData?.amount - rec?.financialTransactionAmount
        empty.push({
          businessNo: [],
          costingNo: '',
          adjustedAmount: '',
          rowSpan: 1,
          financialTransactionAmount: rec?.financialTransactionAmount,
          remainingAdvance: remainingAdvance ? remainingAdvance : 0,
          unPaidAmount: unPaidAmount ? unPaidAmount : 0
          // previewData?.amount - rec?.transactionTotal
        })
      }


    });
    return empty;
  }

  useEffect(() => {
    if (previewData) {

      let permission;
      if (previewData?.contractType === AdvanceEnum.VendorAdvance) {
        permission = 174;
        setAccessId({
          create: 181,
          update: 183,
          delete: 184,
          approve: 185,
          reject: 186,
          release: 187,
          files: 188,
        });
        setApprovePermission(isPermissionExist([177]));
        setRejectPermission(isPermissionExist([178]));
        setEditPermission(isPermissionExist([175]));
        setViewPermission(isPermissionExist([182]));
      } else if (previewData?.contractType === AdvanceEnum.DownPayment) {
        permission = 194;
        setAccessId({
          create: 201,
          update: 203,
          delete: 204,
          approve: 205,
          reject: 206,
          release: 207,
          files: 208,
        });
        setApprovePermission(isPermissionExist([197]));
        setRejectPermission(isPermissionExist([198]));
        setEditPermission(isPermissionExist([195]));
        setViewPermission(isPermissionExist([202]));
      } else {
        permission = 214;
        setAccessId({
          create: 221,
          update: 223,
          delete: 224,
          approve: 225,
          reject: 226,
          release: 227,
          files: 228,
        });
        setApprovePermission(isPermissionExist([217]));
        setRejectPermission(isPermissionExist([218]));
        setEditPermission(isPermissionExist([215]));
        setViewPermission(isPermissionExist([222]));
      }

      if (!isPermissionExist([permission])) navigate('/');
    }
  }, [previewData, navigate]);



  const addInvoice = () => {
    setIsModalOpen(true);
    setInitialValues(undefined);
    formRef.setFieldValue('id', undefined)
    fieldsReset();
    setDummyRefresh(prev => prev + 1);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInitialValues(undefined);
    setDummyRefresh(prev => prev + 1)
    fieldsReset();
  };

  const editClickHandler = (record) => {
    setDummyRefresh(prev => prev + 1);
    setInitialValues(record);
    setIsModalOpen(true);
  };



  const fieldsReset = () => {
    formRef.resetFields();
    setFileList([])
  };


  useEffect(() => {
    getAllCostingInvoice();
  }, []);

  const addendumClickHandler = () => {
    const link = `/vendorAdvance-Addendum?va_Id=${record}` 
    navigate(link, { state: { vendorAdvData: { ...previewData } } })
  }

  const deleteInvoice = (id: string) => {
    const req = new vendorInvoiceIdReq(id, authContext.defaultPlant,)
    service.deleteInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);

      }
    }).catch(err => console.log(err.message))
  }

  const sendForApproval = (id: string) => {
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName,);
    services.advanceSendForApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        getAdvancePreviewData(record);
        getAdvAdjustedRemainingData(record);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  const advanceApproveOrReject = (id: string, status: ContractStatusEnum) => {
    if (!id) {
      AlertMessages.getErrorMessage(fm({ id: ``, defaultMessage: 'Contract id not found' }));
      return;

    }
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName, status, previewData.contractTypes);
    services.advanceApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        // getAdvanceContractType()
        getAdvancePreviewData(record);
        getAdvAdjustedRemainingData(record);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  console.log('typeeeeeeeeeeee',previewData?.contractTypes) 
  const getExtraComponent = (status: any) => {
   
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button className='send-approval-button' onClick={() => sendForApproval(previewData?.id)}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <>{approvePermission && (<Button size="small" className='approve' onClick={() => advanceApproveOrReject(previewData?.id, ContractStatusEnum.ACTIVE)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>)}
        {rejectPermission && (<Button size="small" className='reject' onClick={() => advanceApproveOrReject(previewData?.id, ContractStatusEnum.DRAFT)}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>)}
      </>;
    }
    if(status === ContractStatusEnum.ACTIVE && previewData?.contractTypes === ContractTypesEnum.ORIGINAL){
        return <Button className='new-button' onClick={() => addendumClickHandler()}>Create Addendum</Button>
    }

  }

  const pathToListPage = previewData?.contractType === AdvanceEnum.VendorAdvance ? '/#/vendor-advance' : previewData?.contractType === AdvanceEnum.DownPayment ? '/#/down-payment' : '/#/vendor-advance-against-contract';

  return (
    <div className='layout' >
        <Row justify='space-between' className='mb-12' >
                <Space size={5}>
                    <a href={pathToListPage} className='arrow-left'>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </a>
                    <span className='card-title-md'>Contract Details - {previewData?.['contractNo']}</span>&nbsp;
                    <StatusTag status={previewData?.['status']} />
                </Space>
                <Space size={10}>
                  {previewData?.['status'] !== ContractStatusEnum.ACTIVE && editPermission && (
                      <Button className='action-icons'
                        icon={<EditFilled />}
                        onClick={setFormData}
                      
                      />
                    )}
                                  
                    {getExtraComponent(previewData?.['status'])}
                </Space>
          </Row>

          <Row gutter={[16, 12]} >
              <Col span={previewData?.addendumData?.length > 0 ? 14 : 24} className='flex width-full'
                  xs={24}
                  md={24}
                  lg={previewData?.addendumData?.length > 0 ? 14 : 24}
              >
                  <Card title={<span className='card-title-md'>Contract Details</span>} headStyle={{border: 'none', paddingTop: '10px'}} bordered={false} className='card-radius full-width'>
                      <Row>
                          <Col>
                              <Descriptions
                              className='descriptions'
                                  layout='vertical'
                                  colon={false}
                              >
                                  <Descriptions.Item label={"Vendor"}>
                                    <a href={ `/#/detailCustomer?bp_id=${previewData?.vendorId}`} className='link'>
                                    {valiDateWithSnapShotData(['vendorName'], previewData?.vendorName, false)}
                                    </a>
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Contract Date"}>
                                  <span>{valiDateWithSnapShotData(['contractDate'], previewData?.contractDate ? moment(previewData?.contractDate).format('DD\u2011MMM\u2011\YYYY') : '', false)}</span>
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Currency"}>
                                    <span className='currency'>
                                      {valiDateWithSnapShotData(['billingCurrency'], previewData?.billingCurrency, false)}
                                    </span>
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Negotiation Currency"}>
                                    <span className='currency'>
                                    {valiDateWithSnapShotData(['negotiationCurrency'], previewData?.negotiationCurrency, false)}
                                    </span>  
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Advance Amount"}>
                                  {valiDateWithSnapShotData(['advanceAmount'], Number(previewData?.advanceAmount).toFixed(2), false)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"JISDOR Date"}>
                                  {valiDateWithSnapShotData(['jisdorDate'], previewData?.jisdorDate ? moment(previewData?.jisdorDate).format('DD\u2011MMM\u2011\YYYY') : '', false)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Amount"}>
                                  {valiDateWithSnapShotData(['amount'], Number(previewData?.amount).toFixed(2), false)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={"Purchase Contract"}>
                                  <a className='link' href={`/#/po-detail-view?pc_id=${previewData?.purchaseContractNo}`}>
                                  {valiDateWithSnapShotData(['purchaseContractName'], previewData?.purchaseContractName, false)}
                                  </a>
                                  </Descriptions.Item>
                                  {previewData?.contractTypes === ContractTypesEnum.ADDENDUM &&
                                  <Descriptions.Item label={ "Base Contract"} >
                                      <a className='link' href={`/#/advanceDetailView?id=${previewData?.baseContractId}`}>
                                          {valiDateWithSnapShotData(['baseContractNo'], previewData?.baseContractNo, false)}
                                      </a>
                                  </Descriptions.Item> 
                                  }
                              </Descriptions>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              { (previewData?.addendumData && previewData?.addendumData?.length > 0) &&
                  <Col className='flex'
                  xs={24}
                  md={24}
                  lg={10}
                  >
                      <Card title={<span className='card-title-md'>Addendums</span>} headStyle={{ border: 'none', paddingTop: '10px'}} bordered={false} className='card-br-8 full-width addendums-height' >
                          <Row className='mb-12'>
                              <Card 
                                  bordered={false} 
                                  headStyle={{ border: 'none' }} 
                                  size="small" 
                                  className='addendum-card'
                              >
                                  <Space size={16}>
                                  <Tooltip placement="top" title={previewData.addendumData[0].status}>
                                      <a 
                                      href={`/#/advanceDetailView?id=${previewData.addendumData[0].id}`} 
                                      className="link"
                                      >
                                      {previewData.addendumData[0].contractNo}
                                      </a>
                                  </Tooltip>
                                  <StatusTag status={previewData.addendumData[0].status} />
                                  </Space>
                              </Card>
                          </Row>

                          <Row gutter={[12, 12]}>
                              {previewData.addendumData.slice(1).reverse().map((rec, index) => (
                                <Col key={index}>
                              <Card 
                                  bordered={false} 
                                  headStyle={{ border: 'none' }} 
                                  size="small" 
                                  className='addendum-card'
                              >
                                  <Space size={16}>
                                  <Tooltip placement="top" title={rec.status}>
                                      <a 
                                      href={`/#/advanceDetailView?id=${rec.id}`} 
                                      className="link"
                                      >
                                      {rec.contractNo}
                                      </a>
                                  </Tooltip>
                                  <StatusTag status={rec.status} />
                                  </Space>
                              </Card>
                              </Col>
                          ))}
                          </Row>
                      </Card>
                  </Col>
              }
        </Row> 
        <br />

        <Card title={<span className='card-title-md'>Deductions</span>}
        headStyle={{ border: 0, paddingTop: '10px' }} className='card-radius'
      >
        <Table className='contracts-list' columns={deduction} dataSource={Array(previewData)} pagination={false} />
      </Card>  
              <br />
        <Row>
          <Col span={24}>
            <Card title={<span className='card-title-md'>Additional terms</span>} bordered={false} headStyle={{border: 'none', paddingTop: '10px'}} className='card-br-8 full-width'>
                <Tabs tabPosition='left' tabBarStyle={{backgroundColor: '#F7F9FC'}}>
                    <TabPane tab="Payment Terms" key="1">
                    <PaymentTermsView paymentTerms={paymentTerms} />
                    </TabPane>
                    <TabPane tab="Remarks" key="2">
                    <Row>
                        <Col span={24}>
                        <Card title='Remarks' className='terms-card'  bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                        <Divider dashed className='divider'></Divider>
                        <pre>
                            {previewData?.remarks}
                            </pre>
                        </Card>
                        </Col>
                    </Row>
                    </TabPane>
                    <TabPane tab="Audit Information" key="3">
                    <Card title='User Details' className='terms-card' bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                    <Divider dashed className='divider'></Divider>
                    <Row>
                      <Col>
                        <Descriptions 
                            className='descriptions'
                            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Created By" >
                              {previewData?.createdUser}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved By" >
                              {previewData?.approvedBy}
                            </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col>
                        <Descriptions
                            className='descriptions'
                            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Created Date">
                              {previewData?.createdAt ? moment(previewData?.createdAt).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved Date">
                              {previewData?.approvedOn ? moment(previewData?.approvedOn).format('DD\u2011MMM\u2011YYYY h:mm A') : ''}
                            </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                    </Card>
                    </TabPane>
                </Tabs>
            </Card>
          </Col>
      </Row>

      <br />

      {viewPermission && (
          < AddInvoice
            costingData={previewData}
            vendorAdvanceType={ReferenceFeatures.ADVANCE_INVOICE}
            vendorId={previewData?.vendorId}
            contractNo={previewData?.contractNo}
            accessId={accessId}

          />)}

      <Card title={<span className='card-title-md'>Down Payment Adjustments</span>}
        headStyle={{ border: 0, paddingTop: '10px' }} className='card-radius'
      >
        <Table className='contracts-list' columns={Adjustments} dataSource={getTableData(adjustedData)}
          pagination={false} />
      </Card>         

      {/* <Card>
        <Card>
          <Card
            title={<span >Contract Details - {previewData?.['contractNo']} <Tag color="#55acee">
              {previewData?.['status']}
            </Tag></span>}
            style={{ textAlign: 'center' }}

            headStyle={{ backgroundColor: '#69c0ff', border: 0, color: '#fff' }}
            extra={<span style={{ color: 'white' }}>{getExtraComponent(previewData?.['status'])}</span>}
          >
            <Row>
              <Col span={24}>
                <Descriptions
                  title=""
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >


                  <Descriptions.Item label="Approved By">
                    {previewData?.['approvedBy']}
                    {valiDateWithSnapShotData(['shippingTerms', 'loadingRateForGeared'], shippingTerms?.loadingRateForGeared, false)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Approved On">
                    {previewData?.['approvedOn'] ? moment(previewData?.['approvedOn']).format('DD-MM-YYYY H:mm A') : ''}
                  </Descriptions.Item>
                </Descriptions></Col>
            </Row>
            <Table dataSource={Array(previewData)} columns={contractDetails} pagination={false} bordered /><br></br>
            <Table dataSource={Array(previewData)} columns={deduction} pagination={false} bordered /><br></br>
            <Card >
              <PaymentTermsView paymentTerms={paymentTerms} />
            </Card>
          </Card><br />


          <Modal title="Invoices" open={isModalOpen} footer={null} onCancel={handleCancel} key={dummyRefresh}>
            <AddCostingInvoicesForm
              initialValues={initialValues}
              dummyRefresh={dummyRefresh}
              formRef={formRef}
              getAllCostingInvoice={getAllCostingInvoice}
              setInvoiceFormDrawer={setIsModalOpen}
              setDummyRefresh={setDummyRefresh}
              // fieldsReset={fieldsReset}
              // previewData={previewData}
              vendorAdvanceType={previewData?.contractType}
              costingData={previewData}
              invoiceTotal={0}
            // vendorId={record}
            />
          </Modal>
          {viewPermission && (
          < AddInvoice
            costingData={previewData}
            vendorAdvanceType={ReferenceFeatures.ADVANCE_INVOICE}
            vendorId={previewData?.vendorId}
            contractNo={previewData?.contractNo}
            accessId={accessId}

          />)}



          <Card title={<span style={{ color: 'white' }}>Down Payment Adjustments</span>}
            headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<span style={{ color: 'white' }}></span>}>
            <Table columns={Adjustments} dataSource={getTableData(adjustedData)}
              pagination={false} bordered />
          </Card>

          <Affix offsetBottom={0}>
            <Card bodyStyle={{ padding: '6px 12px' }}>
              <Row justify='space-between'>
                <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                {previewData?.['status'] !== ContractStatusEnum.ACTIVE && editPermission && (
                  <Col><Button onClick={setFormData}>Edit</Button></Col>
                )}
              </Row>
            </Card>
          </Affix>
        </Card>



      </Card> */}
      {/* <Modal open=isOpen}>
        <PaymentPosting />

      </Modal> */}
    </div>
  )
}

export default AdvanceContractDetails