import React, { useCallback, useEffect, useState } from "react";
import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { Button, Card, Col, Flex, Form, Input, Row, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { AccountPayableCostings, InvoiceDetails } from "../costing-detail-view";
import { BGCostingParentReqDto, CostingWorkLogsEnum, ContractStatusEnum, PaymentNoticeGetDataDto, PaymentNoticeService, ReferenceFeatures, CmApprovalsReqDto, BargeCostingsService, PerformaCoalCostingService, CommercialCoalCostingService, BPFCostingRequestIdDto, ContractModesEnum, ExpenseEntryApprovalDto, ExpenseEntryService, AdvancePreview, VendorAdvService, AdvanceReceiversService, PaymentTypeEnum } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../common";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from 'lodash/debounce';
import { CostingFilters } from "../costing-detail-view/costing-filters";


export const AuditApprovals = () => {
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [paymentNoticeData, setPaymentNoticeData] = useState<PaymentNoticeGetDataDto[]>([]);
  const { authContext } = useAuthState();
  const paymentNoticeService = new PaymentNoticeService();
  const [formRef] = Form.useForm();
  const service = new BargeCostingsService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const expenseEntryService = new ExpenseEntryService()
  const advService = new VendorAdvService()
  const advReceivableService = new AdvanceReceiversService()
  const [type, setActiveTab] = useState<PaymentTypeEnum>(PaymentTypeEnum.PAYABLE)

  const [serachInput, setSerachInput] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [businessNo, setBusinessNo] = useState(null);
  const [costingType, setCostingType] = useState<CostingWorkLogsEnum>(null)
  const [bargeId, setBarge] = useState<string>(null)


  useEffect(() => {
    getParentData();
  }, [searchedText, type, currentPage, pageSize, businessNo, bargeId, costingType])


  const getParentData = () => {

    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.status = ContractStatusEnum.DRAFT;
    req.paymentType = type;
    req.page = currentPage;
    req.pageSize = pageSize;
    req.searchTerm = searchedText;
    req.businessNo = businessNo;
    req.bargeId = bargeId;
    req.costingType = costingType;
    paymentNoticeService.getPaymentNoticeAuditApprovals(req).then(res => {
      if (res.status) {
        setPaymentNoticeData(res.data?.paymentNotices ? res.data?.paymentNotices : []);
        setTotal(res.data?.total)
        setExpandedIndex([]);
      }
      else {
        setPaymentNoticeData([]);
      }
    }).catch(err => {
      console.log(err.message);
    })
  };

  useEffect(() => {

  }, [type])

  const routes = (key) => {
    let object = {
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: `performaCoalCosting`,
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: `performaCostingSale`,
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
      [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costings/barge`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
      [CostingWorkLogsEnum.DEMURRAGE]: `demurrage`,
      [CostingWorkLogsEnum.DEAD_FREIGHT]: `dead-freight`,
      [CostingWorkLogsEnum.ADVANCE_INVOICE]: `advanceDetailView`,
      [CostingWorkLogsEnum.EXPENSE_ENTRY]: 'expense-entry',
      [CostingWorkLogsEnum.ADVANCE_RECEIVABLES]: 'advance-receivables-detailed-view'
    };

    let id = "?costingId=";

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;


  };

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setSearchedText(searchValue);
      setCurrentPage(1);
    }, 500),
    []
  );


  // Handle search input change
  const handleSearch = (value: string) => {
    setSerachInput(value)
    debouncedSearch(value);
  };

  const handleBusinessNoChange = (value: string) => {
    setBusinessNo(value);
    setCurrentPage(1);
  };

  const handleCostingTypeChange = (value: CostingWorkLogsEnum) => {
    setCostingType(value);
    setCurrentPage(1);
  };

  const bargeChangeHandler = (value: string) => {
    setBarge(value);
    setCurrentPage(1);
  };

  const rejectedHandler = (costingType, costingId) => {
    let contractType;

    if (costingType == CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType == CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
      contractType = ContractModesEnum.PURCHASE
    }
    if (costingType == CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType == CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
      contractType = ContractModesEnum.SALES
    }
    if (costingType == CostingWorkLogsEnum.PROCUREMENT_FEES) {
      contractType = CostingWorkLogsEnum.PROCUREMENT_FEES
    }
    if (costingType == CostingWorkLogsEnum.MARKETING_FEES) {
      contractType = CostingWorkLogsEnum.MARKETING_FEES
    }

    if (costingType === CostingWorkLogsEnum.BARGE_COSTING || costingType === ReferenceFeatures.BARGE_COSTINGS) {
      bpfStatusRejected(costingId);
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
      perFormaReject(costingId, costingType, contractType);
    } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.MARKETING_FEES) {
      commercialReject(costingId, costingType, contractType);
    } else if (costingType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
      approveExpenses(costingId, ContractStatusEnum.CANCELLED)
    } else if (costingType === CostingWorkLogsEnum.ADVANCE_INVOICE) {
      advanceApproveOrReject(costingId, ContractStatusEnum.CANCELLED)
    }
    else if (costingType === CostingWorkLogsEnum.ADVANCE_RECEIVABLES) {
      advanceReceivableApproveOrReject(costingId, ContractStatusEnum.CANCELLED)
    }
  };

  const advanceReceivableApproveOrReject = (costingId: string, status: string) => {
    const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);

    advReceivableService.advanceReceivableApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        window.location.reload()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const advanceApproveOrReject = (costingId: string, status: string) => {
    const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);
    advService.advanceApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        window.location.reload();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const bpfStatusRejected = (costingId) => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingId, values['remarks'], ContractStatusEnum.CANCELLED, authContext.user.userName, authContext.defaultPlant)
      service.bpfStatusRejected(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
          // getBargeCostingParentData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err)
      })
    })

  }
  const perFormaReject = (costingId, costingType, contractType) => {
    formRef.validateFields().then(values => {
      console.log('perFormaReject', values)
      const req = new CmApprovalsReqDto(costingId, authContext.defaultPlant, authContext.user.userName, ContractStatusEnum.CANCELLED, costingType, contractType, values.remarks);
      perFormaService.performaReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      })
    })

  }

  const commercialReject = (costingId, costingType, contractType) => {
    formRef.validateFields().then(values => {
      const req = new CmApprovalsReqDto(costingId, authContext.defaultPlant, authContext.user.userName, ContractStatusEnum.CANCELLED, costingType, contractType, values.remarks);
      commercialService.commercialReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      });
    })

  }
  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req = new ExpenseEntryApprovalDto()
      req.expenseEntryId = expenseId
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = ContractStatusEnum.AUDIT_APPROVAL;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks']
      expenseEntryService.approveExpense(req).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)

        }
      }).catch((err) => {
        console.log('err : ', err)
      })
    })
  }



  const tableColumns: ColumnsType<any> = [
    {
      title: 'Expense Reference',
      dataIndex: 'costingNo',
      render: (value, record) => {
        return <>
          < a href={`/#/${routes(record.expenseType) + record.costingId}`} className="link" >
            {value}
          </a >
        </>
      }

    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },

    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (value, row: PaymentNoticeGetDataDto, index) => {
        const bnValues = [...new Map(row.paymentNoticeTransactions.map((rec) => [rec.businessNo, rec])).values()]
        return (
          <>
            {new Set(row.paymentNoticeTransactions.filter(rec => (rec.businessNo !== null || rec.businessNo != undefined)
            )).size ? bnValues.map((rec, index) => {
              const comma = index !== bnValues.length - 1 ? ' , ' : '';
              const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`
              return <>
                {<a key={rec.bnId} href={link} className="link">{rec.businessNo + ` (${rec.businessNoName})` + comma}</a>}
              </>
            }) : 'NA'}

          </>
        );
      }
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',

      render: (value, row: PaymentNoticeGetDataDto, index) => {
        const bg = [];
        row.paymentNoticeTransactions?.forEach((rec) => {
          if (rec.bargeNo)
            bg.push({ bargeNo: rec.bargeNo, bargeId: rec.bargeId, bargeNomination: rec.bargeNomination })
        });
        return (
          <>
            {new Set(row.paymentNoticeTransactions.filter(rec => (rec.bargeNo !== null || rec.bargeNo !== undefined)
            )).size ? bg.map((rec, index) => {
              const comma = index !== bg.length - 1 ? ' , ' : '';
              const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
              return <a key={rec?.bargeId} href={`${link}`} className="link">{SequenceUtils.formatNumberToSpecificLength(rec?.bargeNo) + ` (${rec.bargeNomination})` + comma}</a>
            }) : 'NA'}
          </>
        );
      }
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>{internationalFormattedValue(value, 3)}</Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>

        </>

      }
    }
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const renderItems = (record: any, index, indent, expanded) => {
    return <>
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }} >
        <AccountPayableCostings
          costingId={record.costingId}
          costingType={record.expenseType}
          remainingAmount={0}
        >

          <Card
            bordered={false}
            className="card-shadow mb-2"
            styles={{ body: { padding: '0px 10px 15px' } }}
          >
            <Form
              form={formRef}
              // initialValues={initialValues}
              layout='vertical'
              autoComplete='off'
            >


              <Row className='pt-2'>
                <Col span={16}>
                  <Form.Item
                    label='remarks'
                    name='remarks'
                    rules={[
                      {
                        required: true, message: 'Enter Remarks'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                      },
                    ]}
                  >
                    <Input.TextArea placeholder='Notes' onChange={() => { }} />
                  </Form.Item>
                </Col>
              </Row>
              <Flex align="middle" justify="flex-end" >
                <Button size="small" className="reject"
                  onClick={() => { rejectedHandler(record.expenseType, record.costingId) }}>

                  <FontAwesomeIcon icon={faCircleXmark} />Reject
                </Button>

              </Flex>
            </Form>
          </Card>
          <InvoiceDetails
            costingId={record.costingId}
            status={ContractStatusEnum.ACTIVE}
            level={ContractStatusEnum.AUDIT_APPROVAL}
            invoiceStatus={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
            includeFreight={record?.includeFreight}
            workLog={record.workLog}
          />

        </AccountPayableCostings>


      </div>

    </>
  };

  const tabListNoTitle = [
    {
      key: PaymentTypeEnum.PAYABLE,
      label: PaymentTypeEnum.PAYABLE,
    },
    {
      key: PaymentTypeEnum.RECEIVABLE,
      label: PaymentTypeEnum.RECEIVABLE,
    },
  ];

  const onTabChange = (key: PaymentTypeEnum) => {
    setActiveTab(key);
  };


  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  return (
    <Card>

      <Card
        tabList={tabListNoTitle}
        size="small"
        className='card-radius no-shadow '
        bordered={false}
        activeTabKey={type}
        onTabChange={onTabChange}
        tabProps={{
          size: 'small',
        }}
      >
        <CostingFilters

          handleSearch={handleSearch}
          serachInput={serachInput}
          handleBusinessNoChange={handleBusinessNoChange}
          businessNo={businessNo}
          handleCostingTypeChange={handleCostingTypeChange}
          costingType={costingType}
          bargeChangeHandler={bargeChangeHandler}
          bargeId={bargeId}
          paymentType={type}
        />

        <Table
          className='contracts-list'
          rowKey={record => record.costingId}
          columns={tableColumns}
          dataSource={paymentNoticeData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right'
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined
                onClick={(e) => onExpand(record, e)}
              >
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
            )
          }
          scroll={{ x: 500 }}
          size="small"
          bordered={false}

          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            size: 'small'
          }}
          onChange={handleTableChange}
        />
      </Card>
    </Card>
  )
}