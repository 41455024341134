import { DownCircleFilled, EditFilled, EditOutlined, FileExcelFilled, UpCircleFilled } from '@ant-design/icons';
import { BNoRequest, BusinessNumberService, PlantCodeReqDto } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Drawer, Form, Input, Popover, Row, Select, Table, Tooltip } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import { AddContract } from './add-contract-from';
import { BNCommercialPage } from './bn-commercial-page';
import { BusinessNumber } from './business-number';
import VesselData from './mother-vessel-page';
import SamplingAndAnalysis from './sampling-and-analysis';
import { ShipmentPlaning } from './shipment-planing';
import { render } from '@testing-library/react';

export const BusinessNumberGrid = () => {
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [barge, setBarge] = useState<boolean>(false);
  const [sampling, setSampling] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [shipmentDataFormRef] = Form.useForm();
  const [allocationForm, setAllocationForm] = useState<Boolean>(true)
  const [samplingFormRef] = Form.useForm();
  const [witnessAnalysis, setWitnessAnalysis] = useState<any>({ witnessAnalysis: [''] });
  const [bargeBusiness, setBargeBusinessNo] = useState<string>();
  const [motherVesselFormRef] = Form.useForm()
  const [allocationFormRef] = Form.useForm()
  const [dummyRefresh, setDummyRefresh] = useState(1);
  const [hatchData, setHatchData] = useState<any>()
  const [viewForm, setViewForm] = useState<boolean>(false);
  const [mappingSurveyors, setMappingSurveyors] = useState<any>();

  //let pathToreDirect = '/bn-detail-view'

  const service = new BusinessNumberService();

  const closeDrawer = () => {
    setDrawerVisible(false);
    motherVesselFormRef.resetFields();
    setDummyRefresh(prev => prev + 1);
  }

  useEffect(()=>{
    if(!isPermissionExist([254])) navigate('/')
  },[])

  useEffect(() => {
    getAllBusinessNumber();
    getShippingAnalysisData();
    getBargeMappingSurveyors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bargeBusiness]);


  const getShippingAnalysisData = () => {
    const req = new BNoRequest(bargeBusiness, authContext.defaultPlant)
    service.getShippingAnalysisData(req).then(res => {
      if (res.status) {
        setWitnessAnalysis(res.data)
      } else {
        setWitnessAnalysis({ witnessAnalysis: [' '] })
      }
    }).catch(err => {
      console.log(err.message);
    })
  }

  const getBargeMappingSurveyors = () => {
    const req = new BNoRequest(bargeBusiness, authContext.defaultPlant)
    service.getBargeMappingSurveyors(req).then(res => {
      if (res.status) {
        setMappingSurveyors(res.data)
      }
    }).catch(err => console.log(err.message))
  };



  const bargeBusinessNo = (businessNo) => {
    setBargeBusinessNo(businessNo)
  }


  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record.bnId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }

  const contractAllocation = () => {
    setContract(true);
    setDummyRefresh(prev => prev + 1);
  };



  const shippingPlaning = () => {
    setShipping(true);
    setDummyRefresh(prev => prev + 1);

  };


  const samplingAnalysis = () => {
    setSampling(true);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);

  };




  const businessOnClose = () => {
    setOpen(false);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);


  };

  const openFormWithData = () => {
    setDrawerVisible(true);
    setDummyRefresh(prev => prev + 1);

  }

  const allocationResetFields = () => {
    allocationFormRef.resetFields();

  }

  const contractOnClose = () => {
    setDummyRefresh(prev => prev + 1);
    setContract(false);
    setAllocationForm(true)
    allocationResetFields();
    getAllBusinessNumber();

  };


  const shippingOnClose = () => {
    setShipping(false);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);


  };

  const bargeOnClose = () => {
    setBarge(false);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);

  };

  const samplingOnClose = () => {
    setSampling(false);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);
    setViewForm(false);
  };

  const commercialDocumentsHandler = () => {
    setCommercial(true);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);

  }

  const commercialOnClose = () => {
    setCommercial(false);
    getAllBusinessNumber();
    setDummyRefresh(prev => prev + 1);


  };


  const onReset = () => {
    samplingFormRef.setFieldsValue(undefined);
    samplingFormRef.resetFields();
    // setSampling(false)
    setWitnessAnalysis(undefined);
    setWitnessAnalysis({ witnessAnalysis: [' '] });
    setDummyRefresh(prev => prev + 1);

  }

  const samplingAnalysisSaveData = () => {
    samplingFormRef.validateFields().then(values => {
      const data = { ...values, businessNo: bargeBusiness }
      service.samplingAnalysisCreate(data).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getShippingAnalysisData();
          onReset();
          setSampling(false);
          setViewForm(false)
          setDummyRefresh(prev => prev + 1);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    })
  }



  const getAllBusinessNumber = () => {
    service.getAllBusinessNumber(new PlantCodeReqDto(authContext.defaultPlant)).then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const bargeMapping = (businessNo: string) => {
    navigate('/bargeMapping-view', { state: { businessNo } });
  }

  const editClickHandler = (BusinessNo: string) => {
    const req = new BNoRequest(BusinessNo);
    service.getBNoPreviewData(req).then(res => {
      if (res.status) {
        navigate('/businessNumber-update', { state: res.data });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columns: any = [

    {
      title: fm({ id: 'mdm.components.businessNumberCreationForm.businessNo', defaultMessage: "Business No" }),
      dataIndex: 'businessNo',
      // width: 150,
      sorter: (a, b) => a.businessNo !== null ? a.businessNo.localeCompare(b.businessNo) : "Have Null Values",
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(Object.keys(record).map((key) => {
          return String(record[key]).toLowerCase().includes(value.toLocaleString());
        }))
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true))
          return true;
        else
          return false;
      },
      render: (text, record) => {
        const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
        return <><a href={link} className="link" >
          {record.businessNo}
        </a></>
      }
    },

    {
      title: "Laycan",
      dataIndex: 'laycan',
      // width: 225,
      onCell: (record: any) => ({
        style: { whiteSpace: 'nowrap' }
    }),
      // render: (value, record) => (
      //   <>
      //     {record?.loadingAnchorage?.map((la) => {
      //       return <span>
      //         {la?.laycan[0] && la?.laycan[1] ? moment(la.laycan[0]).format('DD-MMM-YYYY') + " to " + moment(la.laycan[1]).format('DD-MMM-YYYY') : ""}<br></br>
      //       </span>
      //     })}
      //   </>
      // )
      render: (value, record) => {
        const laycanList = record?.loadingAnchorage || [];
        const displayedLaycans = laycanList.slice(0, 3);
        const remainingLaycans = laycanList.slice(3);
      
        return (
          <>
            {displayedLaycans.map((la, index) => (
              <span key={index}>
                {la?.laycan[0] && la?.laycan[1] ? moment(la.laycan[0]).format('DD-MMM-YYYY') + " to " + moment(la.laycan[1]).format('DD-MMM-YYYY') : ""}
                {index < displayedLaycans.length - 1 ? <br /> : null}
              </span>
            ))}
      
            {laycanList.length > 3 && (
              <>
                ,&nbsp;
                <Popover
                  title={<span className='popover-title'>Laycan</span>}
                  placement="bottom"
                  content={
                    <div>
                      {remainingLaycans.map((la, index) => (
                        <div key={index}>
                          {la?.laycan[0] && la?.laycan[1] ? moment(la.laycan[0]).format('DD\u2011MMM\u2011YYYY') + " to " + moment(la.laycan[1]).format('DD\u2011MMM\u2011YYYY') : ""}
                        </div>
                      ))}
                    </div>
                  }
                >
                  <a className="link">+{remainingLaycans.length}</a>
                </Popover>
              </>
            )}
          </>
        );
      }
      
    },


    {
      title: "Business Type",
      dataIndex: 'businessType',

    },
    {
      title: fm({ id: 'mdm.components.businessNumberCreationForm.consignmentType', defaultMessage: "Consignment Type" }),
      dataIndex: 'consignmentType',

    },
    {
      title: fm({ id: 'mdm.components.businessNumberCreationForm.motherVesselName', defaultMessage: "Mother Vessel Name" }),
      dataIndex: 'motherVesselName',

    },
    {
      title: fm({ id: 'mdm.components.businessNumberCreationForm.ShipmenType', defaultMessage: "Shipment Type" }),
      dataIndex: 'shipmentType',

    },
    {
      title: "Stowage Plant (MT)",
      align: 'right',
      dataIndex: 'stowagePlanInMt',
      render: (text, record) => {
        return record.stowagePlanInMt ? Number(record.stowagePlanInMt).toFixed(3) : ''
      }

    },
    // {
    //   title: "Supply Tonnage (MT)",
    //   dataIndex: 'supplyTonnageInMt',

    // },
    {
      title: fm({ id: 'mdm.components.businessNumberCreationForm.status', defaultMessage: "Status" }),
      dataIndex: 'status',
      render: (text, record) => <>
        <StatusTag status={record.status} />
      </>,

    },

    // {
    //   title: 'Mapped PC Contract Number',
    //   dataIndex: 'pcContracts',
    //   render: (value, record) => <>
    //     {renderContractsData(value, redirectToPcContractsFullView)}

    //   </>,
    // },
    // {
    //   title: "Mapped SC Contract Number",
    //   dataIndex: "scContracts",
    //   render: (value, record) => <>
    //     {renderContractsData(value, redirectToScContractsFullView)}

    //   </>,
    // },
    {
      // title: "Action",
      // align: 'left',
      // fixed: 'right',
      // children: [
      //   {
          title: 'Action',
          dataIndex: 'action',
          // fixed: 'right',
          // width: 105,
          align: 'center',
          render: (text, rowData) => (
            <span>
              {isPermissionExist([255]) && (
              <EditFilled type="edit"
              className='action-icons'
                onClick={() => {
                  editClickHandler(rowData.bnId)
                }}
                // style={{ color: '#1890ff', fontSize: '20px' }}
              />
              )}
            </span>
          )
      //   },
      // ]
    }

  ];

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = columns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`BusinessNumbers-${currentDate}.xlsx`);
  };

  return (
    <>

    <Card
        title={<span className='card-title'>Business Number</span>}
        className='card-container'
        headStyle={{ border: 'none' }}
    >
      <Card
        className='contracts-list card-radius'
        title={<Row className='card-title-buttons'>
          <Input.Search placeholder="Search" allowClear className='input-search' onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} />
               </Row>}
        extra={isPermissionExist([253]) && (
          <Tooltip placement="topRight" title="Create">
            <Row className='mt-16'>
                <Button
                className='new-button'
                  size="small"
                  type="primary"
                  onClick={() => navigate('/businessnumberDetails')}
                >
                  Create
                </Button>
                </Row>
              </Tooltip>)}
              headStyle={{border: 'none'}}
               
      >
        <Table
          className='contracts-list'
          rowKey={record => record.bnId}
          columns={columns}
          dataSource={gridData}
          pagination={{
            showTotal: (total) => (
              <span 
              className='page-total-items'
              >Total {total} items</span>
            ),
            showSizeChanger: true, 
              pageSizeOptions: ['25', '50', '100'],
              defaultPageSize: 25,
            style: {
              paddingTop: '20px',
            },
          }}
          scroll={{ x: 500 }}
        />

      </Card>

    </Card>

      {/* <Card
        title={<span style={{ color: 'white' }}>Business Number Details</span>}
        style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
      >
        <Row justify='space-between'>
          <Col span={12}>
            <Row>

            </Row>
          </Col>
          <Col>
            <Row>
              <Col><Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} /></Col>
              <Col>
              {isPermissionExist([253]) && (
                <Tooltip placement="topRight" title="Create">
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => navigate('/businessnumberDetails')}
                  >
                    Create
                  </Button>
                </Tooltip>)}

              {isPermissionExist([259]) && (
                <Tooltip placement="topRight" title="Export As Excel">
                  <Button
                    disabled={gridData.length === 0}
                    size='small'
                    type="default"
                    className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                    onClick={handleExport}
                    icon={<FileExcelFilled />}
                  >Export</Button>
                </Tooltip>)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          className='contracts'
          rowKey={record => record.bnId}
          columns={columns}
          dataSource={gridData}
          expandable={{
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            expandedRowRender: record => <>
              <Row>
                <Col span={4}><Button type='primary' style={{ width: '100%' }} ghost onClick={shippingPlaning}>Shipment Planing</Button></Col>
                <Col span={4}><Button type='primary' style={{ width: '100%' }} ghost onClick={() => openFormWithData()}>Mother Vessel</Button></Col>
                {isPermissionExist([258]) && (<Col span={4}><Button type='primary' ghost onClick={() => { contractAllocation(); }} style={{ width: '100%' }}>Contract Allocation</Button></Col>)}
                <Col span={4}><Button type='primary' style={{ width: '100%' }} ghost onClick={() => { bargeMapping(record.businessNo = record.bnId); }}>Barge Mapping</Button></Col>
                <Col span={4}><Button type='primary' style={{ width: '100%' }} ghost onClick={samplingAnalysis}>Sampling and analysis</Button></Col>
                <Col span={4}><Button type='primary' style={{ width: '100%' }} ghost onClick={commercialDocumentsHandler}>Commercial Documents</Button></Col>
              </Row>
            </>,
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleFilled
                onClick={(e) => {
                  onExpand(record, e);
                }}
              >
                Collapse
              </UpCircleFilled>
            ) : (
              <DownCircleFilled onClick={(e) => {
                bargeBusinessNo(record.bnId);
                onExpand(record, e);
              }}>Expand</DownCircleFilled >
            )
          }
          pagination={{
            onChange(current) {
              setExpandedIndex([]);
            }
          }}
          // scroll={{ x: 500 }}
          size='small'
          bordered
        ></Table>


      </Card > */}
      {/* <Card> */}
        <Drawer
          title="Business Number"
          placement="right"
          onClose={businessOnClose}
          open={open}
          width={window.innerWidth > 768 ? '50%' : '85%'}>
          <BusinessNumber formRef={undefined} initialValues={undefined} layoutType={'vertical'} />
        </Drawer>

        <Drawer
          title="Contract Allocation"
          placement="right"
          onClose={contractOnClose}
          width={920}
          open={contract}>
          <br></br>
          <br></br>
          {<AddContract key={bargeBusiness + dummyRefresh} setDummyRefresh={setDummyRefresh} formRef={allocationFormRef} business={bargeBusiness} allocationForm={allocationForm} setAllocationForm={setAllocationForm} bargeBusiness={bargeBusiness} />}
        </Drawer>
        <Drawer
          title="Shipment Planing"
          placement="right"
          onClose={shippingOnClose}
          width={1000}
          open={shipping}
        // key={bargeBusiness}
        >

          <ShipmentPlaning setDummyRefresh={setDummyRefresh} key={`ShipmentPlanning${bargeBusiness} ${dummyRefresh}`} businessNo={bargeBusiness} formRef={shipmentDataFormRef} drawerState={setShipping} layoutType={'vertical'} setHatchData={setHatchData} />
        </Drawer>

        <Drawer
          title="Barge operation mapping"
          placement="right"
          onClose={bargeOnClose}
          // key={bargeBusiness}
          open={barge}
          width={window.innerWidth > 768 ? '50%' : '85%'}>
        </Drawer>


        <Drawer
          title="Sampling and analysis"
          placement="right"
          onClose={samplingOnClose}
          // width={window.innerWidth > 768 ? '50%' : '85%'}
          width={900}
          open={sampling}>

          <SamplingAndAnalysis setDummyRefresh={setDummyRefresh} key={`SamplingAndAnalysis${bargeBusiness} ${dummyRefresh}`} businessNo={bargeBusiness} formRef={samplingFormRef} initialValues={witnessAnalysis} layoutType={'vertical'} setViewForm={setViewForm} viewForm={viewForm} onResets={onReset} samplingAnalysisSaveData={samplingAnalysisSaveData} mappingSurveyors={mappingSurveyors} />

        </Drawer>

        <Drawer
          width={900}
          open={commercial}
          onClose={commercialOnClose}
          footer={undefined}
          bodyStyle={{ padding: 0 }}
        >
          <BNCommercialPage key={`Commercial${bargeBusiness} ${dummyRefresh}`} businessNo={bargeBusiness} cancelHandler={() => { commercialOnClose() }} submitHandler={() => { }} />
        </Drawer>

        <Drawer
          bodyStyle={{ paddingBottom: 80 }}
          width={950}
          onClose={closeDrawer}
          open={drawerVisible}
          closable={true}>
          <VesselData setDummyRefresh={setDummyRefresh} key={`MotherVessel${bargeBusiness} ${dummyRefresh}`} hatchData={hatchData} formRef={motherVesselFormRef} businessNo={bargeBusiness} closeDrawer={closeDrawer} />
        </Drawer>

      {/* </Card> */}
    </>
  )
}

export default BusinessNumberGrid;