import { UploadOutlined } from '@ant-design/icons';
import { AdvanceEnum, CostingInvoiceService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { getBase64 } from '@exportx/ui-utils';
import { Button, Col, Descriptions, Form, FormInstance, Input, Row, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../common';
import DatePicker from '../../common/data-picker/date-picker';
import ViewFileHandling from '../../common/file-upload-form/view-files-handling';


interface IProps {
  initialValues: any;
  dummyRefresh: number;
  formRef: FormInstance<any>;
  getAllCostingInvoice: () => void;
  setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
  setInvoiceFormDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  costingData: any;
  invoiceTotal: number;
  vendorAdvanceType?: any;
  vendorId?: string;
  contractNo?: string;
  advanceType?: AdvanceEnum
}

const AddCostingInvoicesForm = (props: IProps) => {
  const { advanceType, initialValues, dummyRefresh, formRef, getAllCostingInvoice, setDummyRefresh, setInvoiceFormDrawer, costingData, invoiceTotal, vendorAdvanceType, vendorId, contractNo } = props;
  const [fileList, setFileList] = useState<any>([]);
  const [saveButton, setSaveButton] = useState<boolean>(true);
  const { authContext } = useAuthState();
  const invoiceService = new CostingInvoiceService();
  const totalAmount = vendorAdvanceType ? Math.abs(costingData?.amount) : Math.abs(costingData?.totalAmount);



  const onFileChange = (data) => {
    setFileList([data.file]);
  };

  useEffect(() => {
    if (initialValues) {
      validateAmount(initialValues.invoiceAmount)
    }
  }, [initialValues]);

  useEffect(() => {
    formRef.setFieldValue('invoiceAmount', Math.abs(totalAmount - invoiceTotal));
    setSaveButton(false);
  }, [])


  useEffect(() => {
    if (costingData?.includeFreight) {
      formRef.setFieldValue('freight_amount', costingData?.freightAmt);
      formRef.setFieldValue('coal_amount', costingData?.coalAmount);
    }
  }, [costingData])

  const validateAmount = (e) => {
    const extraAmount: number = authContext.defaultPlantCurrency === "IDR" ? 250000 : 50;
    const totalOfInvoice = invoiceTotal + Number(e);
    console.log(Number(totalAmount) < totalOfInvoice, Number(totalAmount), totalOfInvoice)
    if ((Number(totalAmount) + Number(extraAmount)) < totalOfInvoice) {
      formRef.setFieldValue('invoiceAmount', undefined);
      setSaveButton(true)
      return AlertMessages.getErrorMessage(`Do Not Exceed Invoice Amount More Than To TotalAmount`);
      // ${Number(totalAmount) - (invoiceTotal + 50)}
    } else {
      setSaveButton(false);
    };
  };


  const createCostingInvoice = () => {
    formRef.validateFields().then(async values => {
      const formFilesDataFile = formRef.getFieldValue('filesData');
      const formFilesData = formFilesDataFile?.file;
      let filesData = [];
      if (formFilesData) {
        const base64 = await getBase64(formFilesData.originFileObj);
        filesData.push(
          {
            ...formFilesData,
            base64Url: base64,
            fileDescription: 'Invoice file',
            fileUploadId: initialValues?.filesData[0]?.fileUploadId
          }
        )
      };


      const costingId = vendorAdvanceType ? costingData?.id : costingData?.costingId;
      const referenceFeatures = costingData?.referenceFeatures
      const req = { ...values, filesData: filesData, plantCode: authContext.defaultPlant, userName: authContext.user.userName, referenceFeature: referenceFeatures, costingId: costingId, vendorId: vendorId, contractNo: contractNo, advanceType: advanceType }
      invoiceService.createCostingInvoice(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(prev => prev + 1);
          getAllCostingInvoice();
          setInvoiceFormDrawer(false);
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => console.log(err.message))
    })
  };

  const subTotalInvoiceHandler = () => {
    const freightAmt = formRef.getFieldValue('freight_amount') ?? 0;
    const coalAmt = formRef.getFieldValue('coal_amount') ?? 0;
    const totalInvoiceAmt = Number(coalAmt ?? 0) + Number(freightAmt ?? 0);
    console.log('totalInvoiceAmt =>', totalInvoiceAmt)
    formRef.setFieldValue('invoiceAmount', totalInvoiceAmt)
    validateAmount(totalInvoiceAmt)

  }

  const onChangeFreightPercentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100 || Number(e.target.value) === 0) {
      formRef.setFieldValue('freight_percent', undefined);
      formRef.setFieldValue('freight_amount', undefined);
    }
    else {
      const percentageValue = Number(costingData?.freightAmt ?? 0) * Number(e.target.value);
      formRef.setFieldValue('freight_amount', Number(Number(percentageValue / 100).toFixed(2)));
    }
    subTotalInvoiceHandler()

  }

  const onChangeFreightAmountHandler = (value) => {
    const newValue = Number(value);
    const freightAmt = Number(costingData?.freightAmt ?? 0);

    if (newValue <= freightAmt) {
      formRef.setFieldValue('freight_amount', newValue);
    } else {
      formRef.setFieldValue('freight_amount', freightAmt);
    }

    subTotalInvoiceHandler();
  }

  const onChangeCoalAmountHandler = (value) => {
    const newValue = Number(value);
    const coalAmount = Number(costingData?.coalAmount ?? 0);

    if (newValue <= coalAmount) {
      formRef.setFieldValue('coal_amount', newValue);
    } else {
      formRef.setFieldValue('coal_amount', coalAmount);
    }

    subTotalInvoiceHandler();
  }

  const onChangeCoalPercentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100 || Number(e.target.value) === 0) {
      formRef.setFieldValue('coal_percent', undefined);
      formRef.setFieldValue('coal_amount', undefined);
    }
    else {
      const percentageValue = Number(costingData?.coalAmount ?? 0) * Number(e.target.value);
      formRef.setFieldValue('coal_amount', Number(Number(percentageValue / 100).toFixed(2)));
    }
    subTotalInvoiceHandler()

  }

  const invoicePercentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100 || Number(e.target.value) === 0) {
      formRef.setFieldValue('invoicePercent', undefined);
      formRef.setFieldValue('invoiceAmount', undefined);
      setSaveButton(true);
    } else {
      const percentageValue = Number(totalAmount) * Number(e.target.value);
      formRef.setFieldValue('invoiceAmount', Number(Number(percentageValue / 100).toFixed(2)));
      validateAmount(formRef.getFieldValue('invoiceAmount'));
    };

  }


  return <>

    <Form autoComplete='off' layout="vertical" initialValues={initialValues} key={dummyRefresh + 1} form={formRef}>

      {costingData?.includeFreight &&
        <Row>
          <Descriptions
            className='descriptions'
            layout='vertical'
            size='small'
            colon={true}
          >
            <Descriptions.Item label={"Coal Amount"}>
              {costingData?.coalAmount}
            </Descriptions.Item>
            <Descriptions.Item label={"Freight Amount"}>
              {costingData?.freightAmt}
            </Descriptions.Item>
            <Descriptions.Item label={"Total Amount"}>
              {costingData?.amount}
            </Descriptions.Item>

          </Descriptions>
        </Row>
      }
      <Row>

        <Col xs={{ span: 24, }} lg={{ span: 10, offset: 0 }}>
          <Form.Item
            label=" Invoice Date"
            name="invoiceDate"
            rules={[{ required: true, },]}>
            <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
          </Form.Item></Col>


        <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
          <Form.Item label="Invoice No" name="invoiceNo" rules={[{ required: true, },]} >
            <Input placeholder="INVOICE NO" type="text" style={{ width: "100%" }} />
          </Form.Item></Col>

      </Row>
      {costingData?.includeFreight &&

        <>

          <Row>
            <Col xs={{ span: 24, }} lg={{ span: 10, offset: 0 }}>
              <Form.Item label="Freight % " name="freight_percent" >
                <Input placeholder="PERCENT" onChange={onChangeFreightPercentHandler} type="number" style={{ width: "100%" }} />
              </Form.Item></Col>



            <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
              <Form.Item label="Freight Amount" name="freight_amount" rules={[{ required: true }]}>
                <Input
                  placeholder="Freight AMOUNT"
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFreightAmountHandler(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24, }} lg={{ span: 10, offset: 0 }}>
              <Form.Item label="Coal % " name="coal_percent" >
                <Input placeholder="PERCENT" onChange={onChangeCoalPercentHandler} type="number" style={{ width: "100%" }} />
              </Form.Item></Col>



            <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
              <Form.Item label="Coal Amount" name="coal_amount" rules={[{ required: true, },]} >
                <Input
                  placeholder="Coal AMOUNT"
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCoalAmountHandler(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Form.Item></Col>
          </Row>
        </>

      }
      <Row>
        {!costingData?.includeFreight &&
          <Col xs={{ span: 24, }} lg={{ span: 10, offset: 0 }}>
            <Form.Item label="Invoice % " name="invoicePercent" >
              <Input placeholder="PERCENT" onChange={invoicePercentHandler} type="number" style={{ width: "100%" }} />
            </Form.Item></Col>
        }



        <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
          <Form.Item label="Invoice Amount" name="invoiceAmount" rules={[{ required: true, },]} >
            <Input
              placeholder="INVOICE AMOUNT"
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateAmount(e.target.value)}
              defaultValue={totalAmount}
              disabled={costingData?.includeFreight}
              style={{ width: "100%" }}
            />
          </Form.Item></Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{
              required: true,

            }]}
          >
            <Input placeholder="REMARKS" type="text" style={{ width: "90%" }} />
          </Form.Item></Col>
        <Col  >
          <Form.Item name='filesData' label="Upload The Invoice"  >
            <Upload
              multiple={false}
              fileList={fileList}
              customRequest={onFileChange}
              onRemove={() => setFileList([])}
              showUploadList={true}
              listType='text'
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Choose File</Button>
            </Upload>

          </Form.Item>
          <ViewFileHandling filesData={initialValues?.filesData} required={false} />
        </Col>
      </Row>

      <Row>
        <Col offset={18}>
          <Button type='primary' disabled={saveButton} onClick={createCostingInvoice}>Save</Button>

        </Col>
      </Row>
      <Form.Item name={'invoiceId'} hidden={true}>
        <Input></Input>
      </Form.Item>
    </Form>

  </>

}

export default AddCostingInvoicesForm