import { Button, Card, Checkbox, Col, Drawer, Form, FormInstance, Input, InputNumber, Modal, Radio, Row, Select, Space, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { Specifications } from './specs/Specifications';
import { useIntl } from 'react-intl';
import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, ShippingTermsDropDownDto, ShippingTermsService, ContractTermEnum, ColaIndexTypeEnum, ContractModesEnum, AnchorageService, QualityPriceDataDto, SpecificationsEnum, DestinationService, FcChargesEnum } from '@exportx/shared-models-and-services';
import { FormLayout } from 'antd/lib/form/Form';
import './add-quality.css';
import { IndexedPriceCalculationForm } from '../../masters/components/coal-index-prices';
import moment from 'moment';
import { ClassValidator } from '@exportx/ui-utils';
import DatePicker from '../../../common/data-picker/date-picker';
import { NamePath } from 'antd/es/form/interface';
import { useAuthState } from '../../../common';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
interface IAddQualityProps {
  contractType: ContractModesEnum;
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  purchaseTermHandler: (qualityKey: number, selectedPurchasingTerm: ContractTermEnum, purchaseTermDataClone?: any, specSpecification?: any, qualityAdjustments?: any, noPriceAdjustment?: any) => void;
  purchaseTermFormSubmitHandler: (formValues: any) => void;
  handleIsIndexedButton: (isIndexChecked: boolean, purchaseTypeKey: string) => void;
  handlePurchaseType: (shippingTerm: string, purchaseTypeKey: string) => void;
  indexFormCancelHandler: () => void;
  indexFormSubmitButtonHandler: (indexedFormValues: any) => void;
  activeIndexForm: any;
  purchaseTypeData: any;
  activeQualityKeyTerm: any;
  purchaseTermData: any;
  setPurchaseTermData: React.Dispatch<React.SetStateAction<{}>>;
  setPurchaseTypeData: React.Dispatch<React.SetStateAction<{}>>
  activeIndexGrade: number;
}
export const AddQuality = (props: IAddQualityProps) => {
  const { formatMessage: fm } = useIntl();
  const { formRef, layoutType, purchaseTermHandler, purchaseTermFormSubmitHandler, handleIsIndexedButton, handlePurchaseType, indexFormCancelHandler, indexFormSubmitButtonHandler, activeIndexForm, purchaseTypeData, activeQualityKeyTerm, purchaseTermData, setPurchaseTermData, setPurchaseTypeData, activeIndexGrade, contractType } = props;
  const [mineData, setMineData] = useState<BPDropDownDto[]>([]);
  const [shippingTerms, setShippingTerms] = useState<ShippingTermsDropDownDto[]>([]);
  const [anchorages, setAnchorages] = useState([]);
  const [initialValues, setInitialValues] = useState<{ qualityData: QualityPriceDataDto[] }>();
  const classValidator = new ClassValidator();
  const [destination, setDestination] = useState<any[]>()
  const [specification, setSpecification] = useState<SpecificationsEnum>()
  const pathToPurchase = contractType === ContractModesEnum.PURCHASE ? 'mdm.components.purchaseTypesForm.' : 'mdm.components.salesTypesForm.';
  const { authContext } = useAuthState()
  const [resetFields, setResetFields] = useState(true)

  const businessPartnerService = new BusinessPartnerService();
  const shippingTermsService = new ShippingTermsService();
  const anchorageService = new AnchorageService();
  const destinationService = new DestinationService()

  useEffect(() => {
    getAllMinesDropDown();
    getAllShippingTerms();
    getAllAnchorages();
    destinationDropDown();
  }, []);
  const getSpecStandardToBeShow = (qualityAdjustments: any, noPriceAdjustment: any) => {
    return `${qualityAdjustments} ${noPriceAdjustment ? ' - No Price Adjustment' : ''}`;
  }

  useEffect(() => {
    setSpecification(props.initialValues?.qualityData[0]?.qualitySpecifications)
    if (props.initialValues?.qualityData[0]?.qualityId) {
      let valesToSet = { ...props.initialValues };
      props?.initialValues?.qualityData.forEach((item, index) => {
        if (!Array.isArray(item?.layCan)) {
          let layCan = item?.layCan.split(',');
          valesToSet.qualityData[index].layCan = [moment(layCan[0]), moment(layCan[1])];
        }
        valesToSet.qualityData[index].specStandardToBeShow = getSpecStandardToBeShow(item?.specStandard, item?.noPriceAdjustment)
      });
      setInitialValues(valesToSet);
    } else {
      setInitialValues(props.initialValues);
    }
  }, [props.initialValues]);


  useEffect(() => {
    if(resetFields)
    formRef.resetFields();
  }, [initialValues, resetFields])

  const destinationDropDown = () => {
    destinationService.getDestinationDropDown().then(res => {
      if (res.status) {
        setDestination(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }


  const getAllMinesDropDown = () => {
    businessPartnerService.getAllMinesDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
      if (res.status) {
        setMineData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllAnchorages = () => {
    anchorageService.getAllAnchorages().then(res => {
      if (res.status) {
        setAnchorages(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllShippingTerms = () => {
    shippingTermsService.getAllShippingTermsDropDown().then(res => {
      if (res.status) {
        setShippingTerms(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  };

  const suffixSelector = (path: number, name: string) => (
    <Form.Item name={[path, name]} noStyle label="Please Select">
      <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} onChange={(value) => setSpecification(value)} allowClear showSearch style={{ width: 70 }}>
        {Object.keys(SpecificationsEnum).map((rec) => {
          return <Option value={SpecificationsEnum[rec]}>{SpecificationsEnum[rec]}</Option>
        })}
      </Select>
    </Form.Item>
  );

  const returnDeleteIcon = (quantityDelivered: number, quantityAllocated: number) => {
    if ((!Number.isNaN(quantityDelivered)) && (!Number.isNaN(quantityAllocated))) {
      return quantityDelivered <= 0 && quantityAllocated <= 0
    } else {
      return true;
    }
  }

  const setValues = (name: NamePath, value: string, key: string, valueKey: string, values: any[]) => {
    if (value) {
      const labelToBeSet = values.find(el => el[key] === value);
      formRef.setFieldValue(name, labelToBeSet[valueKey]);
    }
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        preserve={false}
        autoComplete="off">
        <Form.List name="qualityData">
          {(qualityFields, { add: addQuality, remove: removeQuality }) => (<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {qualityFields.map((qualityField, qualityIndex) => (
              <Row>
                <Col
                  xs={{ span: 23 }}
                  sm={{ span: 23 }}
                  md={{ span: 23 }}
                  lg={{ span: 23 }}
                  xl={{ span: 23 }}>
                  <Card>
                    <Row>

                      <Form.Item name={[qualityField.name, "qualityId"]} style={{ display: 'none' }}>
                        <Input hidden />
                      </Form.Item>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} >
                        <Form.Item
                          label={fm({ id: `mdm.components.addQualityForm.layCan`, defaultMessage: "Laycan" })}
                          name={[qualityField.name, "layCan"]}
                          rules={[
                            {
                              required: true, message: fm({ id: `mdm.components.addQualityForm.layCanReq`, defaultMessage: "Please select Laycan" })
                            }
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                        <Form.Item
                          name={[qualityField.name, "quality"]}
                          label={fm({ id: `mdm.components.addQualityForm.quality`, defaultMessage: "Quality" })}
                          rules={[
                            {
                              required: true, message: fm({ id: `mdm.components.addQualityForm.qualityReq` })
                            },
                            {
                              validator: (_, value) => {
                                if (value && value.toString().length === 4) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error("Please enter valid Quality"));
                              },
                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }

                          ]}
                        >
                          <InputNumber addonAfter={suffixSelector(qualityField.name, 'qualitySpecifications')}  maxLength={4} minLength={4} placeholder={fm({ id: `mdm.components.addQualityForm.qualityReq`, defaultMessage: " Please  Enter Quality " })} onKeyPress={(e) => {
                            const charCode = e.charCode || e.keyCode;
                            const charStr = String.fromCharCode(charCode);

                            if (!/^[0-9.]$/.test(charStr) || (charStr === '.' && e.currentTarget.value.includes('.'))) {
                              e.preventDefault();
                            }
                          }} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: `mdm.components.addQualityForm.quantity`, defaultMessage: "Quantity" })}
                          name={[qualityField.name, "quantity"]}
                          rules={[
                            {
                              required: true, message: fm({ id: `mdm.components.addQualityForm.quantityReq` })
                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }

                          ]}
                        >
                          <InputNumber placeholder={fm({ id: `mdm.components.addQualityForm.quantityReq`, defaultMessage: " Please Enter Quantity" })} style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: `mdm.components.addQualityForm.tolerance`, defaultMessage: "Tolerance %" })}
                          name={[qualityField.name, "tolerance"]}
                          rules={[
                            {
                              required: true, message: fm({ id: `mdm.components.addQualityForm.tolerenceReq` })
                            },

                            {
                              pattern: /^(0\.5|[1-9](\.\d+)?|1[0-4](\.\d+)?|15)$/,
                              message: `Please enter a decimal number between 0.5 and 15`,
                            }
                          ]}
                        >
                          <InputNumber placeholder={fm({ id: `mdm.components.addQualityForm.tolerenceReq`, defaultMessage: " Please Enter Tolerance" })} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }} >
                        <Form.Item name={[qualityField.name, "mineNameDesc"]} style={{ display: 'none' }}>
                          <Input hidden />
                        </Form.Item>
                        <Form.Item
                          name={[qualityField.name, "mineName"]}
                          label={fm({ id: `mdm.components.addQualityForm.mineName`, defaultMessage: "Mines Name" })}
                        >
                          <Select
                            onChange={(value) => setValues(['qualityData', qualityField.name, "mineNameDesc"], value, 'bpId', 'bpName', mineData)}
                            allowClear showSearch filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} placeholder={fm({ id: `mdm.components.addQualityForm.mineNameReq`, defaultMessage: "Please Select MineName" })} >
                            {/* <Option value={''}>Please Select</Option> */}
                            {mineData.map(item => {
                              return <Option value={item.bpId}>{item.bpName}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <h3>{fm({ id: `${pathToPurchase}purchaseType`, defaultMessage: `Purchase Types` })}</h3>
                    <Form.List name={[qualityField.name, "purchaseTypeData"]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Row>
                              <Col
                                xs={{ span: 22 }}
                                sm={{ span: 22 }}
                                md={{ span: 22 }}
                                lg={{ span: 22 }}
                                xl={{ span: 22 }}>
                                <div className='contractTypeBlock'>
                                  <Form.Item name={[name, "typeId"]} style={{ display: 'none' }}  >
                                    <Input hidden />
                                  </Form.Item>
                                  <Form.Item name={[name, "initialPricePMT"]} style={{ display: 'none' }}  >
                                    <Input hidden />
                                  </Form.Item>
                                  <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                                      <Form.Item name={[name, "purchaseType"]} label={fm({ id: `${pathToPurchase}purchaseType`, defaultMessage: "Purchase Type" })}
                                        rules={[
                                          {
                                            required: true, message: fm({ id: `${pathToPurchase}purchaseTypeReq` })
                                          },
                                        ]}
                                      >
                                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: `${pathToPurchase}purchaseTypeReq`, defaultMessage: "Select Purchase Type" })} onChange={(shippingTerm) => {
                                          handlePurchaseType(shippingTerm, `${qualityField.name}$&${key}`)
                                        }}>
                                          <Option value={''}>Please Select</Option>
                                          {shippingTerms.map(item => {
                                            return <Option value={item.shipmenttermName}>{item.shipmenttermName}</Option>
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    {purchaseTypeData[`${qualityField.name}$&${key}`]?.purchaseType === "FOB BARGE" && <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                      <Form.Item name={[name, "jettyName"]} label={fm({ id: `${pathToPurchase}jettyName`, defaultMessage: "Jetty Name" })} >
                                        <Input placeholder={fm({ id: `${pathToPurchase}jettyNameReq`, defaultMessage: "Please Enter Jetty" })} />
                                      </Form.Item >
                                    </Col>}
                                    {(purchaseTypeData[`${qualityField.name}$&${key}`]?.purchaseType !== "FOB BARGE") && <><Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                      <Form.Item name={[name, "anchorageName"]} label={fm({ id: `${pathToPurchase}anchorageName`, defaultMessage: "Loading Port" })}>

                                        {/* <Input placeholder={fm({ id: `${pathToPurchase}anchorageNameReq`, defaultMessage: "Please Enter Anchorage Name" })} /> */}
                                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}>
                                          <Option value={''}>Please Select</Option>
                                          {
                                            anchorages.map(rec => {
                                              return <Option value={rec.anchorageId}>{rec.anchorageName}</Option>
                                            })
                                          }
                                        </Select>
                                      </Form.Item >
                                    </Col>
                                    </>
                                    }
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                      <Form.Item name={[name, "fcCharges"]} label={fm({ id: `${pathToPurchase}FcCharges`, defaultMessage: "FC Charges" })}
                                        rules={[
                                          {
                                            required: false, message: 'Please Enter Fright Charges'
                                          },
                                          {
                                            pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                                            message: `Only Number & Decimals`
                                          }
                                        ]}
                                      >
                                        <Input placeholder={fm({ id: `${pathToPurchase}FcChargesReq`, defaultMessage: "Please Enter FC Charges" })} style={{ width: '180px' }} />

                                      </Form.Item >
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                                      <Form.Item name={[name, "isIndexLinked"]} label={fm({ id: `${pathToPurchase}switch`, defaultMessage: "Index Linked" })} style={{ width: "120px" }}
                                        valuePropName="checked" initialValue={purchaseTypeData[`${qualityField.name}$&${key}`]?.isIndexed ? true : false}>
                                        <Switch onChange={(isIndexChecked) => handleIsIndexedButton(isIndexChecked, `${qualityField.name}$&${key}`)} checked={purchaseTypeData[`${qualityField.name}$&${key}`]?.isIndexed ? true : false} />
                                      </Form.Item>
                                    </Col>
                                    {['FAS ANCHORAGE'].includes(purchaseTypeData[`${qualityField.name}$&${key}`]?.purchaseType) ? <>
                                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                        <Form.Item name={[name, 'finalQuantityFactor']} label={'Final Quantity Factor'}>
                                          <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={"Select Final Quantity Factor"}>
                                            {Object.keys(FcChargesEnum).map((rec) => {
                                              return <Option value={FcChargesEnum[rec]}>{FcChargesEnum[rec]}</Option>
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </Col>


                                    </> : null
                                    }
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                                      <Form.Item
                                        name={[name, "coalPricePMT"]}
                                        {...restField}
                                        label={fm({ id: `${pathToPurchase}coalPrice PMT`, defaultMessage: "Coal Price (PMT)" })}
                                        rules={[
                                          {
                                            required: purchaseTypeData[`${qualityField.name}$&${key}`]?.isIndexed ? false : true, message: fm({ id: `${pathToPurchase}coalPricePMTReq` })
                                          }
                                        ]}
                                      >
                                        {purchaseTypeData[`${qualityField.name}$&${key}`]?.isIndexed ? (<p>{purchaseTypeData[`${qualityField.name}$&${key}`]?.data?.['finalCoalBasePricePMT'] ? purchaseTypeData[`${qualityField.name}$&${key}`]?.data['finalCoalBasePricePMT'] : 0}</p>) : <InputNumber placeholder={fm({ id: `${pathToPurchase}coalPricePMTReq`, defaultMessage: "Coal Price PMT" })} style={{ width: '100%' }} />}
                                      </Form.Item >
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                                      <Form.Item name={[name, "vat"]} label={fm({ id: `${pathToPurchase}vat`, defaultMessage: "VAT" })} valuePropName="checked" initialValue={false}>
                                        <Checkbox disabled={authContext.defaultPlantCurrency === "USD" ? true : false}>Including VAT</Checkbox>
                                      </Form.Item >
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                      <Form.Item name={[name, "freightPricePMT"]} label={fm({ id: 'mdm.components.purchaseTypesForm.freightPricePMT', defaultMessage: "Freight Price (PMT)" })}
                                        rules={[
                                          {
                                            required: false, message: 'Please Freight Price PMT'
                                          }
                                        ]} >
                                        <InputNumber placeholder={fm({ id: `${pathToPurchase}freightPricePMTReq`, defaultMessage: " Freight Price PMT" })} />
                                      </Form.Item >
                                    </Col>
                                    {['CFR DP', 'CIF DP'].includes(purchaseTypeData[`${qualityField.name}$&${key}`]?.purchaseType) && <>

                                      <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                        <Form.Item name={[name, "dischargePort"]} label={fm({ id: `${pathToPurchase}dischargePort`, defaultMessage: " Discharge Port" })} style={{ marginRight: '12px' }}>
                                          {/* <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: `${pathToPurchase}dischargePortReq`, defaultMessage: "Enter Discharge Port" })}>
                                            {destination?.map((rec) => {
                                              return <Option value={rec.destinationId}>{rec.destinationName}</Option>
                                            })}
                                          </Select> */}
                                          <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}>
                                            <Option value={''}>Please Select</Option>
                                            {
                                              anchorages.map(rec => {
                                                return <Option value={rec.anchorageId}>{rec.anchorageName}</Option>
                                              })
                                            }
                                          </Select>
                                        </Form.Item >
                                      </Col>
                                    </>
                                    }
                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                                      <Form.Item name={[name, "exportDocumentation"]}
                                        label={fm({ id: `${pathToPurchase}exportDocumentation`, defaultMessage: "Export Documentation" })} style={{ marginRight: "12px" }} initialValue={'Including'}>
                                        <Radio.Group>
                                          <Radio value={'Including'}>Including</Radio>
                                          <Radio value={'Excluding'}>Excluding</Radio>
                                        </Radio.Group>
                                      </Form.Item >
                                    </Col>

                                  </Row>
                                </div>
                              </Col>
                              {fields?.length > 1 && <Col xs={{ span: 2 }}
                                sm={{ span: 2 }}
                                md={{ span: 2 }}
                                lg={{ span: 2 }}
                                xl={{ span: 2 }}>
                                <span style={{
                                  position: "absolute",
                                  marginTop: "33px",
                                  marginLeft: "25px",
                                }}>
                                  <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                    <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                      remove(name);
                                      setPurchaseTermData(prevState => {
                                        const newState = { ...prevState };
                                        delete newState[`${qualityField.name}$&${key}`];
                                        return newState;
                                      });
                                    }} />
                                  </Tooltip>
                                </span>
                              </Col>
                              }
                            </Row>
                          ))}

                          <Form.Item >
                            <Button type="dashed" onClick={() => add()}>
                              Add {fm({ id: `${pathToPurchase}purchaseType`, defaultMessage: `Purchase Types` })}
                            </Button>
                          </Form.Item>
                        </>)}
                    </Form.List>
                    <Form.Item
                      name={[qualityField.name, "purchasingTerm"]}
                      label={fm({ id: `${pathToPurchase}purchasingTerm`, defaultMessage: 'Purchase Term' })}
                      rules={[
                        {
                          required: true, message: fm({ id: `${pathToPurchase}purchasingterm`, defaultMessage: "Please Select Purchase Terms" })
                        }
                      ]}
                    >
                      <Radio.Group buttonStyle="solid" onChange={(e) => { purchaseTermHandler(qualityField.name, e.target.value) }}>
                        {Object.keys(ContractTermEnum).map(contractTermKey => {
                          return <Radio.Button value={ContractTermEnum[contractTermKey]} onClick={() => purchaseTermHandler(qualityField.name, ContractTermEnum[contractTermKey])}>{ContractTermEnum[contractTermKey]}</Radio.Button>
                        })}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name={[qualityField.name, "specStandardToBeShow"]} >
                      <Input disabled={true} />
                    </Form.Item >

                    <Form.Item name={[qualityField.name, "specStandard"]} style={{ display: 'none' }}>
                      <Input hidden />
                    </Form.Item >

                    <Form.Item name={[qualityField.name, "qualityAdjustments"]} >
                      <TextArea rows={5} disabled={true} />
                    </Form.Item >


                    <Form.Item name={[qualityField.name, "noPriceAdjustment"]} style={{ display: 'none' }}>
                      <Input hidden />
                    </Form.Item>

                  </Card>
                </Col>
                {(qualityFields?.length > 1 && returnDeleteIcon(Number(initialValues.qualityData?.[qualityIndex]?.['quantityDelivered']), Number(initialValues.qualityData?.[qualityIndex]?.['quantityAllocated']))) && <Col xs={{ span: 2 }}
                  sm={{ span: 1 }}
                  md={{ span: 1 }}
                  lg={{ span: 1 }}
                  xl={{ span: 1 }}>
                  <span style={{
                    position: "absolute",
                    marginTop: "33px",
                    marginLeft: "25px",
                  }}>
                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                      <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                        removeQuality(qualityField.name);
                        console.log('qualityId', qualityField.name['qualityId'])
                        if (initialValues?.qualityData?.length > qualityIndex) {
                          setInitialValues({ qualityData: initialValues?.qualityData?.splice(qualityIndex, 1) })
                          setResetFields(false)

                        }
                        setPurchaseTypeData(prevState => {
                          const newState = { ...prevState };
                          delete newState[`${qualityField.name}`];
                          return newState;
                        });
                      }} />
                    </Tooltip>
                  </span>
                </Col>
                }
              </Row>
            ))}
            <Form.Item >
              <Button type="dashed" onClick={() => addQuality({ purchaseTypeData: [''] })}>
                Add Quality
              </Button>
            </Form.Item>
          </Space>)}
        </Form.List>
        <Modal
          title=' '
          style={{ top: 0 }}
          width={780}
          bodyStyle={{ padding: 0 }}
          open={(activeQualityKeyTerm === 0 || activeQualityKeyTerm) ? true : false}
          footer={null}
          key={activeQualityKeyTerm}
          closable={false}
        >
          <Specifications astmData={purchaseTermData[activeQualityKeyTerm]?.purchaseTermData?.astmData} isoData={purchaseTermData[activeQualityKeyTerm]?.purchaseTermData?.isoData} noPriceAdjustment={purchaseTermData[activeQualityKeyTerm]?.noPriceAdjustment} specSpecification={purchaseTermData[activeQualityKeyTerm]?.specSpecification} purchaseTermFormSubmitHandler={purchaseTermFormSubmitHandler} purchasingTerm={purchaseTermData[activeQualityKeyTerm]?.purchaseTerm} />
        </Modal>

        <Drawer
          width={720}
          open={activeIndexForm ? true : false}
          key={activeIndexForm}
          closable={false}
          footer={undefined}
          bodyStyle={{ padding: 0 }}
        >
          <IndexedPriceCalculationForm grade={activeIndexGrade} coalIndexType={ColaIndexTypeEnum.WEEKLY} cancelHandler={indexFormCancelHandler} indexFormSubmitButtonHandler={indexFormSubmitButtonHandler} initialValues={purchaseTypeData[activeIndexForm]?.data} specification={specification} />
        </Drawer>
      </Form>
      <div style={{ paddingBottom: '10px' }}>
        {props?.children}
      </div>
    </>
  )
}

export default AddQuality;



