import { VesselAgreementService, ContractStatusEnum, PlantCodeReqDto, BargeAgreementDto, VesselAgreementDto, PreviewRequests, VesselAgreementPreviewDto } from "@exportx/shared-models-and-services";
import { Button, Card, Divider, Form, Input, Row, Select, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, StatusTag, useAuthState } from "../../../../common";
import moment from 'moment';
import { EditFilled, FileExcelFilled } from '@ant-design/icons';
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { Excel } from "antd-table-saveas-excel";
import { Option } from "antd/es/mentions";




export const VesselAgreementGrid = () => {
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState<any>([]);
  const navigate = useNavigate();
  const service = new VesselAgreementService();
  let pathToreDirect = '/vesselAgreement-detailed-view'
  const [searchData, setSearchData] = useState("")
  const { authContext } = useAuthState()
  const { state }: any = useLocation();
  const { baId } = state ? state : { baId: null };
  const [status, setStatus] = useState<any>('');

  useEffect(() => {
    getAllVesselAgreementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllVesselAgreementData = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant);

    service.getAllBargeAgreement(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const approveVesselAgreement = (id) => {
    service.sendForApproval({ baId: id, plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        getAllVesselAgreementData();
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status);
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllVesselAgreementData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
};

// Filter data based on selected status
const filteredData = status ? gridData.filter((item) => item.status === status) : gridData;




  const tableColumns: any = [
    {
      title: 'Fixture Note',
      dataIndex: 'contractNo',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.contractNo.localeCompare(b.contractNo),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendor)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, record) => {
        const link = `/#/vesselAgreement-detailed-view?va_Id=${record.agreementId}`;
        return <>
          <a href={link} className="link" >
            {value}
          </a></>
      }


    },
    {
      title: 'Agreement Date',
      dataIndex: 'contractDate',
      width: 125,
      align: 'left',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('DD\u2011MMM\u2011YYYY') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Barge Vendor',
      dataIndex: 'vendor',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => 
        <span className='table-name-column'>{record?.vendor}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <>
        <StatusTag status={record.status} />
      </>,
    },
    {
      title: 'Action',
      className: 'operations',
      width: 130,
      align: 'Center',

      render: (text, rowData) => (
        <span>
          <EditFilled
            className='action-icons'
            type="edit"
            onClick={() => {
              editClickHandler(rowData.agreementId);
            }}
            // style={{ color: '#1890ff', fontSize: '16px', display: `${rowData.status !== ContractStatusEnum.ACTIVE ? "" : 'none'}` }}
          />
          {/* <Divider type="vertical"></Divider>&nbsp;&nbsp;
          {rowData.status === ContractStatusEnum.DRAFT && <Button type="primary" size="small" onClick={() => approveVesselAgreement(rowData.agreementId)}>Send For Approval</Button>}
          {rowData.status === ContractStatusEnum.PENDING_APPROVAL && <Button type="primary" size="small" onClick={() => approveOrRejectHandler(rowData.agreementId, ContractStatusEnum.ACTIVE)}>Approve</Button>} &nbsp;&nbsp;
          {rowData.status === ContractStatusEnum.PENDING_APPROVAL && <Button type="dashed" size="small" onClick={() => approveOrRejectHandler(rowData.agreementId, ContractStatusEnum.DRAFT)}>Reject</Button>} */}
        </span>)
    }
  ];

  const editClickHandler = (agreementId: string) => {
    const req = new PreviewRequests(agreementId, authContext.defaultPlant)
    service.getAgreementPreviewData(req).then(res => {
      if (res.status) {
        navigate('/vesselAgreement-update', { state: { record: res.data, isUpdate: true } });
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`BargeAgreement-${currentDate}.xlsx`);
  };

  return (
    <>
      <Card
            title={<span className='card-title'>{fm({ id: `mdm.components.header`, defaultMessage: `Vessel Agreement` })}</span>}
            className='card-container'
            headStyle={{ border: 'none' }}
            >  

                <Card 
                    className='contracts-list card-radius'
                    title={<Row className='card-title-buttons'>
                    <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} className='input-search' />
                    <Select
                    placeholder="Filter by Status"
                    className='filter-by-status'
                    allowClear
                    onChange={handleStatusChange}
                    >
                    <Option value="Draft">Draft</Option>
                    <Option value="Send For Approval">Send for Approval</Option>
                    <Option value="Active">Active</Option>
                    </Select>
                    </Row>}          
                        extra={
                            <Tooltip placement="topRight" title="Create">
                                <Row className='mt-16'>
                                    <Button
                                    className='new-button'
                                        size="small"
                                        type="primary"
                                        onClick={() => navigate('/vesselAgreement-creation')}
                                    >
                                        Create
                                    </Button>
                                </Row>
                            </Tooltip>}
                            headStyle={{border: 'none'}}
                >
                    <Table
                        className='contracts-list'
                        rowKey={record => record.agreementId}
                        columns={tableColumns}
                        dataSource={filteredData}
                        pagination={{
                            showTotal: (total) => (
                              <span 
                              className='page-total-items'
                              >Total {total} items</span>
                            ),
                            showSizeChanger: true, 
                              pageSizeOptions: ['25', '50', '100'],
                              defaultPageSize: 25,
                            style: {
                              paddingTop: '20px',
                            },
                          }}

                        scroll={{ x: 500 }}
                    ></Table>

                </Card> 

            </Card>

    {/* <Card
      title={fm({ id: `mdm.components.header`, defaultMessage: `Vessel Agreement` })}
      extra={<Button
        type="primary"
        onClick={() => navigate('/vesselAgreement-creation')}
        className='panel_button'
      >Create</Button>
      }
    >
      <Tooltip placement="topRight" title="Export As Excel" color="green">
        <Button
          //disabled={gridData.length === 0}
          size='small'
          type="default"
          style={{ float: "right" }}
          //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
          onClick={handleExport}
          icon={<FileExcelFilled />}
        >Export</Button>
      </Tooltip>
      <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />

      <Table
        className='contracts'
        rowKey={record => record.agreementId}
        columns={tableColumns}
        dataSource={gridData}
        pagination={false}

        scroll={{ x: 500 }}
        size="large"
        bordered
      ></Table>
    </Card> */}
    </>
  )
}
