import { BargeCostingIdReqDto, BargeCostingsService, PriceTypeEnum } from "@exportx/shared-models-and-services";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../../common";
import moment from "moment";
import { internationalFormattedValue } from "@exportx/ui-utils";
import { Card, Col, Divider, Flex, Row, Table, Typography } from "antd";
import { SequenceUtils } from '@exportx/ui-utils';



interface BargeCostingDetailsProps {
  costingId: string;
  remainingAmount?: number;
  children?: React.ReactNode;
}
export const BargeCostingDetails = (props: BargeCostingDetailsProps) => {
  const { costingId, children, remainingAmount } = props

  const service = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [data, setFormValues] = useState<any>();
  const { Text } = Typography;
  const [advTotal, setAdvTotal] = useState<number>(0)
  const [bargeData, setBargeData] = useState<any>([]);

  useEffect(() => {
    bgCostingData()
  }, [costingId])

  const bgCostingData = () => {
    service.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingId, authContext.defaultPlant)).then(res => {
      if (res.status) {
        const costings = res.data?.bargeCostings[0] || {}
        setFormValues({ ...res.data, ...costings });
        const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
        setBargeData(res.data?.bargeCostings?.map((item) => ({ ...item, bargeVendor: res.data?.bargeSupplier, coalVendor: res.data?.coalSupplier, remaining: res.data?.totalAmount, businessNo: res?.data?.businessNo })))
        setAdvTotal(advTotal)
      } else {
        setFormValues(null);
      }
    }).catch(err => {
      console.log(err)
      setFormValues(null);
    });
  }
  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: bargeData.length }
    else return { rowSpan: 0 }
  };

  const columns = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bargeId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bargeNomination}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bargeNomination}</Typography>
        </div>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>
    },
    {
      title: 'Barge Vendor',
      dataIndex: 'bargeVendor',
      key: 'bargeVendor',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Jetty',
      dataIndex: 'address',
      key: 'address',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Supplier',
      dataIndex: 'coalVendor',
      key: 'coalVendor',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Grade',
      dataIndex: 'incoterm',
      key: 'incoterm',
      render: (value, record) => <Typography className="small-text">{value ?? 'N/A'}</Typography>

    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      render: (value, record) => <Text className="small-text">{internationalFormattedValue(value, 3)}</Text>

      // onCell: sharedOnCell,
    },
    {
      title: data?.bargingType === PriceTypeEnum.PER_MT ? 'price PMT' : 'Lumpsum',
      dataIndex: 'basePrice',
      key: 'basePrice',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.basePrice, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Total',
      dataIndex: 'remaining',
      key: 'remaining',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.paid, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Balance',
      dataIndex: 'remaining',
      key: 'remaining',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>
      </>

    },
  ];


  return (
    <>
      <Row gutter={8}>
        <Col span={16}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 10px 15px' } }}
            style={{ marginBottom: 8 }}

            title={<h6>Barge Details</h6>}
          >
            <Table
              className='contracts-list'
              dataSource={bargeData}
              columns={columns}
              size="small"
              pagination={false}
              scroll={{ x: 1400 }}
            />

          </Card>

          {children}

        </Col >

        <Col span={8}>
          <Card
            className="card-shadow"
            title={<span className="card-title-md">Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
          >

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date</Text>
              <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Base Freight</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.baseFreight, 3)}  {data?.billingCurrency}

              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Dead Freight</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.deadFreight, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.costingsTotal, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">VAT</Text>
              <Text className="price-card-text">{`${data?.vat ? 'Yes' : 'No'}`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.taxTotal, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${data?.tdsName || ''} (${data?.tdsPercentage || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            {data?.advanceTarnsactionsData?.map((adv) => {
              const link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;

              return (
                <Flex justify="space-between" align='center' key={adv.id}>
                  <Text className="price-card-text">Deduction (<a href={link} className="link" target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {data?.billingCurrency}
                  </Text>
                </Flex>
              )
            })}

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Advance Adjustment</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(Number(advTotal), 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(Number(data?.totalAmount - advTotal), 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            {Number(remainingAmount) > 0 &&

              <Flex justify="space-between" align='center'>
                <Text className="price-card-red">Remaining Amount</Text>
                <Text className="price-card-red">
                  {internationalFormattedValue(data?.remainingAmount, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}