import { DeleteFilled } from '@ant-design/icons';
import { BargeCostingIdReqDto, CurrencyConverterService, CurrencyDto, CurrencyService, PaymentNoticeService } from '@exportx/shared-models-and-services';
import { Button, Col, Form, Input, Row, Select, Tooltip, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../common';
import DatePicker from '../../../common/data-picker/date-picker';
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import { useIntl } from 'react-intl';


interface IProps {
  totalTransactionValue: {
    transactionAmount: number;
    remainingAmount: number;
    pmtNoticeId: string;
    pmtNoticeTransactionId: string;
  }
  costingCurrency?: string,
  open: boolean;
  title: string;
  onCancel: () => void;
}

const CreditReceivables = (props: IProps) => {
  const { totalTransactionValue, costingCurrency, open, title, onCancel } = props;
  const [formRef] = Form.useForm();
  const { authContext } = useAuthState();
  const [initialValue, setInitialValues] = useState<any>({ transactionValues: [{ costingCurrency: costingCurrency, baseCurrency: authContext.defaultPlantCurrency }] });
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const currencyService = new CurrencyService();
  const [transactionAmount, setTransactionAmount] = useState<any[]>([0]);
  const pmtService = new PaymentNoticeService();
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    getCurrency();
    getTransactionReceivables();
  }, []);

  const getTransactionReceivables = () => {
    const req = new BargeCostingIdReqDto(totalTransactionValue?.pmtNoticeTransactionId, authContext.defaultPlant);
    pmtService.getTransactionReceivables(req).then(res => {
      if (res.status) {
        const updatedValues = res.data.transactionValues.map((rec, index) => {
          exchangeRateOnchange(Number(rec.exchangeRate), Number(rec.transactionAmount), index)
          return { ...rec, receivedDate: moment(rec.receivedDate) }
        })
        // setInitialValues({transactionValues:v});
        formRef.setFieldsValue({ transactionValues: updatedValues })
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => console.log(err.message))
  }



  const suffixSelector = (index: number) => (
    <Form.Item name={[index, "costingCurrency"]} noStyle label="Please Select">
      <Select
        disabled
        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
      >
        {currencies?.map((rec) => {
          return <Select.Option value={rec.currencyCode}>{rec.currencyCode}</Select.Option>
        })}
      </Select>
    </Form.Item>
  );
  const suffixSelector2 = (index: number) => (
    <Form.Item name={[index, "baseCurrency"]} noStyle label="Please Select">
      <Select
        disabled
        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
      >
        {currencies?.map((rec) => {
          return <Select.Option value={rec.currencyCode}>{rec.currencyCode}</Select.Option>
        })}
      </Select>
    </Form.Item>
  );


  // const resetStateWithValidation = (index, amount, fieldName: string) => {
  //     const amountTotal = amount.reduce((a, c) => a + Number(c), 0);
  //     if (amountTotal > totalTransactionValue?.remainingAmount) {
  //         formRef.setFields([
  //             {
  //                 name: ['transactionValues', index, fieldName],
  //                 value: 0
  //             }
  //         ]);
  //         setTransactionAmount((prev) => {
  //             const newTransactionAmounts = [...prev];
  //             newTransactionAmounts[index] = 0;
  //             return newTransactionAmounts;
  //         });
  //         return AlertMessages.getErrorMessage('Do Not Exceed The Amount To Total Transaction Value')
  //     }
  //     return null
  // }



  const getCurrency = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };




  const exchangeRateOnchange = (value: number, amount: number, index: number) => {
    setTransactionAmount((prev) => {
      const newTransactionAmounts = [...prev];
      const transactionAmount = (value ? value : 1) * (amount ? amount : 1)
      newTransactionAmounts[index] = transactionAmount;
      // resetStateWithValidation(index, newTransactionAmounts, fieldName)
      return newTransactionAmounts;
    });
    const totalTransValue = formRef.getFieldValue('transactionValues').reduce((a, c) => a + Number(c.transactionAmount), 0)
    // if (totalTransValue > totalTransactionValue?.remainingAmount) {
    if (totalTransValue > Math.abs(totalTransactionValue?.transactionAmount)) {
      formRef.setFields([
        {
          name: ['transactionValues', index, 'transactionAmount'],
          value: 0
        }
      ]);
      setTransactionAmount((prev) => {
        const newTransactionAmounts = [...prev];
        newTransactionAmounts[index] = 0;
        return newTransactionAmounts;
      });
      return AlertMessages.getErrorMessage('Do Not Exceed The Amount To Total Transaction Value')

    }

  }





  const submitValues = () => {
    formRef.validateFields().then(values => {
      const empty = [];
      values.transactionValues.forEach((rec, index) => {
        empty.push(
          {
            costingCurrency: costingCurrency,
            baseCurrency: rec.baseCurrency,
            transactionAmount: Number(rec.transactionAmount),
            exchangeRate: Number(rec.exchangeRate) || 1,
            receivedDate: rec.receivedDate,
            afterExchangeRate: transactionAmount[index],
            userName: authContext.user.userName,
            pmtNoticeId: totalTransactionValue?.pmtNoticeId,
            pmtNoticeTransactionId: totalTransactionValue?.pmtNoticeTransactionId,
            workLog: rec.workLog,
            plantCode: authContext.defaultPlant

          }
        )
      })
      pmtService.addReceivables(empty).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => console.log(err.message));
    }).catch(err => console.log(err.message));
  }

  return <>

    <Modal
      open={open}
      onClose={() => onCancel()}
      onCancel={() => onCancel()}
      destroyOnClose
      okText='Save'
      okButtonProps={{ className: 'new-button' }}
      onOk={submitValues}
      title={title}
    >


      <Form
        form={formRef}
        initialValues={initialValue}
        layout={'vertical'}
      >
        <Form.List name={'transactionValues'}>
          {(fields, { add, remove }) => (
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  <Row>
                    <Col
                      xs={{ span: 23 }}
                      sm={{ span: 23 }}
                      md={{ span: 23 }}
                      lg={{ span: 23 }}
                      xl={{ span: 23 }}>

                      <Row>
                        <Col offset={1}>
                          <Form.Item

                            label={'Received Date'}
                            name={[name, 'receivedDate']}
                          >
                            <DatePicker
                              style={{ width: 167 }}

                            />
                          </Form.Item>
                        </Col>
                        <Col offset={2}>
                          <Form.Item
                            required={true}
                            label={'amount'}
                            name={[name, 'transactionAmount']}
                          >
                            <Input
                              type='number'
                              onChange={(e) => exchangeRateOnchange(Number(e.target.value), Number(formRef.getFieldValue('transactionValues')[name].exchangeRate), name)}
                              addonAfter={suffixSelector(name)}

                            >
                            </Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        {authContext.defaultPlantCurrency !== costingCurrency ?
                          <>
                            <Col offset={1} span={8}>
                              <Form.Item
                                name={[name, 'exchangeRate']}
                                label={'Exchange Rate'}
                              >
                                <Input
                                  type='number'
                                  onChange={(e) => exchangeRateOnchange(Number(e.target.value), Number(formRef.getFieldValue('transactionValues')[name].transactionAmount), name)}
                                  addonAfter={suffixSelector2(name)}
                                >

                                </Input>
                              </Form.Item>
                            </Col>
                          </>

                          : <></>

                        }
                        <Col offset={authContext.defaultPlantCurrency !== costingCurrency ? 4 : 1}   >
                          <Form.Item
                            label={'After Exchange Rate'}
                          >
                            {transactionAmount[name] ? transactionAmount[name] : 0}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 24 }} offset={1}>
                          <Form.Item label='Remarks' name={[name, 'workLog']}>
                            <TextArea rows={2} style={{ width: '100%' }} placeholder='Notes' />
                          </Form.Item>

                        </Col>

                      </Row>
                    </Col>
                    {fields?.length > 1 &&
                      <Col xs={{ span: 2 }}
                        sm={{ span: 1 }}
                        md={{ span: 1 }}
                        lg={{ span: 1 }}
                        xl={{ span: 1 }}>
                        <span style={{
                          position: "absolute",
                          marginTop: "33px",
                          marginLeft: "4px",
                        }}>
                          <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px' }} onClick={() => {
                              remove(name);
                              setTransactionAmount((prev) => {
                                const newTransactionAmounts = [...prev];
                                newTransactionAmounts[name] = 0;
                                return newTransactionAmounts;
                              });

                            }} />
                          </Tooltip>
                        </span>
                      </Col>

                    }


                  </Row>
                  {fields.length - 1 === index && (
                    <Form.Item >
                      <Button type="dashed" size='small' onClick={() => {

                        add();
                        formRef.setFields([
                          {
                            name: ['transactionValues', name + 1, 'costingCurrency'],
                            value: costingCurrency
                          },
                          {
                            name: ['transactionValues', name + 1, 'baseCurrency'],
                            value: authContext.defaultPlantCurrency
                          },

                        ]);

                      }} >
                        Add More
                      </Button>
                    </Form.Item>

                  )}

                </>
              ))}


            </Space>
          )}

        </Form.List>
      </Form>
    </Modal>

  </>
}

export default CreditReceivables